import requireAuth from '@components/hoc/with-required-auth-hoc';
import useDashboard from './dashboard-hook';
import { Box, Button, Chip, styled, Typography } from '@mui/material';
import { faCalendar, faUser } from '@fortawesome/pro-light-svg-icons';
import { CompanyUserCaseTotalsResponse } from '@api/types/dashboard/company-user-case-totals-response';
import { AnalysisViewMode } from '@pages/app/analysis/analysis-overview-hook';
import RcaCard from '@components/cards/rca-card';
import useUser from '@store/user/user-hook';
import AnalysisMapView from '@pages/app/analysis/components/analysis-map-view';
import AnalysisTableView from '@pages/app/analysis/components/analysis-table-view';
import { DropDownFilter } from '@components/table';
import { ButtonSkeleton, InlineBoxSkeleton } from '@components/skeletons';
import { PrimaryButton } from '@components/buttons';
import { useNavigate } from 'react-router-dom';

import NoRcaInfo from './components/no-rca-info';
import GridSkeleton from '@components/skeletons/grid-skeleton';
import ListContainerSkeleton from '@components/skeletons/list-container-skeleton';
import NoTaskInfo from './components/no-task-info';
import NoGroupInfo from './components/no-group-info';
import {
  DashboardLeagueTable,
  DashboardSplitCoverage,
  DashboardStatBox,
  DashboardStatContainer,
} from '@components/dashboard/dashboard-stat-container';
import GroupForm from '@pages/app/groups/forms/group-form';
import { useUiPopup } from '@components/ui-popup/ui-popup-provider';
import Row from '@components/layout-util-components/row';
import { OverviewImpactFilter } from '@pages/app/rca/tabs/rca-editor-overview-hook';
import { GroupCard } from '@components/cards';
import OutcomesData from '@pages/app/rca/tabs/components/overview/outcomes-data';
import Column from '@components/layout-util-components/column';
import TasksTableData from '@components/tasks/tasks-table-data';
import { Gap } from '@components/layout-util-components/gap';
import TasksFilters from '@components/tasks/tasks-filters';
import AnalysisDashboardFilters from '@pages/app/dashboard/components/analysis-dashboard-filters';
import GroupDashboardFilters from '@pages/app/dashboard/components/group-dashboard-filters';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ReactNode } from 'react';
import { ReactComponent as RcaIndicatorIcon } from '@assets/svgs/analysis.svg';
import usePostLogin from '@hooks/use-post-login-hook';
import { DashboardStatTheme } from '@components/dashboard/dashboard-stat-themes';
import { DashboardStatSolutions } from '@components/dashboard/dashboard-stat-solutions';
import { formatNumberToDecimals } from '@util/string-util';
import { DashboardStatCase } from '@components/dashboard/dashboard-stat-case';

const StyledHeaderBox = styled(Box)<{ styleButtons?: true }>(
  ({ theme: { palette }, styleButtons }) => ({
    display: 'flex',
    flex: '1 1 auto',
    flexWrap: 'wrap',
    marginBottom: 25,
    alignItems: 'center',
    '.radio-filter': {
      marginLeft: 'auto',
    },
    rowGap: 10,
    columnGap: 16,
    ...(styleButtons && {
      button: {
        borderRadius: 4,
        background: palette.common.grey40,
        color: '#001927',
        fontSize: '13px',
        lineHeight: '24px' /* 184.615% */,
        letterSpacing: '0.4px',
        display: 'flex',
        gap: 8,
        padding: '6px 16px',
        justifyContent: 'center',
        alignItems: 'center',
        '&:hover': {
          background: '#D8D8E3',
        },
        '&.create-rca, &.active': {
          background: 'linear-gradient(90deg, #FF6C00 0%, #FF6C00 100%)',
          color: palette.common.white,
          fontSize: '14px',
          fontWeight: 400,
          lineHeight: '24px' /* 171.429% */,
          letterSpacing: '0.4px',
        },
      },
    }),
    h1: {
      lineHeight: '133.4%' /* 32.016px */,
      marginRight: 'auto',
    },
  })
);

const PanelContainer = styled(Box)({
  display: 'flex',
  flexWrap: 'wrap',
  gap: 25,
  alignSelf: 'stretch',
  '>div': {
    '@media (max-width:750px)': {
      width: '100%',
    },
  },
});

const companyUserCaseTotalToIconAndLabel: Record<
  string,
  { label: string; icon: ReactNode; bgColour: string; fontColour: string }
> = {
  openCases: {
    label: 'OPEN RCAS',
    icon: <RcaIndicatorIcon width={28} height="auto" fill="#4CB648" />,
    bgColour: '#4CB64833',
    fontColour: '#4CB648',
  },
  overdueTasks: {
    label: 'OVERDUE TASKS',
    icon: <FontAwesomeIcon icon={faCalendar} fill="#FF3D3D" />,
    bgColour: '#FF3D3D33',
    fontColour: '#FF3D3D',
  },
  totalCases: {
    label: 'TOTAL RCAS',
    icon: <RcaIndicatorIcon width={28} height="auto" fill="#FF6C00" />,
    bgColour: '#FF6C0033',
    fontColour: '#FF6C00',
  },
  users: {
    label: 'USERS',
    icon: <FontAwesomeIcon icon={faUser} fill="#0097E1" />,
    bgColour: '#0097E133',
    fontColour: '#0097E1',
  },
};

function DashboardPage() {
  const state = useDashboard();
  const {
    companyUserCaseTotals,
    themesByCoverage,
    impactsByCoverage,
    groupedSolutionReport: {
      byEffectiveness: solutionsByEffectiveness,
      byEffectivenessCount: solutionsByEffectivenessCount,
      byTerm: solutionsByTerm,
      byTermCount: solutionsByTermCount,
    },
    isLoading,
    categoryFilters,
    onCategoryFilterChange,
    activeCategoryFilter,
    userCases,
    userTasks,
    userGroups,
    userOutcomes,
    viewMode,
    rcaListPagination,
    rcaListSort,
    caseAnalysisTypeOptions,
    caseAnalysisTypeFilter,
    companyUserFilter,
    userOptions,
    categoryFilterCounts,
    canFilterByUser,
    impactFilter,
    toggleImpactFilter,
    searchTasks,
    taskStatusFilter,
    taskStatusOptions,
    solutionTerms,
  } = state;

  const { firstName } = useUser();

  const navigate = useNavigate();
  const { showPopup } = useUiPopup();

  // POST LOGIN: Maybe display post login popup such as onboarding
  usePostLogin();

  if (isLoading) {
    return <StyledHeaderBox />;
  }

  const onCreateRcaClick = () => {
    navigate('/rca/create');
  };

  const buildRCAView = () => {
    if (activeCategoryFilter === 'RCA') {
      const hasNoCases = userCases == null || userCases.totalCount === 0;

      if (viewMode.value === AnalysisViewMode.grid) {
        if (isLoading) {
          return <GridSkeleton count={3} />;
        }

        if (hasNoCases) {
          return <NoRcaInfo onCreateClicked={onCreateRcaClick} />;
        }

        return (
          <Column>
            <AnalysisDashboardFilters state={state} />
            <PanelContainer>
              {userCases!.model.map((rca) => (
                <RcaCard
                  key={`rca-${rca.caseId}`}
                  data={rca}
                  afterClickRedirect={() => {}}
                />
              ))}
            </PanelContainer>
          </Column>
        );
      }

      if (viewMode.value === AnalysisViewMode.table) {
        if (!isLoading && hasNoCases) {
          return <NoRcaInfo onCreateClicked={onCreateRcaClick} />;
        }

        return (
          <Column>
            <AnalysisDashboardFilters state={state} />
            <AnalysisTableView
              isLoading={isLoading}
              pagination={rcaListPagination}
              sort={rcaListSort}
              caseData={userCases}
            />
          </Column>
        );
      }

      if (viewMode.value === AnalysisViewMode.map) {
        if (isLoading) {
          return <GridSkeleton count={1} itemHeight={600} />;
        }
        if (hasNoCases) {
          return <NoRcaInfo onCreateClicked={onCreateRcaClick} />;
        }
        return (
          <Column>
            <AnalysisDashboardFilters state={state} />
            <AnalysisMapView
              viewMode={viewMode}
              caseData={userCases}
              location={{
                arrayValue: undefined,
                value: undefined,
                set: () => null,
              }}
            />
          </Column>
        );
      }
    }

    if (activeCategoryFilter === 'Outcomes') {
      return (
        <Column>
          {isLoading ? (
            <InlineBoxSkeleton width="100%" height={300} />
          ) : (
            <OutcomesData outcomes={userOutcomes!.model} />
          )}
        </Column>
      );
    }

    if (activeCategoryFilter === 'Tasks') {
      if (isLoading) {
        return <ListContainerSkeleton count={3} />;
      }

      if (userTasks == null || userTasks.totalCount === 0) {
        return (
          <>
            <TasksFilters
              isFetching={false}
              isLoading={isLoading}
              search={searchTasks}
              taskStatusFilter={taskStatusFilter}
              taskStatusOptions={taskStatusOptions}
            />
            <Gap size={32} />
            <br />
            <NoTaskInfo />
          </>
        );
      }

      return (
        <Column>
          {isLoading ? (
            <InlineBoxSkeleton width="100%" height={300} />
          ) : (
            <>
              <TasksFilters
                isFetching={false}
                isLoading={isLoading}
                search={searchTasks}
                taskStatusFilter={taskStatusFilter}
                taskStatusOptions={taskStatusOptions}
              />
              <Gap size={10} />
              <TasksTableData
                displayCaseName
                isLoading={isLoading}
                data={userTasks}
              />
            </>
          )}
        </Column>
      );
    }

    if (activeCategoryFilter === 'Groups') {
      if (isLoading) {
        return <GridSkeleton count={3} />;
      }
      if (userGroups == null || userGroups.totalCount === 0) {
        return (
          <NoGroupInfo
            onCreateClicked={() => {
              navigate('/groups');
              showPopup(GroupForm);
            }}
          />
        );
      }
      return (
        <Column>
          <GroupDashboardFilters state={state} />
          <PanelContainer>
            {userGroups.model.map((group) => (
              <GroupCard
                key={`group-${group.companyGroupId}`}
                group={group}
                allowLeave={false}
              />
            ))}
          </PanelContainer>
        </Column>
      );
    }

    return <p>Missing designs</p>;
  };

  return (
    <Box
      id="dashboard-page"
      sx={{
        padding: '50px 0',
        margin: '0 20px',
        '@media (min-width:768px)': {
          margin: '0 80px',
        },
        '@media (min-width:1500px)': {
          margin: '0 100px',
        },
        '@media (min-width:1650px)': {
          margin: '0 auto',
          maxWidth: '1400px',
        },
      }}
    >
      <StyledHeaderBox>
        <Typography variant="h1">Welcome, {firstName}</Typography>
        {isLoading ? (
          <ButtonSkeleton />
        ) : (
          <>
            {((companyUserCaseTotals && companyUserCaseTotals?.openCases > 0) ||
              caseAnalysisTypeFilter.value != null) && (
              <DropDownFilter
                options={caseAnalysisTypeOptions!.map((opt) => ({
                  id: opt.shortName,
                  label: opt.name,
                }))}
                value={caseAnalysisTypeFilter.value}
                onChange={caseAnalysisTypeFilter.set}
                multiple
              >
                RCA Type
              </DropDownFilter>
            )}
          </>
        )}
        {canFilterByUser && (
          <>
            {isLoading ? (
              <ButtonSkeleton />
            ) : (
              <>
                {userOptions && userOptions.length > 1 && (
                  <DropDownFilter
                    options={userOptions!}
                    value={companyUserFilter.value}
                    onChange={companyUserFilter.set}
                    multiple
                  >
                    User
                  </DropDownFilter>
                )}
              </>
            )}
          </>
        )}

        <PrimaryButton
          buttonVariant="two"
          onClick={() => navigate('/rca/create')}
        >
          Create RCA
        </PrimaryButton>
      </StyledHeaderBox>
      {companyUserCaseTotals && (
        <DashboardStatContainer isTotals>
          {Object.entries(companyUserCaseTotals)
            .filter(
              ([key, _]) =>
                companyUserCaseTotalToIconAndLabel[key] !== undefined
            )
            .map(([key, value]) => {
              const { icon, label, bgColour, fontColour } =
                companyUserCaseTotalToIconAndLabel[
                  key as keyof CompanyUserCaseTotalsResponse
                ];

              return (
                <DashboardStatBox
                  key={key}
                  icon={icon}
                  value={value}
                  label={label}
                  fontColour={fontColour}
                  bgColor={bgColour}
                />
              );
            })}
        </DashboardStatContainer>
      )}
      <DashboardStatContainer>
        {isLoading ? (
          <InlineBoxSkeleton height={300} />
        ) : (
          <DashboardStatTheme report={themesByCoverage} limit={3} />
        )}

        {isLoading ? (
          <InlineBoxSkeleton height={300} />
        ) : (
          <DashboardStatSolutions
            report={solutionTerms}
            totalChainItems={companyUserCaseTotals?.totalChainItems}
            totalRcas={companyUserCaseTotals?.totalCases}
          />
        )}
      </DashboardStatContainer>
      {!isLoading &&
        companyUserCaseTotals!.totalCases > 0 &&
        (companyUserCaseTotals!.completedCasesHealthScore > 0 ||
          companyUserCaseTotals!.openCasesHealthScore > 0) && (
          <DashboardStatContainer>
            {isLoading ? (
              <InlineBoxSkeleton height={300} />
            ) : (
              <DashboardStatCase
                totalRcas={companyUserCaseTotals?.totalCases ?? 0}
                healthScore={companyUserCaseTotals?.totalCasesHealthScore ?? 0}
                completedRcas={companyUserCaseTotals?.completedCases ?? 0}
                completedHealthScore={
                  companyUserCaseTotals?.completedCasesHealthScore ?? 0
                }
                inProgressRcas={companyUserCaseTotals?.openCases ?? 0}
                inProgressHealthScore={
                  companyUserCaseTotals?.openCasesHealthScore ?? 0
                }
              />
            )}
            {isLoading ? (
              <InlineBoxSkeleton height={300} />
            ) : (
              <DashboardLeagueTable
                title="Solutions"
                content={
                  <>
                    You have <span>{solutionTerms!.totals.solutionCount}</span>{' '}
                    solution
                    {solutionTerms!.totals.solutionCount === 1 ? '' : 's'}{' '}
                    across <span>{companyUserCaseTotals?.totalCases}</span> RCA
                    {companyUserCaseTotals?.totalCases === 1 ? '' : 's'},
                    achieving{' '}
                    <span>
                      {formatNumberToDecimals(
                        solutionTerms!.totals.coveragePercent,
                        1
                      )}
                      %
                    </span>{' '}
                    overall coverage. The average solution effectiveness is{' '}
                    <span>
                      {formatNumberToDecimals(
                        solutionTerms!.totals.linkedSolutionAverageEffectiveness
                      )}
                      %
                    </span>
                    .
                  </>
                }
                noData
              />
            )}
          </DashboardStatContainer>
        )}

      <StyledHeaderBox styleButtons>
        {categoryFilters.map((filter, i) => (
          <Button
            key={filter}
            id={filter}
            disabled={isLoading}
            onClick={onCategoryFilterChange}
            className={activeCategoryFilter === filter ? 'active' : ''}
          >
            {categoryFilterCounts[i]
              ? `${filter} (${categoryFilterCounts[i]})`
              : filter}
          </Button>
        ))}
      </StyledHeaderBox>
      {buildRCAView()}
    </Box>
  );
}

export default requireAuth(DashboardPage);
