import { WAInstallRow } from '@api/types/wct-admin/wct-admin-install-types';
import {
  Box,
  Button,
  IconButton,
  styled,
  TableCell,
  TableRow,
} from '@mui/material';
import Row from '@components/layout-util-components/row';
import { truncateString } from '@util/string-util';
import { Gap } from '@components/layout-util-components/gap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronDown,
  faChevronUp,
  faCopy,
  faTrashAlt,
} from '@fortawesome/pro-light-svg-icons';
import { usePageAlertVariants } from '@components/alerts';
import { dateOnlyShortFormatter } from '@i18n/formatters';
import { ElipsisButton } from '@components/buttons';
import { useMemo, useState } from 'react';
import { useDeleteWAInstallMutation } from '@api/endpoints/wct-admin/wct-admin-install.api';
import useBusyAction from '@hooks/use-busy-action-hook';
import { useUiPopup } from '@components/ui-popup/ui-popup-provider';
import { invalidation } from '@api/cache-util';
import { isApiError } from '@api/types/api-error';
import WASeedInstallForm from '@pages/wct-admin/companies/components/forms/wa-seed-install-form';
import { WCTTable } from '@components/table';
import { useDeleteSeedWACompanyMutation } from '@api/endpoints/wct-admin/wct-admin-company.api';

import WASeedCompanyForm from '@pages/wct-admin/companies/components/forms/wa-seed-company-form';

interface Props {
  install: WAInstallRow;
  onEdit: () => void;
}

const Container = styled(TableRow)({});

export default function WAInstallTableRow({ install, onEdit }: Props) {
  const { showSuccessMessage, showErrorMessage } = usePageAlertVariants();
  const { showDeleteConfirmationModal, showPopup } = useUiPopup();
  const [deleteInstallMutation] = useDeleteWAInstallMutation();
  const [deleteSeedCompantMutation] = useDeleteSeedWACompanyMutation();
  const [expanded, setExpanded] = useState(false);

  const {
    name,
    installKey,
    installAPIUrl,
    installAPPUrl,
    companyCount,
    created,
    confirmed,
  } = install;

  const [deleteInstall] = useBusyAction(async () => {
    await showDeleteConfirmationModal({
      itemName: `Install '${name}'`,
      onDelete: async () => {
        try {
          await deleteInstallMutation(install.installId).unwrap();
          await invalidation('WAInstall');
          showSuccessMessage('Install deleted successfully');
          return true;
        } catch (error) {
          if (isApiError(error)) {
            showErrorMessage(error.message);
          }

          return false;
        }
      },
    });
  });

  const [deleteQueueItem] = useBusyAction(async (id: number) => {
    await showDeleteConfirmationModal({
      itemName: `Company '${name}'`,
      onDelete: async () => {
        try {
          await deleteSeedCompantMutation(id).unwrap();
          await invalidation('WAInstall');
          await invalidation('WACompany');
          showSuccessMessage('Company deleted successfully');
          if (install.queue.length === 1) {
            setExpanded(false);
          }
          return true;
        } catch (error) {
          if (isApiError(error)) {
            showErrorMessage(error.message);
          }

          return false;
        }
      },
    });
  });

  const createCompany = () => {
    showPopup(WASeedCompanyForm, {
      installId: install.installId,
    });
  };

  // Show first 4 and last 2 digits of the key and only 2 asterisks in between
  const maskedKey = useMemo(() => {
    const clonedStr = installKey?.trim();

    return installKey != null && installKey.length > 8 && clonedStr != null
      ? `${installKey.slice(0, 4).trim()}**${clonedStr.slice(-2).trim()}`
      : null;
  }, [installKey]);

  const appUrlDomain = useMemo(() => {
    return installAPPUrl?.split('/')?.[2];
  }, [installAPPUrl]);
  const apiUrlDomain = useMemo(() => {
    return installAPIUrl?.split('/')?.[2];
  }, [installAPIUrl]);

  return (
    <>
      <Container onClick={() => onEdit()}>
        <TableCell>
          {install.queue && install.queue.length > 0 && (
            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                setExpanded(!expanded);
              }}
            >
              {expanded ? (
                <FontAwesomeIcon icon={faChevronUp} />
              ) : (
                <FontAwesomeIcon icon={faChevronDown} />
              )}
            </IconButton>
          )}
        </TableCell>
        <TableCell>{name}</TableCell>
        <TableCell>
          {maskedKey != null ? (
            <Row>
              <span>{maskedKey}</span>
              <Gap size={4} />
              <Button
                variant="text"
                size="small"
                onClick={(e) => {
                  e.stopPropagation();
                  navigator.clipboard.writeText(installKey!);
                  showSuccessMessage('Key copied to clipboard');
                }}
              >
                <FontAwesomeIcon icon={faCopy} />
              </Button>
            </Row>
          ) : (
            'No Key'
          )}
        </TableCell>
        <TableCell>{companyCount}</TableCell>
        <TableCell>{install.queue?.length ?? 0}</TableCell>
        <TableCell>
          {appUrlDomain != null ? (
            <Row>
              <span>{truncateString(appUrlDomain, 12)}</span>
              <Gap size={12} />
              <Button
                variant="text"
                size="small"
                onClick={(e) => {
                  e.stopPropagation();
                  navigator.clipboard.writeText(installAPPUrl!);
                  showSuccessMessage('APP URL copied to clipboard');
                }}
              >
                <FontAwesomeIcon icon={faCopy} />
              </Button>
            </Row>
          ) : (
            'Not set'
          )}
        </TableCell>
        <TableCell>
          {apiUrlDomain != null ? (
            <Row>
              <span>{truncateString(apiUrlDomain, 12)}</span>
              <Gap size={12} />
              <Button
                variant="text"
                size="small"
                onClick={(e) => {
                  e.stopPropagation();
                  navigator.clipboard.writeText(installAPIUrl!);
                  showSuccessMessage('API URL copied to clipboard');
                }}
              >
                <FontAwesomeIcon icon={faCopy} />
              </Button>
            </Row>
          ) : (
            'Not set'
          )}
        </TableCell>
        <TableCell>{dateOnlyShortFormatter(created)}</TableCell>
        <TableCell>
          <Row>
            <Box
              sx={{
                marginRight: 4,
                width: 8,
                height: 8,
                borderRadius: 4,
                background: confirmed ? '#3CA680' : '#e44848',
              }}
            />
            {confirmed ? dateOnlyShortFormatter(confirmed) : ''}
          </Row>
        </TableCell>
        <TableCell>
          <ElipsisButton
            actions={[
              { label: 'Edit', onClick: onEdit },
              { label: 'Create Company', onClick: createCompany },
              { label: 'Delete', onClick: deleteInstall },
            ]}
          />
        </TableCell>
      </Container>
      {expanded && (
        <Container onClick={() => onEdit()}>
          <TableCell colSpan={10}>
            <WCTTable
              count={install.queue.length}
              headers={[
                { title: 'Company Name' },
                { title: 'Sector' },
                { title: 'Business Area' },
                { title: 'Country' },
                { title: 'Users Name' },
                { title: 'Users Email' },
                { title: 'Last Run' },
                { title: 'Failed Count' },
                { title: '' },
              ]}
            >
              {install.queue.map((q) => (
                <Container>
                  <TableCell>{q.companyName}</TableCell>
                  <TableCell>{q.sectorName}</TableCell>
                  <TableCell>{q.businessAreaName}</TableCell>
                  <TableCell>{q.countryName}</TableCell>
                  <TableCell>
                    {q.userFirstName} {q.userLastName}
                  </TableCell>
                  <TableCell>{q.userEmailAddress}</TableCell>
                  <TableCell>
                    {q.lastRun ? q.lastRun.toString() : 'N/A'}
                  </TableCell>
                  <TableCell>{q.failedCount ?? 0}</TableCell>
                  <TableCell>
                    <Button
                      variant="text"
                      size="small"
                      onClick={(e) => {
                        e.stopPropagation();
                        deleteQueueItem(q.installCompanyQueueId);
                      }}
                    >
                      <FontAwesomeIcon icon={faTrashAlt} />
                    </Button>
                  </TableCell>
                </Container>
              ))}
            </WCTTable>
          </TableCell>
        </Container>
      )}
    </>
  );
}
