import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RunReportResponse } from '@api/types/case/case-report/run-report.response';
import { ReportType } from '@api/types/report-type/report-type.option';
import { ReportSubType } from '@api/types/report-type/report-subtype.option';
import { ReportRankBy } from '@api/types/report-type/report-rank-by.option';

export interface RunReportModel {
  id: number;
  reportTypeId: number;
  reportTypeName: string;
  reportType: ReportType;
  reportSubTypeId: number;
  reportSubTypeName: string;
  reportSubType: ReportSubType;
  reportRankById: number;
  reportRankByName: string;
  reportRankBy: ReportRankBy;
  ids?: number[];
  caseImpactTypeId?: number;
  caseImpactTypeName?: string;
  impactGroup?: string;
  impactType?: string;
  impactTypeName?: string;
  filterCaseImpacts?: number[];
  filterCaseImpactNames?: string[];
  name?: string;
}

interface ReportsSliceState {
  openLensesSlideOut: boolean;
  showId?: number;
  model?: RunReportModel;
  data?: RunReportResponse<any>;
  shrinkView: boolean;
  selectedRankRow?: number;
}

const initialState: ReportsSliceState = {
  openLensesSlideOut: false,
  showId: undefined,
  model: undefined,
  data: undefined,
  shrinkView: false,
  selectedRankRow: undefined,
};

const reportSlice = createSlice({
  name: 'reportSlice',
  initialState: initialState,
  reducers: {
    setShowReportId(
      state,
      { payload: { id, reset } }: PayloadAction<{ id: number; reset: boolean }>
    ) {
      return {
        ...(reset ? initialState : state),
        showId: id,
      };
    },
    setShowRunModel(
      state,
      {
        payload: { model, reset },
      }: PayloadAction<{ model: RunReportModel; reset: boolean }>
    ) {
      return {
        ...(reset ? initialState : state),
        model,
      };
    },
    setLoadedReportData(
      state,
      {
        payload,
      }: PayloadAction<{
        model: RunReportModel;
        data: RunReportResponse<any>;
      }>
    ) {
      state.model = payload.model;
      state.data = payload.data;
    },
    closeReport(state, _: PayloadAction) {
      return {
        ...initialState,
      };
    },
    toggleShrinkView(state, _: PayloadAction) {
      state.shrinkView = !state.shrinkView;
    },
    setOpenLensesSlideOut(state, { payload }: PayloadAction<boolean>) {
      state.openLensesSlideOut = payload;
    },
    setSelectedRankRow(state, { payload }: PayloadAction<number>) {
      state.selectedRankRow = payload;
    },
  },
});

export const {
  setShowReportId,
  setShowRunModel,
  setLoadedReportData,
  closeReport,
  toggleShrinkView,
  setOpenLensesSlideOut,
  setSelectedRankRow,
} = reportSlice.actions;

export default reportSlice.reducer;
