import { PinInfo } from '@components/google-map/pin-display-map';
import RcaCard from '@components/cards/rca-card';
import ContentFooterCard from '@components/cards/content-footer-card';
import { Box, Grid, styled, Typography } from '@mui/material';
import Column from '@components/layout-util-components/column';
import { getAddressString, truncateString } from '@util/string-util';
import { useMemo } from 'react';
import Row from '@components/layout-util-components/row';
import { CreateAnalysisType } from '@store/create-analysis-process/types';
import { ElipsisButton } from '@components/buttons';
import FilledBadge from '@components/badges/filled-badge';
import HealthBadge from '@components/badges/health-badge';
import { dateOnlyLongFormatter, timespanFormatter } from '@i18n/formatters';
import { useNavigate } from 'react-router-dom';
import { Gap } from '@components/layout-util-components/gap';
import Spacer from '@components/layout-util-components/spacer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLocationDot } from '@fortawesome/pro-light-svg-icons';
import HeaderBGImage from '@assets/svgs/card-detail.svg';
import RcaSeverityPill from '@components/rca-components/rca-severity-pill';
import RcaHealthPill from '@components/rca-components/rca-health-pill';
import RcaStatusText from '@components/rca-components/rca-status-text';
const ProblemIcon = '/svgs/problem-icon_sm.svg';
const RiskIcon = '/svgs/risk-icon_sm.svg';
const ImprovementIcon = '/svgs/improvement-icon_sm.svg';

interface Props {
  info: PinInfo;
  onFilterByLocationId: (locationId: number) => void;
}

const CardHeader = styled('div')(({ theme: { palette } }) => ({
  background: `url(${HeaderBGImage}) no-repeat top right  ${palette.blue.dark}`,
  borderTopLeftRadius: 6,
  borderTopRightRadius: 6,
  padding: 20,
  gap: 12,
  display: 'flex',
  flexDirection: 'column',
  color: palette.common.white,
  '& p': {
    display: 'block',
    lineHeight: '150%',
    height: '2.9em',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    marginRight: 20,
  },
  '& .circle-container': {
    backgroundColor: palette.blue.light,
    color: palette.blue.dark,
  },
}));

const CardContent = styled('div')(({ theme: { palette } }) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: 10,
  gap: 20,
  '.pinIcon': {
    color: palette.primary.main,
  },
}));

const CardInfo = styled('div')(({ theme: { palette } }) => ({
  fontSize: '14px',
  fontWeight: 400,
  '& .MuiTypography-overline': {
    display: 'block',
    marginBottom: '0.5em',
    color: palette.blue.dark,
    fontSize: '12px',
  },
  '& .MuiTypography-body2': {
    color: palette.blue.dark,
  },
  '& .circle-container': {
    backgroundColor: palette.success.main,
    opacity: 1,
    fontSize: '10px',
    color: '#fff',
  },
  '& .name-container .label': {
    fontSize: '15px',
    fontWeight: 400,
  },
}));

const StatRow = styled(Row)(({ theme: { palette } }) => ({
  border: '1px solid #ccc',
  borderRadius: '5px',
  padding: '10px',
}));

const StatContainer = styled(Box)(({ theme: { palette } }) => ({
  border: '1px solid #ccc',
  borderRadius: '5px',
}));

const InnerStatRow = styled(Row)(({ theme: { palette } }) => ({
  borderBottom: '1px solid #ccc',
  padding: '10px',
  '& > img': {
    height: '25px',
    marginRight: '10px',
  },
}));

const CaseContainer = styled(Column)(({ theme: { palette } }) => ({
  border: '1px solid #ccc',
  borderRadius: '5px',
  marginBottom: '10px',
}));

export default function CasePinCard({ info, onFilterByLocationId }: Props) {
  const navigate = useNavigate();

  const { cases } = info;
  const numberOfCases = cases.length;

  const incidentLocation = useMemo(() => {
    if (cases.length === 0) {
      return;
    }

    const location = cases[0].incidentLocation;
    if (location == null) {
      return;
    }

    return getAddressString({ ...location }) || location.name;
  }, [cases]);

  const reportingLocation = useMemo(() => {
    if (cases.length === 0) {
      return;
    }

    const location = cases[0].reportingLocation;
    if (location == null) {
      return;
    }

    return getAddressString({ ...location }) || location.name;
  }, [cases]);

  const problemCount = useMemo(() => {
    return cases.filter(
      (x) => x.caseAnalysisTypeId === CreateAnalysisType.problem
    ).length;
  }, [cases]);

  const riskCount = useMemo(() => {
    return cases.filter((x) => x.caseAnalysisTypeId === CreateAnalysisType.risk)
      .length;
  }, [cases]);

  const opportunityCount = useMemo(() => {
    return cases.filter(
      (x) => x.caseAnalysisTypeId === CreateAnalysisType.improvement
    ).length;
  }, [cases]);

  if (numberOfCases === 1) {
    return (
      <RcaCard
        widthOverride={250}
        data={cases[0]}
        afterClickRedirect={() => {}}
      />
    );
  }

  if (info.isReportingLocation) {
    return (
      <ContentFooterCard
        widthOverride={250}
        content={
          <>
            <CardHeader>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  gap: '10px',
                }}
              >
                <FontAwesomeIcon icon={faLocationDot} size="xl" />
                <Typography
                  variant="caption"
                  sx={{
                    textTransform: 'uppercase',
                    color: '#fff',
                    fontSize: '12px',
                    fontWeight: 500,
                  }}
                >
                  Reporting <br />
                  Location
                </Typography>
              </Box>

              {/* <Typography variant="body1">
                  {reportingLocation || '-'}
                </Typography> */}
            </CardHeader>
            <CardContent>
              <Box
                sx={{
                  display: 'flex',
                  gap: '10px',
                }}
              >
                <Typography variant="body2">{reportingLocation}</Typography>
              </Box>
              <StatRow>
                <FontAwesomeIcon
                  style={{ marginRight: '10px' }}
                  icon={faLocationDot}
                  size="xl"
                />
                <Typography className="stat-title">Linked Incidents</Typography>
                <Spacer />
                <Typography className="stat">{cases.length}</Typography>
              </StatRow>
              <StatContainer>
                <InnerStatRow>
                  <img src={ProblemIcon} alt="empty icon" />
                  <Typography className="stat-title">Problems</Typography>
                  <Spacer />
                  <Typography className="stat">{problemCount}</Typography>
                </InnerStatRow>
                <InnerStatRow>
                  <img src={RiskIcon} alt="empty icon" />
                  <Typography className="stat-title">Risks</Typography>
                  <Spacer />
                  <Typography className="stat">{riskCount}</Typography>
                </InnerStatRow>
                <InnerStatRow>
                  <img src={ImprovementIcon} alt="empty icon" />
                  <Typography className="stat-title">Improvements</Typography>
                  <Spacer />
                  <Typography className="stat">{opportunityCount}</Typography>
                </InnerStatRow>
              </StatContainer>
            </CardContent>
          </>
        }
        footer={
          <>
            <Spacer />
            <ElipsisButton
              actions={[
                {
                  label: 'View incidents',
                  onClick: () => {
                    onFilterByLocationId(
                      cases[0].reportingLocation!.companyLocationId
                    );
                  },
                },
              ]}
            />
          </>
        }
      />
    );
  }

  return (
    <>
      <ContentFooterCard
        widthOverride={250}
        content={
          <>
            <CardHeader>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  gap: '10px',
                }}
              >
                <FontAwesomeIcon icon={faLocationDot} size="xl" />
                <Typography
                  variant="caption"
                  sx={{
                    textTransform: 'uppercase',
                    color: '#fff',
                    fontSize: '12px',
                    fontWeight: 500,
                  }}
                >
                  Incident <br />
                  Location
                </Typography>
              </Box>

              {/* <Typography variant="body1">
                  {reportingLocation || '-'}
                </Typography> */}
            </CardHeader>
            <CardContent>
              <Box
                sx={{
                  display: 'flex',
                  gap: '10px',
                }}
              >
                <Typography variant="body2">{incidentLocation}</Typography>
              </Box>
              <div className="row-container">
                {cases.map(
                  ({
                    caseId,
                    caseAnalysisTypeName,
                    caseSeverityName,
                    healthScore,
                    name,
                    created,
                    caseStatusId,
                  }) => (
                    <>
                      <CaseContainer
                        key={caseId}
                        className="detail-row"
                        onClick={() => navigate(`/rca/tabs/${caseId}`)}
                      >
                        <Box
                          sx={{
                            padding: '10px',
                            borderBottom: '1px solid #CCC',
                          }}
                        >
                          <Typography className="name">{name}</Typography>
                        </Box>

                        <Gap size={6} />
                        <Box
                          sx={{
                            padding: '10px',
                          }}
                        >
                          <Grid container spacing={2}>
                            <Grid item xs={6}>
                              <CardInfo>
                                <Typography variant="overline">
                                  Severity
                                </Typography>
                                <RcaSeverityPill severity={caseSeverityName} />
                              </CardInfo>
                            </Grid>
                            <Grid item xs={6}>
                              <CardInfo>
                                <Typography variant="overline">
                                  Health
                                </Typography>
                                <RcaHealthPill health={healthScore} />
                              </CardInfo>
                            </Grid>
                            <Grid item xs={6}>
                              <CardInfo>
                                <Typography
                                  variant="overline"
                                  sx={{
                                    marginBottom: '10px !important',
                                  }}
                                >
                                  Created
                                </Typography>
                                {dateOnlyLongFormatter(created)}
                              </CardInfo>
                            </Grid>
                            <Grid item xs={6}>
                              <CardInfo>
                                <Typography variant="overline">
                                  Status
                                </Typography>
                                <RcaStatusText statusId={caseStatusId || 0} />
                              </CardInfo>
                            </Grid>
                          </Grid>
                        </Box>
                      </CaseContainer>
                      <Gap size={12} />
                    </>
                  )
                )}
              </div>
            </CardContent>
          </>
        }
        footer={
          <>
            <Spacer />
            <ElipsisButton
              actions={[
                {
                  label: 'View incidents',
                  onClick: () => {
                    onFilterByLocationId(
                      cases[0].incidentLocation!.companyLocationId
                    );
                  },
                },
              ]}
            />
          </>
        }
      />
      {/* <ContentFooterCard
        widthOverride={250}
        content={
          <StyledIncidentLocationContent>
            <Column>
              <Typography variant="caption">Incident Location</Typography>
              <Gap size={6} />
              <Typography>{incidentLocation || '-'}</Typography>
              <Gap size={20} />
              
            </Column>
          </StyledIncidentLocationContent>
        }
      /> */}
    </>
  );
}
