import { CaseFileListItemResponse } from '@api/types/case/case-file/case-file-list-item.response';
import { Link, styled, Typography } from '@mui/material';
import CaseFileTypeIconContainer from '@components/containers/case-file-type-icon-container';
import { ReactComponent as CauseBoxIcon } from '@assets/svgs/box-item.svg';
import Spacer from '@components/layout-util-components/spacer';
import InitialsContainerWithData from '@components/containers/initials-container-with-data';
import { getInitials } from '@util/name-util';
import { ReactNode } from 'react';
import Column from '@components/layout-util-components/column';
import {
  isLocationString,
  isNullOrEmpty,
  truncateString,
} from '@util/string-util';
import FlatBadge from '@components/badges/flat-badge';
import { convertBytesToMB } from '@util/helper-util';
import { Gap } from '@components/layout-util-components/gap';

interface Props {
  caseFile: CaseFileListItemResponse;
  trailing?: ReactNode;
  displayCreatedBy?: boolean;
  longName?: boolean;
}

const Container = styled('div')(({ theme: { palette } }) => ({
  padding: 10,
  border: '1px solid #00000040',
  borderRadius: 5,
  display: 'flex',
  alignItems: 'flex-start',

  'file-size': {
    fontFamily: 'Roboto',
    fontSize: '11px',
    fontWeight: '500',
    lineHeight: '11px',
    letterSpacing: '0px',
    textAlign: 'left',
  },

  'linked-cause-box-container': {
    outline: 'none',
    padding: 4,
    display: 'inline-flex',
    gap: '5px',
    border: '1px solid #000000',
    backgroundColor: '#EBEBEB',
    borderRadius: 4,
    maxWidth: 212,
    overflowY: 'elipsis',
    fontSize: '12px',
    fontWeight: '500',
    lineHeight: '14px',
    letterSpacing: '0em',
    textAlign: 'center',
    color: palette.common.black,

    svg: {
      height: 8,
      width: 'auto',
    },

    span: {
      textDecoration: 'underline',
    },
  },
}));

export default function CaseFileItem({
  caseFile,
  trailing,
  displayCreatedBy = true,
  longName = false,
}: Props) {
  const { fileLocation } = caseFile;

  return (
    <Container>
      <CaseFileTypeIconContainer type={caseFile.caseFileType} />
      <Gap size={10} />
      <Column sx={{ flexGrow: 1, flexShrink: 0 }}>
        <Typography variant="caption">
          {truncateString(caseFile.name, longName ? 55 : 35)}
        </Typography>

        {!isNullOrEmpty(fileLocation) ? (
          <>
            {isLocationString(fileLocation ?? '') ? (
              <Link href={fileLocation} target="_blank">
                {truncateString(fileLocation, longName ? 42 : 27)}
              </Link>
            ) : (
              <Typography>
                {truncateString(fileLocation, longName ? 42 : 27)}
              </Typography>
            )}
          </>
        ) : !!caseFile.fileLength ? (
          <Typography>{convertBytesToMB(caseFile.fileLength)}</Typography>
        ) : null}
        <Gap size={6} />
        {caseFile.chainItems != null && caseFile.chainItems.length > 0 ? (
          <FlatBadge>
            <CauseBoxIcon />
            <Link href={`./chart/?chainItem=${caseFile.chainItems[0].id}`}>
              {caseFile.chainItems[0].label}
            </Link>
          </FlatBadge>
        ) : null}
      </Column>
      <Spacer />
      {displayCreatedBy && (
        <>
          <Gap size={24} />
          <InitialsContainerWithData
            initials={getInitials(caseFile.createdByName)}
            label="Added By"
            subLabel={caseFile.createdByName}
          />
        </>
      )}
      {trailing ? (
        <>
          <Gap size={6} />
          <Spacer />
          {trailing}
        </>
      ) : null}
    </Container>
  );
}
