import {
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  SideDrawerFormProps,
  withSideDrawer,
} from '@components/ui-popup/drawers';
import {
  WAInstallRow,
  WAUpsertInstallResponse,
} from '@api/types/wct-admin/wct-admin-install-types';
import { Grid, Typography, styled } from '@mui/material';
import { OutlinedButton, PrimaryButton } from '@components/buttons';
import useUpsertInstallForm from '@pages/wct-admin/installs/components/forms/wa-upsert-install-hook';
import { ColumnForm } from '@components/layout-util-components/column';
import WCTTextField from '@components/input/text-field';
import WCTSwitchField from '@components/input/switch-field';

export interface WAUpsertInstallFormProps
  extends SideDrawerFormProps<WAUpsertInstallResponse> {
  install?: WAInstallRow;
}

const Container = styled(DrawerContent)({});

function WAUpsertInstallForm({ install, onClose }: WAUpsertInstallFormProps) {
  const {
    isEdit,
    installName,
    companyLimit,
    manualPayments,
    allowPublicSignups,
    installAppUrl,
    installApiUrl,
    po,
    billingCompanyName,
    addressLineOne,
    addressLineTwo,
    town,
    postcode,
    contactName,
    contactEmail,
    contactTelephone,
    contactNotes,
    upsertInstall,
    isBusy,
    canSubmit,
  } = useUpsertInstallForm(install);

  const onSubmit = async () => {
    const success = await upsertInstall();
    if (success) {
      onClose();
    }
  };

  return (
    <ColumnForm
      noValidate
      onSubmit={(e) => {
        e.preventDefault();
        onSubmit();
      }}
    >
      <DrawerHeader onClose={onClose}>
        {isEdit ? `Edit ${install!.name}` : 'Create Install'}
      </DrawerHeader>
      <Container>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <WCTTextField
              name="installName"
              label="Install Name"
              onChange={installName.set}
              value={installName.value}
              error={installName.error}
              required
            />
          </Grid>
          <Grid item xs={3}>
            <WCTTextField
              name="companyLimit"
              label="Company Limit"
              onChange={companyLimit.set}
              value={companyLimit.value?.toString()}
              error={companyLimit.error}
              required
            />
          </Grid>
          <Grid item xs={9}>
            <WCTSwitchField
              name="manualPayments"
              label="Manual Payments"
              onChange={manualPayments.set}
              value={manualPayments.value}
              error={manualPayments.error}
            />
            <WCTSwitchField
              name="allowPublicSignups"
              label="Allow Public Signups"
              onChange={allowPublicSignups.set}
              value={allowPublicSignups.value}
              error={allowPublicSignups.error}
            />
          </Grid>
          <Grid item xs={12}>
            <WCTTextField
              name="installAPIURL"
              label="API URL"
              onChange={installApiUrl.set}
              value={installApiUrl.value}
              error={installApiUrl.error}
              required
            />
          </Grid>
          <Grid item xs={12}>
            <WCTTextField
              name="installAPPURL"
              label="APP URL"
              onChange={installAppUrl.set}
              value={installAppUrl.value}
              error={installAppUrl.error}
              required
            />
          </Grid>
          <Grid item xs={24}>
            <Typography variant="h4">Contact Details</Typography>
          </Grid>
          <Grid item xs={12}>
            <WCTTextField
              name="contactName"
              label="Name"
              onChange={contactName.set}
              value={contactName.value}
              error={contactName.error}
              required
            />
          </Grid>
          <Grid item xs={6}>
            <WCTTextField
              name="contactEmail"
              label="Email"
              onChange={contactEmail.set}
              value={contactEmail.value}
              error={contactEmail.error}
              required
            />
          </Grid>
          <Grid item xs={6}>
            <WCTTextField
              name="contactTelephone"
              label="Telephone"
              onChange={contactTelephone.set}
              value={contactTelephone.value}
              error={contactTelephone.error}
              required
            />
          </Grid>
          <Grid item xs={12}>
            <WCTTextField
              name="contactNotes"
              label="Notes"
              onChange={contactNotes.set}
              value={contactNotes.value}
              error={contactNotes.error}
              multiline
              required
            />
          </Grid>
          <Grid item xs={24}>
            <Typography variant="h4">Billing Details</Typography>
          </Grid>
          <Grid item xs={12}>
            <WCTTextField
              name="billingCompanyName"
              label="Company Name"
              onChange={billingCompanyName.set}
              value={billingCompanyName.value}
              error={billingCompanyName.error}
              required
            />
          </Grid>
          <Grid item xs={12}>
            <WCTTextField
              name="po"
              label="PO"
              onChange={po.set}
              value={po.value}
              error={po.error}
              required
            />
          </Grid>
          <Grid item xs={12}>
            <WCTTextField
              name="addressLineOne"
              label="Address Line One"
              onChange={addressLineOne.set}
              value={addressLineOne.value}
              error={addressLineOne.error}
              required
            />
          </Grid>
          <Grid item xs={12}>
            <WCTTextField
              name="addressLineTwo"
              label="Address Line Two"
              onChange={addressLineTwo.set}
              value={addressLineTwo.value}
              error={addressLineTwo.error}
              required
            />
          </Grid>
          <Grid item xs={12}>
            <WCTTextField
              name="town"
              label="Town"
              onChange={town.set}
              value={town.value}
              error={town.error}
              required
            />
          </Grid>
          <Grid item xs={12}>
            <WCTTextField
              name="postcode"
              label="Postcode"
              onChange={postcode.set}
              value={postcode.value}
              error={postcode.error}
              required
            />
          </Grid>
        </Grid>
      </Container>
      <DrawerFooter>
        <OutlinedButton onClick={() => onClose()}>Cancel</OutlinedButton>
        <PrimaryButton type="submit" disabled={!canSubmit} isBusy={isBusy}>
          {isEdit ? 'Update' : 'Create'}
        </PrimaryButton>
      </DrawerFooter>
    </ColumnForm>
  );
}

export default withSideDrawer(WAUpsertInstallForm);
