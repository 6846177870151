import { Box, Icon, styled, Typography } from '@mui/material';
import Column from '@components/layout-util-components/column';
import FilledBadge from '@components/badges/filled-badge';
import { ReactComponent as GraphIcon } from '@assets/svgs/nodes.svg';
import { CaseSolutionChainItemResource } from '@api/types/case/case-solution/case-solution.resource';
import { BorderedContainer } from '@components/containers/bordered-container';
import { OutlinedButton } from '@components/buttons';
import { useNavigate } from 'react-router-dom';
import Row from '@components/layout-util-components/row';
import HealthBadge from '@components/badges/health-badge';
import { currencyFormatter, dateOnlyShortFormatter } from '@i18n/formatters';
import { useAppSelector } from '@store/store';
import { selectCurrentRcaCurrency } from '@store/rca-editor/selectors';
import SolutionEffectivenessBadge from '@components/outcome-report/components/solution-effectiveness-badge';
import { ReactComponent as RcaIndicatorIcon } from '@assets/svgs/analysis.svg';
import SolutionValueBadge from '@components/outcome-report/components/solution-value-badge';
import SolutionTypeBadge from '@components/outcome-report/components/solution-type-badge';
import { Gap } from '@components/layout-util-components/gap';

interface Props {
  chainItem: CaseSolutionChainItemResource;
  caseId: number;
  showHealth?: boolean;
}

const Container = styled(BorderedContainer, {
  target: 'chain-item-solution-item-container',
})({
  padding: 0,
  marginBottom: '15px',
  borderRadius: 4,
  border: '1px solid rgba(0,0,0,0.25)',
  '> div': {
    flexShrink: '1',
    gap: 0,
    justifyContent: 'space-between',
    flexGrow: 1,
    button: {
      flexShrink: '0',
      marginLeft: '1rem',
      svg: {
        marginRight: '0.5rem',
        path: {
          fill: '#FF6C00',
        },
      },
    },
  },
  '.name': {
    fontSize: '16px',
    fontWeight: '400',
    lineHeight: '24px',
    letterSpacing: '0.15000000596046448px',
    textAlign: 'left',
    wordBreak: 'break-word',
    marginBottom: '0',
  },

  '.cost': {
    '& > b': {
      fontWeight: '500',
    },
  },

  '.health': {
    marginBottom: 0,
  },
  '.scoreRow': {
    display: 'flex',

    '> *': {
      flex: '0 0 50%',
    },
    '.scoreContainer': {
      display: 'flex',
      flexDirection: 'column',
      gap: 8,
      '.label': {
        color: '#949FA2',
        fontSize: '13px',
        fontWeight: '500',
        lineHeight: '150%',
        letterSpacing: '0.15px',
        textTransform: 'uppercase',
      },
    },
  },
});

export default function RelatedCauseBoxCard({
  chainItem,
  caseId,
  showHealth = true,
}: Props) {
  const navigate = useNavigate();
  const firstItem = chainItem?.chainItemSolution?.[0];
  const currency = useAppSelector(selectCurrentRcaCurrency);

  return (
    <Container>
      <Column sx={{ flexGrow: 1, flexShrink: 0, gap: 1 }}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            margin: '10px',
          }}
        >
          <Typography className="name" variant="body2">{`${
            chainItem.description
          } (${dateOnlyShortFormatter(chainItem.created)})`}</Typography>
          <OutlinedButton
            onClick={() =>
              navigate(
                `/rca/tabs/${caseId}/chart/?chainItem=${chainItem.chainItemId}`
              )
            }
          >
            <Row>
              <Icon sx={{ display: 'flex', alignItems: 'center' }}>
                <RcaIndicatorIcon width={20} height="auto" />
              </Icon>
              View Analysis
            </Row>
          </OutlinedButton>
        </Box>
        <Box
          sx={{
            padding: '10px',
            borderTop: '1px solid rgba(0,0,0,0.10)',
          }}
        >
          {showHealth && (
            <Box className="health">
              <HealthBadge small health={chainItem?.healthScore} />
            </Box>
          )}

          <Row
            className="scoreRow"
            sx={{
              paddingTop: showHealth ? '' : '10px',
            }}
          >
            {!!firstItem?.solutionTerm && (
              <span className="scoreContainer">
                <span className="label severity">Term</span>
                <SolutionTypeBadge type={firstItem.solutionTerm} />
              </span>
            )}

            {!!firstItem?.efficacy && (
              <span className="scoreContainer">
                <span className="label severity">Effectiveness</span>
                <SolutionEffectivenessBadge
                  effectiveness={firstItem.efficacy}
                />
              </span>
            )}
          </Row>
          <Row className="scoreRow" sx={{ paddingTop: '10px' }}>
            {!!firstItem?.solutionTypeName && (
              <span className="scoreContainer">
                <span className="label severity">Type</span>
                <SolutionValueBadge value={firstItem.solutionTypeName} />
              </span>
            )}
            {!!firstItem?.additionalCost && firstItem?.additionalCost !== 0 ? (
              <span className="scoreContainer">
                <span className="label severity">Additional Cost</span>
                <Typography>
                  +
                  {currencyFormatter(firstItem.additionalCost, {
                    decimalPlaces: 0,
                    currency: currency || '',
                  })}
                </Typography>
              </span>
            ) : null}
          </Row>
        </Box>
      </Column>
    </Container>
  );
}
