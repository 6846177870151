import { styled } from '@mui/material';
import { PropsWithChildren, RefObject } from 'react';

interface Props {
  appBarRef: RefObject<HTMLDivElement>;
  onSubmit: () => void;
  noPadding?: boolean;
}

const Container = styled('form')<{ appBarTop?: number; noPadding?: boolean }>(
  ({ appBarTop, noPadding, theme: { palette } }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    minHeight: '100vh',
    width: '100vw',
    overflow: 'auto',
    padding: noPadding ? 0 : '0 10% 120px 10%',
    backgroundColor: palette.common.grey15,
    '& h2': {
      marginBottom: '10px',
    },
  })
);

export default function FullPageFormScaffold({
  appBarRef,
  onSubmit,
  children,
  noPadding,
}: PropsWithChildren<Props>) {
  return (
    <Container
      noValidate
      noPadding={noPadding}
      appBarTop={appBarRef?.current?.clientHeight}
      onSubmit={(e) => {
        e.preventDefault();
        onSubmit();
      }}
    >
      {children}
    </Container>
  );
}
