import {
  useGetCompanyUserCaseTotalsQuery,
  useGetImpactsByCoverageQuery,
  useGetSolutionsByEffectivenessQuery,
  useGetSolutionsByTermQuery,
  useGetSolutionsTermsQuery,
  useGetThemesByCoverageQuery,
  useGetUserCasesQuery,
  useGetUserCompanyGroupsQuery,
  useGetUserOutcomesQuery,
  useGetUserTaskDetailsQuery,
} from '@api/endpoints/dashboard-api';
import { useFilter, usePagination, useSortableHeader } from '@components/table';
import { AnalysisViewMode } from '@pages/app/analysis/analysis-overview-hook';
import { useMemo, useState } from 'react';
import { useGetCaseAnalysisTypesQuery } from '@api/endpoints/case-analysis-type.api';
import { DashboardFiltersRequest } from '@api/types/dashboard/dashboard-filters.request';
import { useGetCompanyUsersOptionsQuery } from '@api/endpoints/company-user.api';
import { useDeleteCaseOutcomeMutation } from '@api/endpoints/case-outcome.api';
import { usePageAlertVariants } from '@components/alerts';
import { isApiError } from '@api/types/api-error';
import useUser from '@store/user/user-hook';
import { ReportUtil } from '@util/report-util';
import { OverviewImpactFilter } from '@pages/app/rca/tabs/rca-editor-overview-hook';
import { TaskStatus } from '@api/types/task-detail/task-status';
import { useGetTaskStatusOptionsQuery } from '@api/endpoints/task-detail.api';
import { DashboardTasksFiltersRequest } from '@api/types/dashboard/dashboard-tasks-filters.request';
import { SortDirection } from '@api/types/sort-direction';
import { useGetCaseStatusOptionsQuery } from '@api/endpoints/case-status.api';
import { useGetCompanyGroupOptionsQuery } from '@api/endpoints/company/company-company-group.api';

const categoryFilters = ['RCA', 'Outcomes', 'Tasks', 'Groups'] as const;
type CategoryFilter = (typeof categoryFilters)[number];

export default function useDashboard() {
  const user = useUser();
  const { showErrorMessage, showSuccessMessage } = usePageAlertVariants();
  const [activeCategoryFilter, setActiveCategoryFilter] =
    useState<CategoryFilter>('RCA');
  const [impactFilter, setImpactFilter] = useState<
    OverviewImpactFilter | undefined
  >(OverviewImpactFilter.actual);

  const caseAnalysisTypeFilter = useFilter<string[]>();
  const companyUserFilter = useFilter<number[]>();
  const viewMode = useFilter<AnalysisViewMode>(AnalysisViewMode.grid);

  const rcaListSort = useSortableHeader({ initialProperty: 'name' });
  const rcaListPagination = usePagination();

  const onCategoryFilterChange = (e: React.MouseEvent<HTMLElement>) =>
    setActiveCategoryFilter(e.currentTarget.id as CategoryFilter);

  const { data: userOptions, isLoading: loadingUserOptions } =
    useGetCompanyUsersOptionsQuery({});

  const { data: caseAnalysisTypeOptions, isLoading: loadingCaseAnalysisTypes } =
    useGetCaseAnalysisTypesQuery({});

  const { data: taskStatusOptions, isLoading: loadingTaskStatusOptions } =
    useGetTaskStatusOptionsQuery();

  const searchTasks = useFilter<string>();
  const taskStatusFilter = useFilter<Array<TaskStatus>>([]);

  const filters = useMemo(
    (): DashboardFiltersRequest => ({
      caseAnalysisType: caseAnalysisTypeFilter.value,
      companyUserId: companyUserFilter.value,
    }),
    [caseAnalysisTypeFilter.value, companyUserFilter.value]
  );

  const tasksFilters = useMemo(
    (): DashboardTasksFiltersRequest => ({
      caseAnalysisType: caseAnalysisTypeFilter.value,
      companyUserId: companyUserFilter.value,
      searchText: searchTasks.value,
      taskStatus: taskStatusFilter.value,
    }),
    [
      caseAnalysisTypeFilter.value,
      companyUserFilter.value,
      searchTasks.value,
      taskStatusFilter.value,
    ]
  );

  const {
    data: companyUserCaseTotalsData,
    isLoading: isLoadingCompanyUserCaseTotals,
  } = useGetCompanyUserCaseTotalsQuery(filters);

  const { data: themesByCoverageData, isLoading: isLoadingThemesByCoverage } =
    useGetThemesByCoverageQuery(filters);

  const { data: impactsByCoverageData, isLoading: isLoadingImpactsByCoverage } =
    useGetImpactsByCoverageQuery(filters);

  const { data: solutionsByTermData, isLoading: isLoadingSolutionsByTerm } =
    useGetSolutionsByTermQuery(filters);

  const { data: caseStatusOptions, isLoading: loadingStatusOptions } =
    useGetCaseStatusOptionsQuery();

  const { data: caseGroupOptions, isLoading: loadingGroupOptions } =
    useGetCompanyGroupOptionsQuery({});

  const { data: solutionTerms, isLoading: loadingSolutionTerms } =
    useGetSolutionsTermsQuery({});

  const {
    data: solutionsByEffectivenessData,
    isLoading: isLoadingSolutionsByEffectiveness,
  } = useGetSolutionsByEffectivenessQuery(filters);

  const userCaseSort = useSortableHeader({
    initialProperty: 'created',
    initialDirection: SortDirection.desc,
  });

  const userCaseSearch = useFilter<string>();
  const userCaseStatus = useFilter<number[]>();
  const userCaseGroup = useFilter<number[]>();
  const {
    data: userCases,
    isLoading: isLoadingUserCases,
    isFetching: isFetchingUserCases,
  } = useGetUserCasesQuery({
    ...filters,
    searchText: userCaseSearch.value,
    sortBy: userCaseSort.property,
    sortDirection: userCaseSort.direction,
    status: userCaseStatus.value,
    groups: userCaseGroup.value,
  });

  const userGroupSearch = useFilter<string>();
  const userGroupSort = useSortableHeader({ initialProperty: 'groupName' });

  const {
    data: userGroups,
    isLoading: isLoadingCompanyGroups,
    isFetching: isFetchingUserGroups,
  } = useGetUserCompanyGroupsQuery({
    ...filters,
    searchText: userGroupSearch.value,
    sortBy: userGroupSort.property,
    sortDirection: userGroupSort.direction,
  });

  const { data: userOutcomes, isLoading: isLoadingUserOutcomes } =
    useGetUserOutcomesQuery(filters);

  const { data: userTasks, isLoading: isLoadingTaskDetails } =
    useGetUserTaskDetailsQuery(tasksFilters);

  const [deleteOutcome] = useDeleteCaseOutcomeMutation();

  const isLoading =
    isLoadingCompanyUserCaseTotals ||
    isLoadingThemesByCoverage ||
    isLoadingImpactsByCoverage ||
    isLoadingSolutionsByTerm ||
    isLoadingSolutionsByEffectiveness ||
    loadingCaseAnalysisTypes ||
    loadingUserOptions ||
    isLoadingUserCases ||
    isLoadingCompanyGroups ||
    isLoadingUserOutcomes ||
    isLoadingTaskDetails ||
    loadingTaskStatusOptions ||
    loadingStatusOptions ||
    loadingGroupOptions ||
    loadingSolutionTerms;

  const onDeleteOutcome = async (caseId: number, outcomeId: number) => {
    try {
      await deleteOutcome({ caseId, outcomeId }).unwrap();
      showSuccessMessage('Outcome deleted');
      return true;
    } catch (e) {
      if (isApiError(e)) {
        showErrorMessage(e.message);
      }
      return false;
    }
  };

  const toggleImpactFilter = (value: OverviewImpactFilter) => {
    setImpactFilter(value === impactFilter ? undefined : value);
  };

  const categoryFilterCounts = [
    userCases?.count,
    userOutcomes?.count,
    userTasks?.count,
    userGroups?.count,
  ] as const;

  const canFilterByUser = user.isAdminOrSuperAdminUser;

  const groupedSolutionReport = useMemo(() => {
    if (solutionsByEffectivenessData == null || solutionsByTermData == null) {
      return {
        byTerm: null,
        byTermCount: 0,
        byEffectiveness: null,
        byEffectivenessCount: 0,
      };
    }

    const groupedData =
      ReportUtil.getGroupedSolutionTermAndEffectivenessReports(
        solutionsByEffectivenessData,
        solutionsByTermData
      );

    // Remove no data entry
    const byTerm = Object.values(groupedData.byTerm);
    const byEffectiveness = Object.values(groupedData.byEffectiveness);

    return {
      byTerm,
      byTermCount: solutionsByTermData.totals.solutionCount,
      byEffectiveness,
      byEffectivenessCount: solutionsByEffectivenessData.totals.solutionsCount,
    };
  }, [solutionsByEffectivenessData, solutionsByTermData]);

  return {
    companyUserCaseTotals: companyUserCaseTotalsData,
    themesByCoverage: themesByCoverageData,
    themesByCoverageCount: Math.max(
      (themesByCoverageData?.data.length ?? 0) - 1,
      0
    ),
    impactsByCoverage:
      impactsByCoverageData?.data?.filter(
        (x, i) =>
          impactFilter === undefined ||
          (impactsByCoverageData?.metaData[i].actual &&
            impactFilter === OverviewImpactFilter.actual) ||
          (!impactsByCoverageData?.metaData[i].actual &&
            impactFilter === OverviewImpactFilter.potential)
      ) ?? [],
    impactsByCoverageCount: Math.max(
      (impactsByCoverageData?.data.length ?? 0) - 1,
      0
    ),
    groupedSolutionReport,
    isLoading,
    userCases,
    userTasks,
    userGroups,
    userOutcomes,
    categoryFilters,
    activeCategoryFilter,
    onCategoryFilterChange,
    viewMode,
    rcaListPagination,
    rcaListSort,
    caseAnalysisTypeOptions,
    caseAnalysisTypeFilter,
    userOptions,
    companyUserFilter,
    onDeleteOutcome,
    categoryFilterCounts,
    canFilterByUser,
    impactFilter,
    toggleImpactFilter,
    searchTasks,
    taskStatusFilter,
    taskStatusOptions,
    userCaseSearch,
    isFetchingUserCases,
    userCaseSort,
    userCaseGroup,
    userCaseStatus,
    caseStatusOptions,
    caseGroupOptions,
    userGroupSearch,
    userGroupSort,
    loadingUserOptions,
    isFetchingUserGroups,
    solutionTerms,
  };
}

export type DashboardState = ReturnType<typeof useDashboard>;
