import { StepLayout } from '@pages/app/rca/create/components/step-layout';
import ActionsRow from '@components/layout-util-components/actions-row';
import { OutlinedButton, PrimaryButton } from '@components/buttons';
import { Typography } from '@mui/material';
import Spacer from '@components/layout-util-components/spacer';
import useCollaboration from '@pages/app/rca/create/steps/collaboration-step-hook';
import CollaborateForm from '@pages/app/rca/create/components/collaborate-form';
import CollaborateUsers from '@pages/app/rca/create/components/collaborate-users';
import { useAppSelector } from '@store/store';
import { selectStepTitle } from '@store/create-analysis-process/selectors';
import { RcaStepProps } from '@pages/app/rca/create/rca-creation-steps-page';
import useDirtyGuard from '@hooks/use-dirty-guard';
import { LoadingIndicator } from '@components/loading-indicator';
import { Gap } from '@components/layout-util-components/gap';
import Column from '@components/layout-util-components/column';

export default function CollaborationStep({
  caseId,
  onGoBack,
  onComplete,
}: RcaStepProps) {
  const { confirmDirty } = useDirtyGuard();

  const title = useAppSelector((state) =>
    selectStepTitle(state, 'Collaborate')
  );

  const state = useCollaboration(caseId!);
  const {
    isEdit,
    //viewMode,
    onSyncGroups,
    isBusy,
    isLoading,
    // contributors,
    // invitedGroups,
    // isPublicCase,
    canSubmit,
    //setViewMode,
    shouldCollaborate,
    onCompleteCase,
  } = state;

  const onNext = async () => {
    const didSync = await onSyncGroups();
    if (didSync) {
      if (!isEdit) {
        const didComplete = await onCompleteCase();
        if (didComplete) {
          onComplete();
        }
      }
    }
  };

  return (
    <StepLayout
      marginTop={!isEdit}
      actionsRow={
        <>
          <ActionsRow>
            {
              <OutlinedButton onClick={confirmDirty(onGoBack)}>
                Back
              </OutlinedButton>
            }
            <Spacer />
            <PrimaryButton
              disabled={!canSubmit}
              isBusy={isBusy}
              onClick={onNext}
            >
              {isEdit ? 'Update' : 'Continue'}
            </PrimaryButton>
          </ActionsRow>
        </>
      }
    >
      <Column sx={{ marginBottom: '100px' }}>
        <Typography variant="h5">{title}</Typography>
        <Gap size={10} />
        {isLoading ? (
          <LoadingIndicator show />
        ) : (
          <>
            <CollaborateForm state={state} />
            {shouldCollaborate.value && (
              <>
                {/* <OverviewActions>
                <Row className="item">
                  <div className="icon">
                    <LockSvg />
                  </div>
                  <Column>
                    <div className="title">
                      {isPublicCase.value ? 'Public' : 'Private'} Investigation
                    </div>
                    <div className="text">Example sub text</div>
                  </Column>
                </Row>
                <div className="divider" />
                <Row className="item">
                  <div className="icon">
                    <LockSvg />
                  </div>
                  <Column>
                    <div className="title">
                      Viewing Groups
                      {(invitedGroups?.length ?? 0) > 0
                        ? ` (${invitedGroups!.length})`
                        : ''}
                    </div>
                    <div className="text">Example sub text</div>
                  </Column>
                </Row>
                <div className="divider" />
                <Row className="item">
                  <div className="icon">
                    <UsersSvg />
                  </div>
                  <Column>
                    <div className="title">
                      Contributors
                      {contributors != null
                        ? ` (${contributors!.model.length})`
                        : ''}
                    </div>
                    <div className="text">Example sub text</div>
                  </Column>
                </Row>
                <Spacer />
                <Box py="9px" pr="15px">
                  <OutlinedButton
                    onClick={() => setViewMode(CollaborationViewMode.form)}
                  >
                    Edit
                  </OutlinedButton>
                </Box>
              </OverviewActions> */}
                <Gap size={32} />
                <CollaborateUsers state={state} />
              </>
            )}
          </>
        )}
      </Column>
    </StepLayout>
  );
}
