import requireAuth from '@components/hoc/with-required-auth-hoc';
import { Box, Chip, styled, Typography } from '@mui/material';
import {
  faBuilding,
  faCalendar,
  faChair,
  faUser as faLiteUser,
  faUser,
} from '@fortawesome/pro-light-svg-icons';
import { CompanyUserCaseTotalsResponse } from '@api/types/dashboard/company-user-case-totals-response';
import useUser from '@store/user/user-hook';
import { DropDownFilter } from '@components/table';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ReactNode } from 'react';
import { ReactComponent as RcaIndicatorIcon } from '@assets/svgs/analysis.svg';
import useWADashboard from '@pages/wct-admin/dashboard/wa-dashboard-hook';
import {
  DashboardLeagueTable,
  DashboardSplitCoverage,
  DashboardStatBox,
  DashboardStatContainer,
  LeagueTableStat,
} from '@components/dashboard/dashboard-stat-container';
import { faUser as faProUser } from '@fortawesome/pro-solid-svg-icons';
import { Gap } from '@components/layout-util-components/gap';
import Column from '@components/layout-util-components/column';
import { getColorForId } from '@util/colour-identifiers';
import { RadialOverviewStat } from '@components/outcome-report/components/radial-chart';
import Row from '@components/layout-util-components/row';
import { SortDirection } from '@api/types/sort-direction';

const StyledHeaderBox = styled(Box)<{ styleButtons?: true }>(
  ({ theme: { palette }, styleButtons }) => ({
    display: 'flex',
    flex: '1 1 auto',
    flexWrap: 'wrap',
    marginBottom: 25,
    alignItems: 'center',
    '.radio-filter': {
      marginLeft: 'auto',
    },
    rowGap: 10,
    columnGap: 16,
    ...(styleButtons && {
      button: {
        borderRadius: 4,
        background: palette.common.grey40,
        color: '#001927',
        fontSize: '13px',
        lineHeight: '24px' /* 184.615% */,
        letterSpacing: '0.4px',
        display: 'flex',
        gap: 8,
        padding: '6px 16px',
        justifyContent: 'center',
        alignItems: 'center',
        '&:hover': {
          background: '#D8D8E3',
        },
        '&.create-rca, &.active': {
          background: 'linear-gradient(90deg, #FF6C00 0%, #FF6C00 100%)',
          color: palette.common.white,
          fontSize: '14px',
          fontWeight: 400,
          lineHeight: '24px' /* 171.429% */,
          letterSpacing: '0.4px',
        },
      },
    }),
    h1: {
      lineHeight: '133.4%' /* 32.016px */,
      marginRight: 'auto',
    },
  })
);

const PanelContainer = styled(Box)({
  display: 'flex',
  flexWrap: 'wrap',
  gap: 25,
  alignSelf: 'stretch',
  '>div': {
    '@media (max-width:750px)': {
      width: '100%',
    },
  },
});

// const companyUserCaseTotalToIconAndLabel: Record<
//   string,
//   {
//     label: string;
//     icon: ReactNode;
//     bgColour: string;
//     fontColour: string;
//     totalChainItems: number;
//   }
// > = {
//   openCases: {
//     label: 'OPEN RCAS',
//     icon: <RcaIndicatorIcon width={28} height="auto" fill="#4CB648" />,
//     bgColour: '#4CB64833',
//     fontColour: '#4CB648',
//   },
//   overdueTasks: {
//     label: 'OVERDUE TASKS',
//     icon: <FontAwesomeIcon icon={faCalendar} fill="#FF3D3D" />,
//     bgColour: '#FF3D3D33',
//     fontColour: '#FF3D3D',
//   },
//   totalCases: {
//     label: 'TOTAL RCAS',
//     icon: <RcaIndicatorIcon width={28} height="auto" fill="#FF6C00" />,
//     bgColour: '#FF6C0033',
//     fontColour: '#FF6C00',
//   },
//   users: {
//     label: 'USERS',
//     icon: <FontAwesomeIcon icon={faUser} fill="#0097E1" />,
//     bgColour: '#0097E133',
//     fontColour: '#0097E1',
//   },
// };

function WADashboard() {
  const {
    caseStatuses,
    sectors,
    isLoading,
    caseTypeFilter,
    caseTypes,
    companyFilter,
    companyOptions,
    companySeats,
    installFilter,
    packageFilter,
    packageOptions,
    regionFilter,
    regions,
    healthScores,
    dateFrom,
    dateTo,
    impactCategories,
    solutionEfficacy,
    solutionTerm,
    themes,
    sortDirection,
    adminStats,
    isPackageSelected,
    toggleSelectedPackage,
    toggleSelectedSortDirection,
  } = useWADashboard();
  const { firstName } = useUser();

  if (isLoading) {
    return <StyledHeaderBox />;
  }

  const mapStats = (stats: Record<string, number>): LeagueTableStat[] => {
    return Object.keys(stats).map(
      (key, index): LeagueTableStat => ({
        id: index,
        name: key,
        percentage: stats[key],
        colour: getColorForId(index),
      })
    );
  };

  const mapOverviewStats = (
    stats: Record<string, number>
  ): RadialOverviewStat[] => {
    return Object.keys(stats).map(
      (key, index): RadialOverviewStat => ({
        id: index,
        name: key,
        percentage: stats[key],
        colour: getColorForId(index),
      })
    );
  };

  return (
    <Column
      id="dashboard-page"
      sx={{
        padding: '50px 0',
        margin: '0 20px',
        '@media (min-width:768px)': {
          margin: '0 80px',
        },
        '@media (min-width:1500px)': {
          margin: '0 100px',
        },
        '@media (min-width:1650px)': {
          margin: '0 auto',
          maxWidth: '1400px',
        },
      }}
    >
      <StyledHeaderBox>
        <Typography variant="h1">Welcome, {firstName}</Typography>
        <DropDownFilter
          options={companyOptions!}
          value={companyFilter.value}
          onChange={companyFilter.set}
        >
          Company
        </DropDownFilter>
      </StyledHeaderBox>
      <DashboardStatContainer isTotals>
        <DashboardStatBox
          label="Companies"
          value={adminStats!.companiesCount}
          icon={<FontAwesomeIcon icon={faBuilding} fill="#4CB648" />}
          bgColor="#4CB64833"
          fontColour="#4CB648"
        />

        <DashboardStatBox
          label="Standard Subscriptions"
          value={adminStats!.standardCount}
          icon={<FontAwesomeIcon icon={faLiteUser} fill="#4CB648" />}
          bgColor="#4CB64833"
          fontColour="#4CB648"
        />
        <DashboardStatBox
          label="Pro Subscriptions"
          value={adminStats!.proCount}
          icon={<FontAwesomeIcon icon={faProUser} fill="#4CB648" />}
          bgColor="#4CB64833"
          fontColour="#4CB648"
        />
        <DashboardStatBox
          label="No. of paid / unpaid seats"
          value={`${adminStats!.paidSeatsCount} / ${
            adminStats!.unpaidSeatsCount
          }`}
          icon={<FontAwesomeIcon icon={faChair} fill="#4CB648" />}
          bgColor="#4CB64833"
          fontColour="#4CB648"
        />
      </DashboardStatContainer>
      <Gap size={15} />
      <DashboardStatContainer isTotals>
        <DashboardStatBox
          label="Total RCAs"
          value={adminStats!.caseCount}
          icon={<FontAwesomeIcon icon={faBuilding} fill="#4CB648" />}
          bgColor="#4CB64833"
          fontColour="#4CB648"
        />

        <DashboardStatBox
          label="Open Cases"
          value={adminStats!.openCaseCount}
          icon={<FontAwesomeIcon icon={faLiteUser} fill="#4CB648" />}
          bgColor="#4CB64833"
          fontColour="#4CB648"
        />
        <DashboardStatBox
          label="Completed Cases"
          value={adminStats!.completeCaseCount}
          icon={<FontAwesomeIcon icon={faLiteUser} fill="#4CB648" />}
          bgColor="#4CB64833"
          fontColour="#4CB648"
        />
        <DashboardStatBox
          label="Solutions Identified"
          value={adminStats!.validatedCaseCount}
          icon={<FontAwesomeIcon icon={faProUser} fill="#4CB648" />}
          bgColor="#4CB64833"
          fontColour="#4CB648"
        />
      </DashboardStatContainer>

      <DashboardStatContainer>
        <DashboardLeagueTable
          title="Top themes by coverage"
          tableTitle="ALL RCAS"
          emptyMessage="No themes have been assigned to cause boxes as part of an RCA investigation"
          entries={mapStats(themes!)}
          hideCoverageLabel
          showCauseBox={false}
        />
        <DashboardLeagueTable
          title="Impact categories by coverage"
          tableTitle="ALL RCAS"
          emptyMessage="No impact categories have been assigned to an RCA investigation"
          entries={mapStats(impactCategories!)}
          hideCoverageLabel
          showCauseBox={false}
        />
        <DashboardSplitCoverage
          title="Solutions"
          subtitle="ALL RCAS"
          emptyMessage="No solutions have been created as part of an RCA investigation"
          topTitle="Term breakdown"
          topSubtitle={`${Object.values(solutionTerm!).length} Solutions`}
          topEntries={mapOverviewStats(solutionTerm!)}
          bottomTitle="Effectiveness breakdown"
          bottomSubtitle={`${
            Object.values(solutionEfficacy!).length
          } Solutions`}
          bottomEntries={mapOverviewStats(solutionEfficacy!)}
        />
      </DashboardStatContainer>
      <DashboardStatContainer>
        <DashboardLeagueTable
          title="Largest Subscriptions"
          tableTitle="Number of seats"
          emptyMessage="No subscriptions have been created"
          entries={companySeats!.map((x, i) => ({
            id: i,
            colour: getColorForId(i),
            name: x.id,
            percentage: Number(x.label),
          }))}
          afterTitle={
            packageOptions!.length > 1 &&
            Object.values(companySeats!).length > 0 && (
              <Row sx={{ gap: 1 }}>
                {packageOptions!.map((packageOption) => (
                  <Chip
                    key={packageOption.id}
                    label={packageOption.label}
                    size="small"
                    color={
                      isPackageSelected(packageOption!.id)
                        ? 'primary'
                        : 'default'
                    }
                    onClick={() => toggleSelectedPackage(packageOption!.id)}
                  />
                ))}
              </Row>
            )
          }
          hideCoverageLabel
          noPercentage
          showCauseBox={false}
        />
        <DashboardLeagueTable
          title="Subscriptions by region"
          tableTitle="Region"
          emptyMessage="No subscriptions by region have been created"
          entries={mapStats(regions!)}
          afterTitle={
            Object.values(regions!).length > 0 && (
              <Row sx={{ gap: 1 }}>
                <Chip
                  label="Highest"
                  size="small"
                  color={
                    sortDirection.value === SortDirection.desc
                      ? 'primary'
                      : 'default'
                  }
                  onClick={() =>
                    toggleSelectedSortDirection(SortDirection.desc)
                  }
                />
                <Chip
                  label="Lowest"
                  size="small"
                  color={
                    sortDirection.value === SortDirection.asc
                      ? 'primary'
                      : 'default'
                  }
                  onClick={() => toggleSelectedSortDirection(SortDirection.asc)}
                />
              </Row>
            )
          }
          hideCoverageLabel
          showCauseBox={false}
        />
      </DashboardStatContainer>
      <DashboardStatContainer>
        <DashboardSplitCoverage
          title="RCA Type"
          subtitle="ALL RCAS"
          emptyMessage="No RCAs have been found"
          topTitle="Type of RCA"
          topSubtitle={`${Object.values(caseTypes!).length} RCAs`}
          topEntries={mapOverviewStats(caseTypes!)}
          bottomTitle="Completion Status"
          bottomSubtitle={`${Object.values(caseStatuses!).length} RCAs`}
          bottomEntries={mapOverviewStats(caseStatuses!)}
        />

        <DashboardLeagueTable
          title="Top sectors by company"
          tableTitle="ALL RCAS"
          emptyMessage="No sectors have been assigned to any companies"
          entries={mapStats(sectors!)}
          hideCoverageLabel
          showCauseBox={false}
        />

        <DashboardLeagueTable
          title="Lowest health score by company"
          tableTitle="Average health score"
          emptyMessage="No health scores have been found"
          entries={healthScores!.map((x, i) => ({
            id: i,
            colour: getColorForId(i),
            name: x.id,
            percentage: Number(x.label),
          }))}
          hideCoverageLabel
          showCauseBox={false}
        />
      </DashboardStatContainer>

      {/*<DashboardStatContainer>*/}
      {/*  <DashboardLeagueTable*/}
      {/*    title="Top themes by coverage"*/}
      {/*    tableTitle="Theme"*/}
      {/*    emptyMessage="No themes have been assigned to cause boxes as prt of an RCA investigation"*/}
      {/*    entries={*/}
      {/*      themesByCoverage?.data.map((row) => ({*/}
      {/*        id: row.clientGeneratedId,*/}
      {/*        name: row.cells[1].value,*/}
      {/*        percentage: parseFloat(row.cells[3].value),*/}
      {/*        colour: row.colour,*/}
      {/*      })) ?? []*/}
      {/*    }*/}
      {/*  />*/}
      {/*  <DashboardSplitCoverage*/}
      {/*    title="Solutions Insight"*/}
      {/*    subtitle="ALL RCAS"*/}
      {/*    emptyMessage="No solutions have been created as part of an RCA investigation"*/}
      {/*    topTitle="Term breakdown"*/}
      {/*    topSubtitle={`${solutionsByTermCount} Solutions`}*/}
      {/*    topEntries={solutionsByTerm ?? []}*/}
      {/*    bottomTitle="Effectiveness breakdown"*/}
      {/*    bottomSubtitle={`${solutionsByEffectivenessCount} Solutions`}*/}
      {/*    bottomEntries={solutionsByEffectiveness ?? []}*/}
      {/*  />*/}
      {/*  <DashboardLeagueTable*/}
      {/*    title="Top impacts by coverage"*/}
      {/*    tableTitle="Impact Category"*/}
      {/*    emptyMessage="No impacts have been assigned to an RCA investigation"*/}
      {/*    entries={*/}
      {/*      impactsByCoverage?.map((row) => ({*/}
      {/*        id: row.clientGeneratedId,*/}
      {/*        name: row.cells[1].value,*/}
      {/*        percentage: parseFloat(row.cells[3].value),*/}
      {/*        colour: row.colour,*/}
      {/*      })) ?? []*/}
      {/*    }*/}
      {/*    afterTitle={*/}
      {/*      (impactsByCoverage?.length ?? 0) > 0 && (*/}
      {/*        <Row sx={{ gap: 1 }}>*/}
      {/*          <Chip*/}
      {/*            label="Actual"*/}
      {/*            size="small"*/}
      {/*            color={*/}
      {/*              impactFilter === OverviewImpactFilter.actual*/}
      {/*                ? 'primary'*/}
      {/*                : 'default'*/}
      {/*            }*/}
      {/*            onClick={() =>*/}
      {/*              toggleImpactFilter(OverviewImpactFilter.actual)*/}
      {/*            }*/}
      {/*          />*/}
      {/*          <Chip*/}
      {/*            label="Potential"*/}
      {/*            size="small"*/}
      {/*            color={*/}
      {/*              impactFilter === OverviewImpactFilter.potential*/}
      {/*                ? 'primary'*/}
      {/*                : 'default'*/}
      {/*            }*/}
      {/*            onClick={() =>*/}
      {/*              toggleImpactFilter(OverviewImpactFilter.potential)*/}
      {/*            }*/}
      {/*          />*/}
      {/*        </Row>*/}
      {/*      )*/}
      {/*    }*/}
      {/*  />*/}
      {/*</DashboardStatContainer>*/}
      {/*<StyledHeaderBox styleButtons>*/}
      {/*  {categoryFilters.map((filter, i) => (*/}
      {/*    <Button*/}
      {/*      key={filter}*/}
      {/*      id={filter}*/}
      {/*      disabled={isLoading}*/}
      {/*      onClick={onCategoryFilterChange}*/}
      {/*      className={activeCategoryFilter === filter ? 'active' : ''}*/}
      {/*    >*/}
      {/*      {categoryFilterCounts[i]*/}
      {/*        ? `${filter} (${categoryFilterCounts[i]})`*/}
      {/*        : filter}*/}
      {/*    </Button>*/}
      {/*  ))}*/}
      {/*</StyledHeaderBox>*/}
    </Column>
  );
}

export default requireAuth(WADashboard);
