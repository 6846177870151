import { LensesPanelState } from '@pages/app/rca/tabs/lenses/lenses-panel-hook';
import {
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
} from '@components/ui-popup/drawers';
import { Divider } from '@components/layout-util-components/divider';
import ActionsRow from '@components/layout-util-components/actions-row';
import { OutlinedButton, PrimaryButton } from '@components/buttons';
import { Box, FormHelperText, Link, Typography } from '@mui/material';
import WCTSelectField from '@components/input/select-field';
import { InputSkeleton } from '@components/skeletons';
import { ColumnForm } from '@components/layout-util-components/column';
import useImpactReportView from '@pages/app/rca/tabs/lenses/views/report-form-hook';
import InlineRadioSelectGroup from '@components/input/inline-radio-select-group';
import { LoadingIndicator } from '@components/loading-indicator';
import SelectableReportTypeListItem from '@pages/app/rca/tabs/lenses/components/selectable-report-type-list-item';
import { Gap } from '@components/layout-util-components/gap';
import { ReportType } from '@api/types/report-type/report-type.option';
import OnboardingEntryContainer from '@components/containers/onboarding-entry-container';
import { ReactComponent as GlassesIcon } from '@assets/svgs/glasses-light.svg';
import { ITypeDetails } from '../lenses-panel';

interface Props {
  onClose: () => void;
  state: LensesPanelState;
  types: ITypeDetails[];
}

export default function ReportFormView({ onClose, state, types }: Props) {
  const { backToOptionsView } = state;
  const {
    reportType,
    reportSubType,
    reportSubTypeOptions,
    loadingReportSubTypes,
    hasLoadedReportSubTypes,
    canSubmit,
    submit,
    isLoading,
    trackedImpactOptions,
    impactReportGroupOptions,
    trackedImpact,
    rankBy,
    rankByOptions,
    loadingRankByOptions,
    hasLoadedRankByOptions,
    impactGroup,
    selectableOptions,
    toggleSelect,
    isSelected,
    shouldDisplayImpactOptions,
    shouldDisplayFilters,
    clearSelected,
    selectAll,
    selectedIds,
    caseImpacts,
    filteredCaseImpacts,
  } = useImpactReportView(state);

  const type = types.find((x) => x.type === reportType.reportType);

  return (
    <ColumnForm
      noValidate
      onSubmit={async (e) => {
        e.preventDefault();
        if (await submit()) {
          onClose();
        }
      }}
    >
      <DrawerHeader onBack={backToOptionsView} onClose={onClose}>
        {reportType.name}
      </DrawerHeader>
      <DrawerContent>
        {type && <Typography variant="body2">{type.message}</Typography>}
        <Gap size={20} />
        {loadingReportSubTypes ? (
          <InputSkeleton />
        ) : hasLoadedReportSubTypes && reportSubTypeOptions!.length > 1 ? (
          <WCTSelectField
            id="reportType"
            name="reportType"
            label="Report Type"
            options={reportSubTypeOptions!.map((option) => ({
              id: option.reportSubTypeId,
              label: option.name,
            }))}
            value={reportSubType.value}
            error={reportSubType.error}
            onChange={reportSubType.set}
            required
          />
        ) : null}
        {loadingRankByOptions ? (
          <>
            <Gap size={20} />
            <InputSkeleton />
          </>
        ) : hasLoadedRankByOptions && rankByOptions!.length > 1 ? (
          <>
            <Gap size={20} />
            <WCTSelectField
              id="rankBy"
              name="rankBy"
              label="Rank By"
              options={rankByOptions!.map((option) => ({
                id: option.reportRankById,
                label: option.name,
              }))}
              value={rankBy.value}
              error={rankBy.error}
              onChange={rankBy.set}
              required
            />
          </>
        ) : null}
        {shouldDisplayImpactOptions &&
        (isLoading || trackedImpactOptions.length > 0) ? (
          <>
            <Gap size={20} />
            {isLoading ? (
              <InputSkeleton />
            ) : (
              <WCTSelectField
                id="trackedImpact"
                name="trackedImpact"
                label="Tracked Impact Category"
                options={trackedImpactOptions!.map((option) => ({
                  id: option.caseImpactTypeId,
                  label: option.name,
                }))}
                value={trackedImpact.value}
                error={trackedImpact.error}
                onChange={trackedImpact.set}
                required
              />
            )}
            <Gap size={20} />
            {isLoading ? (
              <InputSkeleton />
            ) : (
              <WCTSelectField
                id="filteredCaseImpacts"
                name="filteredCaseImpacts"
                label="Tracked Impacts"
                options={caseImpacts!.map((option) => ({
                  id: option.caseImpactId,
                  label: option.name,
                  group: option.actualValue ? 'Actual' : 'Potential',
                }))}
                value={filteredCaseImpacts.value}
                error={filteredCaseImpacts.error}
                onChange={filteredCaseImpacts.set}
                readonly={!trackedImpact.value}
                multiple
                group
              />
            )}
            <Gap size={20} />
            {isLoading ? (
              <InputSkeleton />
            ) : reportType.reportType === ReportType.dominant ? (
              <InlineRadioSelectGroup
                name="displayLevel"
                label="Display Level"
                options={impactReportGroupOptions!}
                value={impactGroup.value}
                error={impactGroup.error}
                onChange={impactGroup.set}
              />
            ) : null}
          </>
        ) : null}
        {shouldDisplayFilters &&
        (isLoading || selectableOptions.options.length > 0) ? (
          <>
            <Divider addMargin compact />
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography>{`Filter ${selectableOptions.name}${
                selectableOptions.options.length === 1 ? '' : 's'
              } ${
                selectableOptions.isLoading
                  ? ''
                  : `(${selectableOptions.options.length})`
              }`}</Typography>
              <Link
                onClick={(e) => {
                  e.preventDefault();
                  clearSelected();
                }}
              >
                Clear All
              </Link>
              <Link
                onClick={(e) => {
                  e.preventDefault();
                  selectAll();
                }}
              >
                Select All
              </Link>
            </Box>

            <Gap size={12} />
            {selectedIds.error ? (
              <Box mx={2}>
                <FormHelperText error>{selectedIds.error}</FormHelperText>
              </Box>
            ) : null}
            {selectableOptions.isLoading ? (
              <LoadingIndicator show />
            ) : (
              selectableOptions.options.map((option) => (
                <SelectableReportTypeListItem
                  option={option}
                  isSelected={isSelected(option)}
                  onToggleSelect={() => toggleSelect(option)}
                />
              ))
            )}
          </>
        ) : null}
        {((shouldDisplayFilters &&
          !isLoading &&
          selectableOptions.options.length === 0) ||
          (shouldDisplayImpactOptions &&
            !isLoading &&
            trackedImpactOptions.length === 0)) && (
          <>
            <Gap size={20} />
            <OnboardingEntryContainer
              icon={<GlassesIcon />}
              title="Not enough data"
              message="Not enough data in the RCA to run report"
              backgroundColor
            />
          </>
        )}
      </DrawerContent>
      <DrawerFooter showBorderTop>
        <ActionsRow>
          <OutlinedButton onClick={backToOptionsView}>Cancel</OutlinedButton>
          <PrimaryButton type="submit" disabled={!canSubmit}>
            Generate
          </PrimaryButton>
        </ActionsRow>
      </DrawerFooter>
    </ColumnForm>
  );
}
