import { useAppSelector } from '@store/store';
import { selectCurrentRcaCaseId } from '@store/rca-editor/selectors';
import {
  useGetCaseDetailQuery,
  useGetCaseOverviewTotalsQuery,
  useGetGroupOptionsForCaseQuery,
  useGetUsersForCaseQuery,
  useOpenCaseMutation,
} from '@api/endpoints/case.api';
import {
  useGetCaseOverviewEvidenceStrengthCoveragesQuery,
  useGetCaseOverviewImpactsQuery,
  useGetCaseOverviewSolutionCoveragesQuery,
  useGetCaseOverviewSolutionTermCoveragesQuery,
  useGetCaseOverviewThemesQuery,
} from '@api/endpoints/case-report.api';
import {
  CaseOutcomePathParams,
  useDeleteCaseOutcomeMutation,
  useGetCaseOutcomesQuery,
} from '@api/endpoints/case-outcome.api';
import { ApiError } from '@api/types/api-error';
import { usePageAlertVariants } from '@components/alerts';
import { useMemo, useState } from 'react';
import { ReportRow } from '@api/types/case/case-report/run-report.response';
import { useCasePermission } from '@hooks/case/use-case-permission';
import useBusyAction from '@hooks/use-busy-action-hook';
import { invalidation } from '@api/cache-util';
import { ReportUtil } from '@util/report-util';
import { LeagueTableStat } from '@components/dashboard/dashboard-stat-container';
import { getStrengthColor } from '@util/colour-identifiers';

export enum OverviewImpactFilter {
  actual = 'Actual',
  potential = 'Potential',
}

export default function useRcaEditorOverview() {
  const { showErrorMessage, showSuccessMessage } = usePageAlertVariants();
  const [impactFilter, setImpactFilter] = useState<
    OverviewImpactFilter | undefined
  >(OverviewImpactFilter.actual);
  const { canChangeCaseDetails, canContribute, canReopenCase } =
    useCasePermission();
  const caseId = useAppSelector(selectCurrentRcaCaseId);

  const {
    data: caseDetail,
    isLoading: loadingCaseDetail,
    isFetching,
  } = useGetCaseDetailQuery(caseId, { refetchOnMountOrArgChange: true });
  const { data: totals, isLoading: loadingTotals } =
    useGetCaseOverviewTotalsQuery(caseId, { refetchOnMountOrArgChange: true });
  const { data: themes, isLoading: loadingThemes } =
    useGetCaseOverviewThemesQuery(caseId, { refetchOnMountOrArgChange: true });
  const { data: solutions, isLoading: loadingSolutions } =
    useGetCaseOverviewSolutionCoveragesQuery(caseId, {
      refetchOnMountOrArgChange: true,
    });
  const {
    data: solutionTermCoverage,
    isLoading: loadingSolutionTermCoverages,
  } = useGetCaseOverviewSolutionTermCoveragesQuery(caseId, {
    refetchOnMountOrArgChange: true,
  });
  const {
    data: evidenceStrengthCoverage,
    isLoading: loadingEvidenceStrengthCoverage,
  } = useGetCaseOverviewEvidenceStrengthCoveragesQuery(caseId, {
    refetchOnMountOrArgChange: true,
  });
  const { data: impactsReport, isLoading: loadingImpacts } =
    useGetCaseOverviewImpactsQuery(caseId, { refetchOnMountOrArgChange: true });
  const { data: outcomes, isLoading: loadingOutcomes } =
    useGetCaseOutcomesQuery(caseId, { refetchOnMountOrArgChange: true });

  const { data: contributors, isLoading: loadingContributors } =
    useGetUsersForCaseQuery({
      caseId,
      invitedOnly: true,
    });

  const { data: invitedGroups, isLoading: loadingInvitedGroups } =
    useGetGroupOptionsForCaseQuery(caseId);

  const [openCase] = useOpenCaseMutation();
  const [deleteCaseOutcome] = useDeleteCaseOutcomeMutation();

  const isLoading =
    loadingCaseDetail ||
    loadingTotals ||
    loadingThemes ||
    loadingSolutions ||
    loadingImpacts ||
    loadingOutcomes ||
    loadingContributors ||
    loadingInvitedGroups ||
    loadingSolutionTermCoverages ||
    loadingEvidenceStrengthCoverage;

  const canShowCompleteButton =
    canChangeCaseDetails && caseDetail?.caseStatusName === 'In Progress';

  const deleteOutcome = async (outcomeId: number) => {
    try {
      await deleteCaseOutcome({ caseId, outcomeId });

      showSuccessMessage('Outcome deleted successfully');
      return true;
    } catch (error) {
      const { message, errors } = error as ApiError<CaseOutcomePathParams>;

      showErrorMessage(errors?.caseId ?? errors?.outcomeId ?? message);
      return false;
    }
  };

  const filteredImpacts = useMemo(() => {
    if (impactsReport == null) {
      return {
        data: [],
        metaData: [],
        columns: [],
        totals: {},
        realCount: 0,
      };
    }

    if (impactFilter == null) {
      return {
        ...impactsReport,
        data: impactsReport.data,
        metaData: impactsReport.metaData,
        realCount: Math.max(impactsReport.data.length - 1, 0),
      };
    }

    const filteredData: Array<ReportRow> = [];
    const filteredMetaData: Array<any> = [];

    for (let i = 0; i < impactsReport.data.length; i++) {
      const data = impactsReport.data[i];
      const actual = impactsReport.metaData[i].actual as boolean;
      const thisFilter =
        impactsReport.metaData[i].actual == null
          ? impactFilter
          : actual
          ? OverviewImpactFilter.actual
          : OverviewImpactFilter.potential;

      if (
        impactFilter === thisFilter // &&
        // impactsReport.totals.coverageShare[i] > 0
      ) {
        filteredData.push(data);
        filteredMetaData.push(impactsReport.metaData[i]);
      }
    }

    return {
      realCount: Math.max(impactsReport.data.length - 1, 0),
      totals: impactsReport.totals,
      columns: impactsReport.columns,
      data: filteredData,
      metaData: filteredMetaData,
    };
  }, [impactFilter, impactsReport]);

  const toggleImpactFilter = (value: OverviewImpactFilter) => {
    setImpactFilter(value === impactFilter ? undefined : value);
  };

  const [reopenCase] = useBusyAction(async () => {
    try {
      await openCase(caseId);
      await invalidation('Case', caseId);
      return true;
    } catch (e) {
      return false;
    }
  });

  const themeCoveragePercentage = useMemo(() => {
    if (themes == null) {
      return;
    }

    return {
      percentage: themes.totals?.coveragePercent ?? 0.0,
      count: themes.data.filter((x) => !ReportUtil.isNotSetData(x.cells))
        .length,
    };
  }, [themes]);

  const solutionsCoveragePercentage = useMemo(() => {
    if (solutions == null) {
      return;
    }

    return {
      percentage: solutions.totals?.coveragePercent ?? 0.0,
      count: solutions.data.filter((x) => !ReportUtil.isNotSetData(x.cells))
        .length,
    };
  }, [solutions]);

  const impactsCoveragePercentage = useMemo(() => {
    if (impactsReport == null) {
      return;
    }

    return {
      percentage: impactsReport.totals?.coveragePercent ?? 0.0,
      count: impactsReport.data.filter((x) => !ReportUtil.isNotSetData(x.cells))
        .length,
    };
  }, [impactsReport]);

  const evidenceStrengthChartData = useMemo(() => {
    var result: LeagueTableStat[] = [];

    if (isLoading) return [];

    for (var x = 5; x >= 1; x--) {
      var indexOf = evidenceStrengthCoverage!.metaData.findIndex(
        (y) => y.strength === x
      );

      result.push({
        id: x,
        name: `${x}/5`,
        percentage:
          indexOf !== -1
            ? evidenceStrengthCoverage!.metaData[indexOf].coverage
            : 0,
        colour: getStrengthColor(x),
        causeBoxes:
          indexOf !== -1
            ? Object.keys(
                evidenceStrengthCoverage!.metaData[indexOf].chainItems
              ).length
            : 0,
      });
    }

    return result;
  }, [isLoading, evidenceStrengthCoverage]);

  return {
    isFetching,
    caseId,
    caseDetail,
    totals,
    themes,
    solutions,
    filteredImpacts,
    outcomes,
    isLoading,
    deleteOutcome,
    canShowCompleteButton,
    canShowReOpenButton: canReopenCase,
    impactFilter,
    toggleImpactFilter,
    canChangeCaseDetails,
    canContribute,
    reopenCase,
    contributors,
    invitedGroups,
    themeCoveragePercentage,
    solutionsCoveragePercentage,
    impactsCoveragePercentage,
    solutionTermCoverage,
    evidenceStrengthCoverage,
    evidenceStrengthChartData,
  };
}
