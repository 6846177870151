import {
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Validator } from '@util/validators';
import { areEqualShallow } from '@util/helper-util';
import { isNullOrEmpty } from '@util/string-util';
import { ErrorType } from '@api/types/api-error';
import { DefaultTFuncReturn } from 'i18next';

export default function useField<T>(
  fieldValidators: Array<Validator<T> | Array<Validator<T>>> = [],
  defaultValue?: T
) {
  const validatorsRef = useRef(fieldValidators);
  validatorsRef.current = fieldValidators;

  const [value, set] = useState<T>(defaultValue as T);
  const [error, setErrorValue] = useState<string | ErrorType<T>>();

  const validate = useCallback(
    (writeError: boolean = true) => {
      const validate = (validator: Validator<any>, value: any) => {
        const errorMsg = validator.validate(value);
        if (!!errorMsg) {
          if (writeError) {
            if (!isNullOrEmpty(validator.propName)) {
              setErrorValue((e) => ({
                ...(e as any),
                [validator.propName as any]: errorMsg,
              }));
            } else {
              setErrorValue(errorMsg);
            }
          }
          return false;
        } else {
          if (validator.propName != null) {
            setErrorValue((e) => {
              if (
                e != null &&
                typeof e === 'object' &&
                validator.propName! in e
              ) {
                delete e[validator.propName!];
              }

              return e;
            });
          }

          return true;
        }
      };

      const validators = validatorsRef.current;
      if (validators == null) {
        if (writeError) setErrorValue(undefined);
        return true;
      }

      for (const validator of validators) {
        if (Array.isArray(validator)) {
          for (const innerValidator of validator) {
            if (!validate(innerValidator, value)) {
              return false;
            }
          }
        } else {
          if (!validate(validator, value)) {
            return false;
          }
        }
      }

      if (writeError) setErrorValue(undefined);
      return true;
    },

    [value]
  );

  const projectValidation = useCallback(
    (writeError: boolean = true) => {
      const validate = (validator: Validator<any>, value: any) => {
        const errorMsg = validator.validate(value);
        if (!!errorMsg) {
          return false;
        } else {
          return true;
        }
      };

      const validators = validatorsRef.current;
      if (validators == null) {
        if (writeError) setErrorValue(undefined);
        return true;
      }

      for (const validator of validators) {
        if (Array.isArray(validator)) {
          for (const innerValidator of validator) {
            if (!validate(innerValidator, value)) {
              return false;
            }
          }
        } else {
          if (!validate(validator, value)) {
            return false;
          }
        }
      }

      return true;
    },

    [value]
  );

  const setError = useCallback(
    (error?: string | Array<string> | ErrorType<any> | DefaultTFuncReturn) => {
      // Really, this should be set at the calling level, but for typing reasons
      // we allow it. As a safety fallback, we will pluck the first error.
      if (Array.isArray(error) && error.length >= 1) {
        setErrorValue(error.join(','));
        return;
      }

      setErrorValue(error as string | ErrorType<T> | undefined);
    },
    []
  );

  const isDirty = useMemo(() => {
    if (
      typeof value === 'string' &&
      isNullOrEmpty(value) &&
      defaultValue == null
    ) {
      return false;
    }

    if (value == null && defaultValue != null) {
      return true;
    } else if (value != null && defaultValue == null) {
      return true;
    }

    if (typeof value === 'object') {
      if (Array.isArray(value) && Array.isArray(defaultValue)) {
        if (value.length === 0 && defaultValue!.length > 0) {
          return true;
        } else if (defaultValue!.length === 0 && value.length > 0) {
          return true;
        }

        for (const valueElement of value) {
          if (defaultValue!.indexOf(valueElement) === -1) {
            return true;
          }
        }

        for (const valueElement of defaultValue) {
          if (value.indexOf(valueElement) === -1) {
            return true;
          }
        }
      }

      return !areEqualShallow(value, defaultValue as T);
    }

    return value !== defaultValue;
  }, [defaultValue, value]);

  const reset = useCallback(
    (newDefault?: T) => {
      setError(undefined);
      set(newDefault || (defaultValue as T));
    },
    [defaultValue, setError]
  );

  useEffect(() => {
    reset();
  }, [reset]);

  return useMemo(() => {
    return {
      value,
      defaultValue,
      set: set as SetStateAction<any>,
      error: error as string | undefined,
      errors: error as ErrorType<T> | undefined,
      setError,
      hasError: !!error,
      isDirty,
      validate,
      reset,
      projectValidation,
    };
    // Disabling because we don't want reset triggering a rebuild loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error, isDirty, setError, validate, value, projectValidation]);
}

export type Field<T> = ReturnType<typeof useField<T>>;
