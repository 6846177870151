import { ListItemRow } from '@components/containers/list-container';
import { Chip, IconButton, Typography, styled } from '@mui/material';
import {
  CaseSolutionItemChainItemResponse,
  CaseSolutionItemResponse,
} from '@api/types/case/case-solution/case-solution-item.response';
import { ElipsisButton } from '@components/buttons';
import { useMemo, useState } from 'react';
import Row from '@components/layout-util-components/row';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/pro-light-svg-icons';
import { currencyFormatter } from '@i18n/formatters';
import { useNavigate } from 'react-router-dom';
import { Gap } from '@components/layout-util-components/gap';
import AssociatedCauseBoxBadge from '@components/badges/associated-cause-box-badge';
import SolutionEffectivenessBadge from '@components/outcome-report/components/solution-effectiveness-badge';
import FileIndicator from '@components/outcome-report/components/file-indicator';
import { OutcomeSolutionOverviewResponse } from '@api/types/case/case-outcome/outcome-report-types/outcome-solution-overview.response';
import { PopoverMenuAction } from '@components/popover-menu/use-popover-menu';
import SolutionTypeBadge from '@components/outcome-report/components/solution-type-badge';
import SolutionValueBadge from '@components/outcome-report/components/solution-value-badge';
import { truncateString } from '@util/string-util';
import { SolutionTypeUtil } from '@util/solution-type-util';

interface Props {
  solution: CaseSolutionItemResponse | OutcomeSolutionOverviewResponse;
  onEdit?: () => void;
  onDelete?: () => void;
  onOpen?: () => void;
  noActions?: boolean;
  caseId: number;
  currency: string;
}

export default function SolutionsTableRow({
  solution,
  onEdit,
  onDelete,
  onOpen,
  currency,
  caseId,
  noActions = false,
}: Props) {
  const navigate = useNavigate();
  const { name, description, chainItems, cost, fileCount } = solution;
  const chainItemCount = chainItems.length;

  const firstChain = chainItems?.[0];
  const oneOrMoreChains = chainItemCount > 0 && firstChain != null;
  const multipleChains = chainItemCount > 1 && firstChain != null;
  const averageEffectiveness = useMemo(() => {
    if (solution.chainItems.length === 0) {
      return 0;
    }

    return (
      solution.chainItems
        .map((chainItem) => chainItem.efficacy ?? 0)
        .reduce((acc, cost) => acc + cost, 0) / solution.chainItems.length
    );
  }, [solution.chainItems]);

  const [expanded, setExpanded] = useState(false);

  const getChipValue = (k: keyof CaseSolutionItemChainItemResponse) => {
    if (firstChain == null) {
      return '-';
    }
    const originalValue = firstChain[k];
    let value = originalValue;
    if (value == null) {
      return '-';
    }

    if (typeof value === 'string') {
      value = value.trim().toLowerCase();
    }

    const otherValues = chainItems?.map((chainItem) => {
      let otherValue = chainItem[k];
      if (otherValue != null && typeof otherValue === 'string') {
        otherValue = otherValue.trim().toLowerCase();
      }
      return otherValue;
    });

    return otherValues?.every((v) => v === value) ? originalValue : '-';
  };

  const accumulatedCost = useMemo(() => {
    const additionalCosts = chainItems.map(
      (chainItem) => chainItem.additionalCost ?? 0
    );
    return additionalCosts.reduce((acc, cost) => acc + cost, 0);
  }, [chainItems]);

  const actions = useMemo(() => {
    if (noActions) {
      return [];
    }

    const actions: Array<PopoverMenuAction> = [];
    if (onOpen) {
      actions.push({
        label: 'View',
        onClick: () => onOpen(),
      });
    }

    if (onEdit) {
      actions.push({
        label: 'Edit',
        onClick: () => onEdit(),
      });
    }

    if (onDelete) {
      actions.push({
        label: 'Delete',
        onClick: () => onDelete(),
      });
    }

    return actions;
  }, [onDelete, onEdit, onOpen]);

  return (
    <>
      <ListItemRow onClick={onOpen}>
        <div className="texty">
          {multipleChains && (
            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                setExpanded(!expanded);
              }}
            >
              {expanded ? (
                <FontAwesomeIcon icon={faChevronUp} />
              ) : (
                <FontAwesomeIcon icon={faChevronDown} />
              )}
            </IconButton>
          )}
        </div>
        <div>
          <Typography variant="body1">{name}</Typography>
        </div>
        <div>
          {oneOrMoreChains ? (
            <AssociatedCauseBoxBadge variant="no-icon" count={chainItemCount} />
          ) : null}
        </div>
        <div>
          {oneOrMoreChains && (
            <SolutionTypeBadge
              type={SolutionTypeUtil.toShortLabel(
                getChipValue('solutionType')?.toString()
              )}
            />
          )}
        </div>
        <div>
          {oneOrMoreChains && (
            <SolutionEffectivenessBadge
              effectiveness={Number(averageEffectiveness.toFixed(1))}
            />
          )}
        </div>
        <div>
          {oneOrMoreChains && (
            <SolutionValueBadge
              value={getChipValue('solutionTermName')?.toString() ?? ''}
            />
          )}
        </div>
        <div>
          {!!cost && cost !== 0 ? (
            <>
              {currencyFormatter(cost, {
                decimalPlaces: 0,
                currency: currency || '',
              })}
            </>
          ) : null}
        </div>
        <div>
          {!!accumulatedCost && accumulatedCost !== 0 ? (
            <>
              +
              {currencyFormatter(accumulatedCost, {
                decimalPlaces: 0,
                currency: currency || '',
              })}
            </>
          ) : null}
        </div>
        <div>
          {!!cost &&
          cost !== 0 &&
          !!accumulatedCost &&
          accumulatedCost !== 0 ? (
            <>
              <strong>
                {currencyFormatter(accumulatedCost + cost, {
                  decimalPlaces: 0,
                  currency: currency || '',
                })}
              </strong>
            </>
          ) : null}
        </div>
        <ElipsisButton actions={actions} />
      </ListItemRow>
      {expanded && (
        <>
          {chainItems.map(
            ({
              chainItemId,
              additionalCost,
              chainItemName,
              efficacy,
              solutionTermName,
              solutionType,
            }) => (
              <ListItemRow className="child-row">
                <Gap size={8} />
                <Gap size={8} />
                <div className="associated">
                  <Typography>
                    {!!chainItemCount
                      ? truncateString(chainItemName, 20)
                      : null}
                  </Typography>
                </div>
                <div>
                  {solutionType && (
                    <SolutionTypeBadge
                      type={SolutionTypeUtil.toShortLabel(solutionType)}
                    />
                  )}
                </div>
                <div>
                  {efficacy != null && (
                    <SolutionEffectivenessBadge
                      effectiveness={efficacy === 0 ? '-' : efficacy}
                    />
                  )}
                </div>
                <div>
                  {solutionTermName && (
                    <SolutionValueBadge value={solutionTermName} />
                  )}
                </div>
                <div></div>
                <div>
                  {!!additionalCost && additionalCost !== 0 ? (
                    <>
                      {currencyFormatter(additionalCost, {
                        decimalPlaces: 0,
                        currency: currency || '',
                      })}
                    </>
                  ) : null}
                </div>
                <div></div>
                <ElipsisButton
                  actions={
                    noActions
                      ? []
                      : [
                          {
                            label: 'View visual analysis',
                            onClick: () =>
                              navigate(
                                `/rca/tabs/${caseId}/chart/?chainItem=${chainItemId}`
                              ),
                          },
                        ]
                  }
                />
              </ListItemRow>
            )
          )}
        </>
      )}
    </>
  );
}
