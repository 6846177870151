import {
  WAInstallRow,
  WAUpsertInstallRequest,
} from '@api/types/wct-admin/wct-admin-install-types';
import useField from '@hooks/use-field-hook';
import { onlyNumbers, required } from '@util/validators';
import useFieldsWatcher from '@hooks/use-fields-watcher';
import { useUpsertWAInstallMutation } from '@api/endpoints/wct-admin/wct-admin-install.api';
import useBusyAction from '@hooks/use-busy-action-hook';
import { isApiError } from '@api/types/api-error';
import { usePageAlertVariants } from '@components/alerts';
import { invalidation } from '@api/cache-util';
import { useUiPopup } from '@components/ui-popup/ui-popup-provider';
import WAInstallSecretModal from '@pages/wct-admin/installs/components/wa-install-secret-modal';

export default function useUpsertInstallForm(install?: WAInstallRow) {
  const isEdit = install != null;
  const { showPopup } = useUiPopup();
  const { showSuccessMessage, showErrorMessage } = usePageAlertVariants();

  const [upsertInstallMutation] = useUpsertWAInstallMutation();

  const companyLimit = useField<string>(
    [required(), onlyNumbers()],
    install?.companyLimit?.toString() ?? '1'
  );
  // TODO(Nick): Get value from model when it's added
  const installApiUrl = useField<string>([required()], install?.installAPIUrl);
  const installAppUrl = useField<string>([required()], install?.installAPPUrl);
  const installName = useField<string>([required()], install?.name);
  const manualPayments = useField<boolean>([], install?.manualPayments);
  const allowPublicSignups = useField<boolean>([], install?.allowPublicSignups);
  const po = useField<string>([required()], install?.billing?.po);
  const billingCompanyName = useField<string>(
    [required()],
    install?.billing?.companyName
  );
  const addressLineOne = useField<string>(
    [required()],
    install?.billing?.addressLineOne
  );
  const addressLineTwo = useField<string>([], install?.billing?.addressLineTwo);
  const town = useField<string>([], install?.billing?.town);
  const postcode = useField<string>([required()], install?.billing?.postcode);
  const contactName = useField<string>(
    [required()],
    install?.contacts[0]?.name
  );
  const contactEmail = useField<string>(
    [required()],
    install?.contacts[0]?.email
  );
  const contactTelephone = useField<string>(
    [required()],
    install?.contacts[0]?.telephone
  );
  const contactNotes = useField<string>([], install?.contacts[0]?.notes);

  const { validateAll, isValid } = useFieldsWatcher([
    installName,
    companyLimit,
    manualPayments,
    allowPublicSignups,
    installAppUrl,
    installApiUrl,
    po,
    billingCompanyName,
    addressLineOne,
    addressLineTwo,
    town,
    postcode,
    contactName,
    contactEmail,
    contactTelephone,
    contactNotes,
  ]);

  const [upsertInstall, isBusy] = useBusyAction(async () => {
    try {
      if (!validateAll()) {
        return false;
      }

      const result = await upsertInstallMutation({
        installId: install?.installId,
        name: installName.value,
        companyLimit: !isNaN(Number(companyLimit.value))
          ? Number(companyLimit.value)
          : 0,
        manualPayments: manualPayments.value ? true : false,
        allowPublicSignups: allowPublicSignups.value ? true : false,
        installAPIUrl: installApiUrl.value,
        installAPPUrl: installAppUrl.value,
        po: po.value,
        companyName: billingCompanyName.value,
        addressLineOne: addressLineOne.value,
        addressLineTwo: addressLineTwo.value,
        town: town.value,
        postcode: postcode.value,
        contacts: [
          {
            name: contactName.value,
            email: contactEmail.value,
            telephone: contactTelephone.value,
            notes: contactNotes.value,
          },
        ],
      }).unwrap();
      await invalidation('WAInstall');

      const secret = result.installSecret;
      if (secret != null && secret.length > 0) {
        await showPopup(WAInstallSecretModal, { secret });
      }

      showSuccessMessage('Install saved successfully');
      return true;
    } catch (error) {
      if (isApiError<WAUpsertInstallRequest>(error)) {
        const { message, errors } = error;
        showErrorMessage(errors?.installId ?? message);

        installName.setError(errors?.name);
        companyLimit.setError(errors?.companyLimit);
        manualPayments.setError(errors?.manualPayments);
        allowPublicSignups.setError(errors?.allowPublicSignups);
        installAppUrl.setError(errors?.installAPPUrl);
        installApiUrl.setError(errors?.installAPIUrl);
        po.setError(errors?.po);
        billingCompanyName.setError(errors?.companyName);
        addressLineOne.setError(errors?.addressLineOne);
        addressLineTwo.setError(errors?.addressLineTwo);
        town.setError(errors?.town);
        postcode.setError(errors?.postcode);
        // contactName.setError(errors?.contactName);
        // contactEmail.setError(errors?.contactEmail);
        // contactTelephone.setError(errors?.contactTelephone);
        // contactNotes.setError(errors?.contactNotes);
      }

      return false;
    }
  });

  const canSubmit = !isBusy && isValid;

  return {
    isEdit,
    installName,
    companyLimit,
    manualPayments,
    allowPublicSignups,
    installAppUrl,
    installApiUrl,
    po,
    billingCompanyName,
    addressLineOne,
    addressLineTwo,
    town,
    postcode,
    contactName,
    contactEmail,
    contactTelephone,
    contactNotes,
    upsertInstall,
    isBusy,
    canSubmit,
  };
}
