import { useFilter } from '@components/table';
import { SortDirection } from '@api/types/sort-direction';
import { useGetWACompanyOptionsQuery } from '@api/endpoints/wct-admin/wct-admin-company.api';
import {
  WADashboardFilters,
  WADashboardSortableFilters,
} from '@api/types/wct-admin/wa-dashboard-types';
import {
  useGetWADashboardAdminStatsQuery,
  useGetWADashboardCaseStatusesQuery,
  useGetWADashboardCaseTypesQuery,
  useGetWADashboardCompanySeatsQuery,
  useGetWADashboardHealthScoreQuery,
  useGetWADashboardImpactCategoriesQuery,
  useGetWADashboardRegionsQuery,
  useGetWADashboardSectorsQuery,
  useGetWADashboardSolutionEfficacyQuery,
  useGetWADashboardSolutionTermQuery,
  useGetWADashboardThemesQuery,
} from '@api/endpoints/wct-admin/wct-admin-dashboard.api';
import { useGetAllWAPackageOptionsQuery } from '@api/endpoints/wct-admin/wct-admin-package.api';

export default function useWADashboard() {
  const { data: companyOptions, isLoading: loadingCompanyOptions } =
    useGetWACompanyOptionsQuery({});

  const { data: packageOptions, isLoading: loadingPackageOptions } =
    useGetAllWAPackageOptionsQuery();

  // const sectorFilter = useFilter<number>();
  const regionFilter = useFilter<number>();
  const installFilter = useFilter<number>();
  const companyFilter = useFilter<number>();
  const caseTypeFilter = useFilter<number>();
  const dateFrom = useFilter<string>();
  const dateTo = useFilter<string>();

  const packageFilter = useFilter<string>();

  const sortDirection = useFilter<SortDirection>(SortDirection.desc);

  const filters: WADashboardFilters = {
    // sectorId: sectorFilter.value,
    regionId: regionFilter.value,
    installId: installFilter.value,
    companyId: companyFilter.value,
    caseTypeId: caseTypeFilter.value,
    dateFrom: dateFrom.value,
    dateTo: dateTo.value,
  };

  const sortableFilters: WADashboardSortableFilters = {
    ...filters,
    sortDirection: sortDirection.value,
  };

  const { data: adminStats, isLoading: loadingAdminStats } =
    useGetWADashboardAdminStatsQuery(filters);

  const { data: themes, isLoading: loadingThemes } =
    useGetWADashboardThemesQuery(filters);

  const { data: impactCategories, isLoading: loadingImpactCategories } =
    useGetWADashboardImpactCategoriesQuery(filters);

  const { data: solutionTerm, isLoading: loadingSolutionTerm } =
    useGetWADashboardSolutionTermQuery(filters);

  const { data: solutionEfficacy, isLoading: loadingSolutionEfficacy } =
    useGetWADashboardSolutionEfficacyQuery(filters);

  const { data: caseTypes, isLoading: loadingCaseTypes } =
    useGetWADashboardCaseTypesQuery(filters);

  const { data: caseStatuses, isLoading: loadingCaseStatuses } =
    useGetWADashboardCaseStatusesQuery(filters);

  const { data: companySeats, isLoading: loadingCompanySeats } =
    useGetWADashboardCompanySeatsQuery({
      package: packageFilter.value,
      sortDirection: SortDirection.desc,
    });

  const { data: regions, isLoading: loadingRegions } =
    useGetWADashboardRegionsQuery(sortableFilters);

  const { data: sectors, isLoading: loadingSectors } =
    useGetWADashboardSectorsQuery(filters);

  const { data: healthScores, isLoading: loadingHealthScores } =
    useGetWADashboardHealthScoreQuery({
      ...filters,
      sortDirection: SortDirection.asc,
    });

  // TODO(Nick): Backed is returning status 500 here.
  // const { data: healthScores, isLoading: loadingHealthScores } =
  //   useGetWADashboardHealthScoreQuery(filters);

  const isLoading =
    loadingCompanyOptions ||
    loadingPackageOptions ||
    loadingImpactCategories ||
    loadingThemes ||
    loadingSolutionTerm ||
    loadingSolutionEfficacy ||
    loadingCaseTypes ||
    loadingCaseStatuses ||
    loadingCompanySeats ||
    loadingRegions ||
    loadingSectors ||
    loadingAdminStats ||
    loadingHealthScores;

  const isPackageSelected = (id: string) => {
    if (packageFilter.value == null) {
      return false;
    }

    return id === packageFilter.value;
  };

  const toggleSelectedPackage = (id: string) => {
    if (isPackageSelected(id)) {
      packageFilter.set(undefined);
    } else {
      packageFilter.set(id);
    }
  };

  const toggleSelectedSortDirection = (direction: SortDirection) => {
    if (sortDirection.value !== direction) {
      sortDirection.set(direction);
    }
  };

  return {
    companyOptions,
    packageOptions,
    companySeats,
    regions,
    sectors,
    themes,
    impactCategories,
    solutionTerm,
    solutionEfficacy,
    caseTypes,
    caseStatuses,
    adminStats,
    regionFilter,
    installFilter,
    companyFilter,
    caseTypeFilter,
    dateFrom,
    dateTo,
    packageFilter,
    sortDirection,
    isLoading,
    healthScores,
    isPackageSelected,
    toggleSelectedPackage,
    toggleSelectedSortDirection,
  };
}
