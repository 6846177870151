import {
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
} from '@components/ui-popup/drawers';
import { Typography } from '@mui/material';
import ReportOption from '@pages/app/rca/tabs/lenses/components/report-option';
import { LensesPanelState } from '@pages/app/rca/tabs/lenses/lenses-panel-hook';
import Row from '@components/layout-util-components/row';
import Spacer from '@components/layout-util-components/spacer';
import { LinkButton, OutlinedButton } from '@components/buttons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/pro-light-svg-icons';
import RecentLenseOption from '@pages/app/rca/tabs/lenses/components/recent-lense-option';
import { Fragment } from 'react';
import { LoadingIndicator } from '@components/loading-indicator';
import { showReportById } from '@store/reports/reports-actions';
import { useAppDispatch } from '@store/store';
import { Gap } from '@components/layout-util-components/gap';
import { ITypeDetails } from '../lenses-panel';

interface Props {
  onClose: () => void;
  state: LensesPanelState;
  types: ITypeDetails[];
}

export default function OptionSelectView({ onClose, state, types }: Props) {
  const dispatch = useAppDispatch();

  const {
    selectedReportType,
    selectReportType,
    showAllRecentLenses,
    isLoading,
    reportTypeOptions,
    allReports,
    goToForm,
  } = state;

  return (
    <>
      <DrawerHeader onClose={onClose}>Lenses</DrawerHeader>
      <DrawerContent>
        <Typography variant="body2">
          Create, name and save advanced lenses to provide real time chart
          intelligence
        </Typography>
        <Gap size={20} />
        {isLoading ? (
          <LoadingIndicator show />
        ) : (
          reportTypeOptions!.map((option) => {
            const type = types.some((x) => x.type === option.reportType)
              ? types.filter((x) => x.type === option.reportType)[0]!
              : undefined;
            return (
              <Fragment key={option.reportTypeId}>
                <ReportOption
                  isSelected={
                    selectedReportType?.reportTypeId === option.reportTypeId
                  }
                  icon={type?.icon}
                  iconBackground={type ? type.iconBackground : '#CCC'}
                  title={option.name}
                  message={type ? type.message : ''}
                  onClick={() => {
                    selectReportType(option);
                    goToForm();
                  }}
                />
                <Gap size={8} />
              </Fragment>
            );
          })
        )}
        <Gap size={20} />
        <Row>
          <Typography>Recent Lenses</Typography>
          <Spacer />
          <LinkButton onClick={showAllRecentLenses}>View all</LinkButton>
          <FontAwesomeIcon icon={faChevronRight} />
        </Row>
        <Gap size={10} />
        {isLoading ? (
          <LoadingIndicator show />
        ) : (
          allReports!.model.slice(0, 2).map((report) => (
            <Fragment key={report.reportId}>
              <RecentLenseOption
                title={report.name}
                tag={`${report.reportSubTypeName} report`}
                createdBy={report.createdByCompanyUser}
                createdAt={report.created}
                onClick={() => {
                  onClose();
                  var reportType = reportTypeOptions?.find(
                    (x) => x.reportTypeId === report.reportTypeId
                  );
                  if (reportType) {
                    selectReportType(reportType);
                  }
                  dispatch(showReportById(report.reportId));
                }}
              />
              <Gap size={15} />
            </Fragment>
          ))
        )}
      </DrawerContent>
      <DrawerFooter>
        <OutlinedButton onClick={() => onClose()}>Cancel</OutlinedButton>
        {/* <PrimaryButton disabled={!canMoveNext} onClick={goToForm}>
          Next
        </PrimaryButton> */}
      </DrawerFooter>
    </>
  );
}
