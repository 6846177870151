import { Box, styled, Typography } from '@mui/material';
import Column from '@components/layout-util-components/column';
import Spacer from '@components/layout-util-components/spacer';
import { FlatButton } from '@components/buttons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faDownLeftAndUpRightToCenter,
  faPencil,
  faRefresh,
  faTimes,
  faTrash,
  faTrashAlt,
  faUpRightAndDownLeftFromCenter,
} from '@fortawesome/pro-light-svg-icons';
import {
  RunReportModel,
  setOpenLensesSlideOut,
  toggleShrinkView,
} from '@store/reports/reports-slice';
import { useAppDispatch, useAppSelector } from '@store/store';
import { shouldShrinkReportView } from '@store/reports/reports-selectors';
import { Gap } from '@components/layout-util-components/gap';
import WCTTextField from '@components/input/text-field';
import { ReportPanelState } from '../report-panel-hook';
import FetchingIndicator from '@components/loading-indicator/fetching-indicator';
import { useUiPopup } from '@components/ui-popup/ui-popup-provider';
import useBusyAction from '@hooks/use-busy-action-hook';
import { useDeleteReportMutation } from '@api/endpoints/case-report.api';
import { usePageAlertVariants } from '@components/alerts';
import { ApiError } from '@api/types/api-error';
import { DeleteReportRequest } from '@api/types/case/case-report/delete-report.request';
import { selectCurrentRcaCaseId } from '@store/rca-editor/selectors';
import { truncateString } from '@util/string-util';

interface Props {
  runModel: RunReportModel;
  onClose: () => void;
  state: ReportPanelState;
}

const Container = styled('div')({
  height: 120,
  display: 'flex',
  padding: 20,
});

export default function ReportTitleBar({ runModel, onClose, state }: Props) {
  const { showConfirmationModal } = useUiPopup();
  const { showSuccessMessage, showErrorMessage } = usePageAlertVariants();
  const dispatch = useAppDispatch();

  const [deleteReport] = useDeleteReportMutation();
  const isShrunk = useAppSelector(shouldShrinkReportView);
  const caseId = useAppSelector(selectCurrentRcaCaseId);
  const canEdit = runModel.reportTypeId !== -1;
  const { name, isReRunning, reRun } = state;

  const [onDelete] = useBusyAction((id: number) => {
    return deleteReport({
      caseId: caseId!,
      reportId: id,
    })
      .unwrap()
      .then(async () => {
        showSuccessMessage(
          `You have successfully deleted the report '${name.value}'`
        );
        onClose();
        dispatch(setOpenLensesSlideOut(true));
        return true;
      })
      .catch(({ message, errors }: ApiError<DeleteReportRequest>) => {
        showErrorMessage(message);
        return false;
      });
  });

  const beginDelete = (id: number) => {
    return showConfirmationModal({
      title: 'Delete Report',
      message: `Are you sure you would like to delete this report?\n\n"${name.value}"`,
      yesButtonAsyncAction: () => onDelete(id),
    });
  };

  return (
    <Container>
      <Box sx={{ width: '50%' }}>
        <Column>
          <Typography variant="body1">{runModel.reportTypeName}</Typography>
          {isShrunk ? (
            <Typography
              sx={{ marginTop: '10px' }}
              variant="h2"
              title={name.value}
            >
              {truncateString(name.value, 12)}
            </Typography>
          ) : (
            <WCTTextField
              name="reportName"
              value={name.value}
              error={name.error}
              onChange={name.set}
              required
            />
          )}
        </Column>
      </Box>
      <Spacer />
      <Gap size={15} />
      <FlatButton onClick={() => dispatch(toggleShrinkView())}>
        <FontAwesomeIcon
          icon={
            isShrunk
              ? faUpRightAndDownLeftFromCenter
              : faDownLeftAndUpRightToCenter
          }
        />
      </FlatButton>
      <Gap size={24} />
      {canEdit ? (
        <>
          <FlatButton onClick={() => dispatch(setOpenLensesSlideOut(true))}>
            <FontAwesomeIcon icon={faPencil} />
          </FlatButton>
        </>
      ) : null}
      {runModel.id > 0 && (
        <>
          <Gap size={12} />
          <FlatButton onClick={() => beginDelete(runModel.id)}>
            <FontAwesomeIcon icon={faTrashAlt} />
          </FlatButton>
        </>
      )}
      <Gap size={12} />
      <FlatButton disabled={isReRunning} onClick={() => reRun()}>
        {isReRunning ? (
          <FetchingIndicator show />
        ) : (
          <FontAwesomeIcon icon={faRefresh} />
        )}
      </FlatButton>
      <Gap size={24} />
      <FlatButton shape="circle" onClick={onClose}>
        <FontAwesomeIcon icon={faTimes} />
      </FlatButton>
    </Container>
  );
}
