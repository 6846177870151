import { api } from '@api/api';
import { Option } from '@api/types/option';

const wctAdminSectorApi = api.injectEndpoints({
  endpoints: (build) => ({
    getAllWASectorOptions: build.query<Option[], void>({
      query: () => ({
        url: 'sector/Options',
        method: 'GET',
      }),
    }),
  }),
});

export const { useGetAllWASectorOptionsQuery } = wctAdminSectorApi;
