import { PropsWithChildren, ReactNode } from 'react';
import { css, styled } from '@mui/material';

interface DivLayoutProps {
  actionsRow: ReactNode;
  marginTop: boolean;
}

interface FormLayoutProps extends DivLayoutProps {
  onSubmit: () => void;
}

const StepContainer = css({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'stretch',
  // height: 'calc(100vh - 80px)',
  width: '100%',
  background: '#ECEDF0',
  maxWidth: '100% !important',

  '& .content': {
    flex: '1 1 auto',
    overflow: 'auto',
    padding: '60px 80px 100px 164px',
    '@media (min-width:1450px)': {
      padding: '60px 164px 100px',
    },
  },

  '& .actions': {
    position: 'fixed',
    bottom: 0,
    left: 0,
    flex: '0 0 auto',
    display: 'flex',
    padding: '20px 80px 20px 164px',
    '@media (min-width:1450px)': {
      padding: '20px 164px',
    },
    alignItems: 'center',
    height: 82,
    backgroundColor: 'white',
    width: '100%',
    '@media (min-width:1290px)': {
      width: 'calc(80%)',
    },
    zIndex: 5,
  },
});

const DivLayoutContainer = styled('div', { target: 'step-form-container' })(
  StepContainer
);

const FormLayoutContainer = styled('form', { target: 'step-form-container' })(
  StepContainer
);

export function StepFormLayout({
  children,
  actionsRow,
  onSubmit,
  marginTop,
}: PropsWithChildren<FormLayoutProps>) {
  return (
    <FormLayoutContainer
      noValidate
      onSubmit={(e) => {
        e.preventDefault();
        onSubmit();
      }}
    >
      <div
        className="content"
        style={{ marginTop: marginTop ? 80 : 0, marginBottom: 82 }}
      >
        {children}
      </div>
      <div className="actions">{actionsRow}</div>
    </FormLayoutContainer>
  );
}

export function StepLayout({
  children,
  actionsRow,
  marginTop,
}: PropsWithChildren<DivLayoutProps>) {
  return (
    <DivLayoutContainer>
      <div className="content" style={{ marginTop: marginTop ? 80 : 0 }}>
        {children}
      </div>
      <div className="actions">{actionsRow}</div>
    </DivLayoutContainer>
  );
}
