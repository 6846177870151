import requireAuth from '@components/hoc/with-required-auth-hoc';
import ContentContainer from '@components/containers/content-container';
import Row from '@components/layout-util-components/row';
import { LoadingIndicator } from '@components/loading-indicator';
import { SortByFilter, WCTTable, WCTTableFilters } from '@components/table';
import { Gap } from '@components/layout-util-components/gap';
import OnboardingEntryContainer from '@components/containers/onboarding-entry-container';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBuildings } from '@fortawesome/pro-light-svg-icons';
import { useUiPopup } from '@components/ui-popup/ui-popup-provider';
import PageCount from '@components/typography/page-count';
import useWAInstallsIndexPage from '@pages/wct-admin/installs/wa-installs-index-hook';
import WAInstallTableRow from '@pages/wct-admin/installs/components/wa-install-table-row';
import Spacer from '@components/layout-util-components/spacer';
import { ButtonSkeleton } from '@components/skeletons';
import { PrimaryButton } from '@components/buttons';
import { WAInstallRow } from '@api/types/wct-admin/wct-admin-install-types';
import WAUpsertInstallForm from '@pages/wct-admin/installs/components/forms/wa-upsert-install-form';
import { Box } from '@mui/material';

function WaInstallsIndexPage() {
  const { showPopup } = useUiPopup();
  const {
    isLoading,
    isFetching,
    search,
    sort,
    pagination,
    sortByOptions,
    installs,
  } = useWAInstallsIndexPage();

  const upsertInstall = (install?: WAInstallRow) => {
    showPopup(WAUpsertInstallForm, {
      install,
    });
  };

  return (
    <ContentContainer maxWidth={1500}>
      <Row>
        <PageCount
          title="Installs"
          isLoading={isLoading}
          count={installs?.totalCount}
        />
        <Spacer />
        {isLoading ? (
          <Box>
            <ButtonSkeleton />
          </Box>
        ) : installs!.totalCount > 0 ? (
          <PrimaryButton onClick={() => upsertInstall()}>
            Create Install
          </PrimaryButton>
        ) : null}
      </Row>
      <Gap size={32} />
      {isLoading ? (
        <LoadingIndicator show />
      ) : installs!.totalCount === 0 ? (
        <OnboardingEntryContainer
          icon={<FontAwesomeIcon icon={faBuildings} size="3x" />}
          title="No Installs Found"
          message="There have been no installations yet"
          createMessage="Create Install"
          onCreateClicked={() => upsertInstall()}
        />
      ) : (
        <>
          <WCTTable
            count={installs!.totalCount}
            pagination={pagination}
            isLoading={isLoading}
            headers={[
              { title: '' },
              { title: 'Name', sortProperty: 'name', ...sort },
              {
                title: 'Key',
              },
              {
                title: 'No. of Companies',
                sortProperty: 'companyCount',
                ...sort,
              },
              {
                title: 'Queue Length',
              },
              { title: 'App URL' },
              { title: 'API URL' },
              { title: 'Created', sortProperty: 'created', ...sort },
              { title: 'Initialised' },
              { title: <></> },
            ]}
            filters={
              <WCTTableFilters
                isLoading={isLoading}
                isFetching={isFetching}
                search={search.value}
                onSearch={search.set}
                rightOfSearchContent={
                  <SortByFilter
                    value={sort.value}
                    onChange={sort.set}
                    options={sortByOptions}
                  />
                }
              />
            }
          >
            {installs!.model.map((install) => (
              <WAInstallTableRow
                key={install.installId}
                install={install}
                onEdit={() => upsertInstall(install)}
              />
            ))}
          </WCTTable>
        </>
      )}
    </ContentContainer>
  );
}

export default requireAuth(WaInstallsIndexPage);
