import { api } from '@api/api';
import { Option } from '@api/types/option';

const wctAdminBuesinessAreaApi = api.injectEndpoints({
  endpoints: (build) => ({
    getAllWABusinessAreaOptions: build.query<Option[], void>({
      query: () => ({
        url: 'businessArea/Options',
        method: 'GET',
      }),
    }),
  }),
});

export const { useGetAllWABusinessAreaOptionsQuery } = wctAdminBuesinessAreaApi;
