import { api } from '@api/api';
import { Option } from '@api/types/option';

const wctAdminCountryApi = api.injectEndpoints({
  endpoints: (build) => ({
    getAllWACountryOptions: build.query<Option[], void>({
      query: () => ({
        url: 'country/Names',
        method: 'GET',
      }),
    }),
  }),
});

export const { useGetAllWACountryOptionsQuery } = wctAdminCountryApi;
