import { styled } from '@mui/material';
import { useAppDispatch, useAppSelector } from '@store/store';
import {
  selectIsAnyPanelOpen,
  selectNodePanelEditorSelectedTab,
} from '@store/rca-editor/selectors';
import { ReactNode, useEffect, useState } from 'react';
import {
  NodePanelEditorTab,
  resetFocus,
  selectNodePanelEditorTab,
} from '@store/rca-editor/rca-editor-slice';
import OverviewPanel from '@pages/app/rca/tabs/components/editor-panels/overview-panel';
import EvidencePanel from '@pages/app/rca/tabs/components/editor-panels/evidence-panel';
import TasksPanel from '@pages/app/rca/tabs/components/editor-panels/tasks-panel';
import NotesPanel from '@pages/app/rca/tabs/components/editor-panels/notes-panel';
import SolutionsPanel from '@pages/app/rca/tabs/components/editor-panels/solutions-panel';
import ActivityPanel from '@pages/app/rca/tabs/components/events/activity-panel';
import { CircleButton } from '@components/buttons';
import { ReactComponent as BanIcon } from '@assets/svgs/ban.svg';
import { ReactComponent as CloseChevron } from '@assets/svgs/close.svg';
import { ReactComponent as FocalPointIcon } from '@assets/svgs/focal-point.svg';
import { ReactComponent as CollapseChainIcon } from '@assets/svgs/collapse-chain.svg';
import { ReactComponent as TwoArrowsIcon } from '@assets/svgs/two-arrows.svg';
import { ReactComponent as CircleTickIcon } from '@assets/svgs/circle-tick.svg';
import { ReactComponent as LinkIcon } from '@assets/svgs/link.svg';
import { ReactComponent as TrashIcon } from '@assets/svgs/trash.svg';
import Row from '@components/layout-util-components/row';
import PanelEditorSideAction, {
  PanelEditorSideActionProps,
  PanelEditorSideActionType,
} from '@pages/app/rca/tabs/components/panel-editor-side-action';
import PanelEditorSideSubAction from '@pages/app/rca/tabs/components/panel-editor-side-sub-action';
import { NodeEndStateType } from '@store/rca-editor/types';
import ImpactsPanel from '@pages/app/rca/tabs/components/editor-panels/impacts-panel';
import ConnectionsPanel from './connections-panel';
import useFocusedNodePanelHook from '@pages/app/rca/tabs/components/editor-panels/focused-node-panel-hook';
import { ActivityPanelView } from '@pages/app/rca/tabs/components/events/activity-panel-hook';
import {
  selectIsReportsOpen,
  shouldShrinkReportView,
} from '@store/reports/reports-selectors';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronsRight } from '@fortawesome/pro-light-svg-icons';
import { setCurrentFormDirty } from '@store/ui/ui-slice';

interface SidePanelProps {
  type: NodePanelEditorTab;
  label: ReactNode;
  count?: number;
}

const Container = styled('div')<{
  isBuildMode: boolean;
  isPanelOpen: boolean;
  isReportPanelOpen: boolean;
}>(({ isBuildMode, isPanelOpen, isReportPanelOpen }) => ({
  display: 'flex',
  position: 'fixed',
  right: isPanelOpen ? (isReportPanelOpen ? 430 : 480) : 20,
  top: 100,
  bottom: 20,
  width: isBuildMode ? 550 : 0,
  backgroundColor: 'white',
  borderRadius: 10,
  boxShadow: '0px 4px 24px 0px #00000033',
}));

const SidePanel = styled('div')(({ theme: { palette } }) => ({
  padding: 10,
  background: palette.blue.dark,
  borderRadius: '0 10px 10px 0',
  flex: '0 0 136px',
  display: 'inline-flex',
  flexDirection: 'column',
  alignItems: 'stretch',
  gap: 10,
  height: '100%',
  overflowY: 'auto',
  '.back-button': {
    background: 'none',
    border: '1px solid #fff',
    transition: 'all 0.3s ease-in-out',
    'svg path': {
      fill: '#fff',
      transition: 'all 0.3s ease-in-out',
    },
    '&:hover': {
      background: '#fff',
      'svg path': {
        fill: palette.blue.dark,
      },
    },
  },

  '> .side-panel-button': {
    flex: '0 0 auto',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    border: 'none',
    height: 42,
    padding: '5px 5px 5px 12px',
    borderRadius: 96,
    backgroundColor: 'rgba(255,255,255,0.175)',
    color: '#ffffff',
    textJustify: 'left',
    transition: 'all 0.3s ease-in-out',
    '&:hover': {
      backgroundColor: 'rgba(255,255,255,0.25)',
      cursor: 'pointer',
    },

    '&>.count': {
      display: 'inline-flex',
      justifyContent: 'center',
      alignItems: 'center',
      marginLeft: 10,
      width: 32,
      height: 32,
      borderRadius: '50%',
      backgroundColor: palette.common.white,
      color: palette.primary.main,
      fontSize: '12px',
      fontWeight: '600',
    },
    '&.selected>.count': {
      backgroundColor: palette.common.grey20,
    },
    '&.selected': {
      cursor: 'default',
      color: '#001927',
      backgroundColor: palette.common.white,
    },
    '&.selected:hover': {
      background: palette.common.white,
      color: '#001927',
    },
  },
}));

const Content = styled('div')(({ theme: { palette } }) => ({
  flex: '1 1 auto',
  height: '100%',
  overflowY: 'auto',
  padding: 25,

  '& .count': {
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: 10,
    width: 32,
    height: 32,
    borderRadius: '50%',
    backgroundColor: palette.common.grey35,
  },
}));

const ActionsContainer = styled('div')(({ theme: { palette } }) => ({
  width: 91,
  minHeight: 20,
  borderRadius: 5,
  transform: 'translateX(-100%)',
  position: 'absolute',
  left: -11,
  background: palette.blue.dark,
  padding: 8,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'stretch',
  gap: 8,
  backdropFilter: 'blur(6px)',

  '.title': {
    margin: 0,
    padding: 0,
    fontFamily: 'Usual',
    fontSize: '10px',
    fontWeight: '500',
    lineHeight: '11px',
    letterSpacing: '0.5px',
    textAlign: 'left',
    color: '#fff',
  },
}));

const SidePanelButton = ({ label, type, count }: SidePanelProps) => {
  const dispatch = useAppDispatch();
  const selectedTab = useAppSelector(selectNodePanelEditorSelectedTab);

  return (
    <button
      type="button"
      className={`side-panel-button ${type === selectedTab ? 'selected' : ''}`}
      onClick={() => dispatch(selectNodePanelEditorTab(type))}
    >
      <span>{label}</span>
      {count ? <span className="count">{count}</span> : null}
    </button>
  );
};

const contentMap = {
  [NodePanelEditorTab.overview]: <OverviewPanel />,
  [NodePanelEditorTab.evidence]: <EvidencePanel />,
  [NodePanelEditorTab.tasks]: <TasksPanel />,
  [NodePanelEditorTab.impacts]: <ImpactsPanel />,
  [NodePanelEditorTab.notes]: <NotesPanel />,
  [NodePanelEditorTab.solutions]: <SolutionsPanel />,
  [NodePanelEditorTab.connections]: <ConnectionsPanel />,
  [NodePanelEditorTab.activity]: (
    <ActivityPanel view={ActivityPanelView.chainItemLevel} />
  ),
};

export default function FocusedNodePanelEditor() {
  const dispatch = useAppDispatch();
  const isAnyPanelOpen = useAppSelector(selectIsAnyPanelOpen);
  const isReportPanelOpen = useAppSelector(selectIsReportsOpen);
  const isReportViewShrunk = useAppSelector(shouldShrinkReportView);

  const [openAction, setOpenAction] = useState<PanelEditorSideActionType>();

  const {
    focusedNode,
    setEndState,
    isBuildMode,
    isPresenterMode,
    canMakeEndState,
    canCollapseNode,
    canCreateAsFocalPoint,
    canConnectToCauseBox,
    canEditDisprovalState,
    disproved,
    canDelete,
    totals,
    isHighlightMode,
    selectedTab,
    systemText,
    createAsFocusPoint,
    deleteNode,
    disprove,
    toggleHighlightMode,
    toggleCollapsedState,
    setTab,
    shouldHighlightCollapsed,
    canContribute,
  } = useFocusedNodePanelHook();

  useEffect(() => {
    // When a user closes the panel whilst having a dirty form, we don't
    // want to keep triggering the dirt check message, so reset it when
    // focusedNode is reset (i.e. when panel closes)
    if (focusedNode == null) {
      dispatch(setCurrentFormDirty(false));
    }
  }, [dispatch, focusedNode]);

  if (focusedNode == null || (isReportPanelOpen && !isReportViewShrunk)) {
    return <></>;
  }

  const makeSideAction = (
    type: PanelEditorSideActionType,
    props: Pick<
      PanelEditorSideActionProps,
      'icon' | 'label' | 'actions' | 'disabled'
    >,
    highlight?: boolean
  ) => {
    const isOpen = openAction === type;
    return (
      <PanelEditorSideAction
        {...props}
        isOpen={highlight ?? isOpen}
        onClick={() => {
          switch (type) {
            case PanelEditorSideActionType.addEndState:
              if (isOpen) {
                setOpenAction(undefined);
              } else {
                setOpenAction(type);
              }
              break;
            case PanelEditorSideActionType.highlight:
              toggleHighlightMode();
              break;
            case PanelEditorSideActionType.collapse:
              toggleCollapsedState();
              break;
            case PanelEditorSideActionType.createAsFocalPoint:
              createAsFocusPoint();
              break;
            case PanelEditorSideActionType.connect:
              setTab(NodePanelEditorTab.connections);
              break;
            case PanelEditorSideActionType.disprove:
              disprove();
              break;
            case PanelEditorSideActionType.deleteItem:
              deleteNode();
              break;
          }
        }}
      />
    );
  };

  return (
    <Container
      isBuildMode={isBuildMode}
      isPanelOpen={isAnyPanelOpen}
      isReportPanelOpen={isReportPanelOpen}
    >
      {canContribute && (
        <ActionsContainer>
          <h4 className="title">{`${
            isPresenterMode ? 'PRESENTER' : ''
          } TOOLBAR`}</h4>
          {isBuildMode
            ? makeSideAction(PanelEditorSideActionType.addEndState, {
                icon: <BanIcon />,
                label: 'Add End State',
                disabled: !canMakeEndState,
                actions: [
                  <PanelEditorSideSubAction
                    key="complete"
                    icon={<CircleTickIcon />}
                    label="Path Analysis Complete"
                    onClick={() => setEndState(NodeEndStateType.complete)}
                  />,
                  <PanelEditorSideSubAction
                    key="furtherAnalysis"
                    icon={<FontAwesomeIcon icon={faChevronsRight} />}
                    label="Further Analysis Possible"
                    onClick={() =>
                      setEndState(NodeEndStateType.furtherAnalysis)
                    }
                  />,
                ],
              })
            : null}

          {isPresenterMode
            ? makeSideAction(
                PanelEditorSideActionType.highlight,
                {
                  icon: <TwoArrowsIcon />,
                  label: 'Highlight Chain',
                  actions: [],
                },
                isHighlightMode
              )
            : null}

          {isBuildMode ? (
            <>
              {makeSideAction(
                PanelEditorSideActionType.collapse,
                {
                  icon: <CollapseChainIcon />,
                  label: 'Collapse Chain',
                  disabled: !canCollapseNode,
                  actions: [],
                },
                shouldHighlightCollapsed
              )}
              {makeSideAction(PanelEditorSideActionType.createAsFocalPoint, {
                icon: <FocalPointIcon />,
                label: `Create as ${systemText['RCA.Overview.FocalPoint']}`,
                disabled: !canCreateAsFocalPoint,
                actions: [],
              })}
              {makeSideAction(PanelEditorSideActionType.connect, {
                icon: <LinkIcon />,
                label: 'Link To Cause Box',
                disabled: !canConnectToCauseBox,
                actions: [],
              })}
              {makeSideAction(
                PanelEditorSideActionType.disprove,
                {
                  icon: <FocalPointIcon />,
                  label: 'Mark As Disproved',
                  disabled: !canEditDisprovalState,
                  actions: [],
                },
                disproved
              )}
              {makeSideAction(PanelEditorSideActionType.deleteItem, {
                icon: <TrashIcon />,
                label: 'Delete Cause Box',
                disabled: !canDelete,
                actions: [],
              })}
            </>
          ) : null}
        </ActionsContainer>
      )}
      {isBuildMode ? (
        <>
          <Content>{contentMap[selectedTab]}</Content>
          <SidePanel>
            <Row contentAtEnd>
              <CircleButton
                className="back-button"
                onClick={() => dispatch(resetFocus())}
              >
                <CloseChevron />
              </CircleButton>
            </Row>
            <SidePanelButton
              label="Overview"
              type={NodePanelEditorTab.overview}
            />
            <SidePanelButton
              label="Evidence"
              type={NodePanelEditorTab.evidence}
              count={totals?.evidence}
            />
            <SidePanelButton
              label="Tasks"
              type={NodePanelEditorTab.tasks}
              count={totals?.tasks}
            />
            <SidePanelButton
              label="Impacts"
              type={NodePanelEditorTab.impacts}
              count={totals?.impacts}
            />
            <SidePanelButton
              label="Notes"
              type={NodePanelEditorTab.notes}
              count={totals?.notes}
            />
            <SidePanelButton
              label="Solutions"
              type={NodePanelEditorTab.solutions}
              count={totals?.solutions}
            />
            <SidePanelButton
              label="Links"
              type={NodePanelEditorTab.connections}
              count={totals?.connections}
            />
            <SidePanelButton
              label="Activity"
              type={NodePanelEditorTab.activity}
            />
          </SidePanel>
        </>
      ) : null}
    </Container>
  );
}
