import requireAuth from '@components/hoc/with-required-auth-hoc';
import withEditorScaffold from '@pages/app/rca/tabs/components/rca-editor-scaffold';
import { Box, Link, styled, Typography } from '@mui/material';
import { OutlinedButton } from '@components/buttons';
import { useNavigate } from 'react-router-dom';
import useRcaEditorOverview from '@pages/app/rca/tabs/rca-editor-overview-hook';
import { ButtonSkeleton, InlineBoxSkeleton } from '@components/skeletons';
import Row from '@components/layout-util-components/row';
import Spacer from '@components/layout-util-components/spacer';
import LabelValueDisplay from '@components/data/label-value-display';
import LabelValueSkeleton from '@components/skeletons/label-value-skeleton';
import InitialsContainerWithData from '@components/containers/initials-container-with-data';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faArrowRight,
  faBullseyePointer,
  faChartPieSimple,
  faCheckCircle,
  faCircleCheck,
  faFile,
  faUser,
  faUserGroup,
  faUsers,
} from '@fortawesome/pro-light-svg-icons';
import ContentContainer from '@components/containers/content-container';
import { OverviewContainer } from '@pages/app/rca/tabs/components/overview/overview-container';
import { useUiPopup } from '@components/ui-popup/ui-popup-provider';
import OutcomesData from '@pages/app/rca/tabs/components/overview/outcomes-data';
import { useAppDispatch } from '@store/store';
import { RunReportResponse } from '@api/types/case/case-report/run-report.response';
import {
  RunReportModel,
  setLoadedReportData,
} from '@store/reports/reports-slice';
import CompleteRcaModal from '@pages/app/rca/tabs/components/complete-rca/complete-rca-modal';
import ActivityPanelDrawer, {
  ActivityPanelDrawerProps,
} from '@pages/app/rca/tabs/components/events/activity-panel-drawer';
import { ActivityPanelView } from '@pages/app/rca/tabs/components/events/activity-panel-hook';
import { Gap } from '@components/layout-util-components/gap';
import OverviewGetStartedContainer from '@pages/app/rca/tabs/components/overview/overview-get-started-container';
import FetchingIndicator from '@components/loading-indicator/fetching-indicator';
import {
  DashboardLeagueTable,
  DashboardStatBox,
  DashboardStatContainer,
  LargeDashboardStatBox,
  LeagueTableStat,
} from '@components/dashboard/dashboard-stat-container';
import useSystemText from '@hooks/use-system-text';
import { SelectedCaseEditTab } from '@pages/app/rca/edit/edit-case-page';
import GroupedAvatars from '@components/containers/grouped-avatars';
import RcaSeverityPill from '@components/rca-components/rca-severity-pill';
import RcaStatusText from '@components/rca-components/rca-status-text';
import { ReactComponent as CauseBoxIcon } from '@assets/svgs/cause-box-orange.svg';
import EvidenceSectionOverview from '@components/outcome-report/sections/evidence-section-overview';
import SolutionsSectionOverview from '@components/outcome-report/sections/solutions-section-overview';
import GroupedBadges from '@components/containers/grouped-badges';
import InsightsSection from '@components/outcome-report/sections/insights-section';
import EmptySection from './components/overview/empty-section';
import { formatNumberToDecimals, numberFromString } from '@util/string-util';
import { getStrengthColor } from '@util/colour-identifiers';
import { useMemo } from 'react';
import { DashboardStatTheme } from '@components/dashboard/dashboard-stat-themes';
import { DashboardStatSolutions } from '@components/dashboard/dashboard-stat-solutions';

const Container = styled(ContentContainer)(({ theme: { palette } }) => ({
  '.headerBar': {
    alignItems: 'flex-start',
    h1: {
      fontSize: '22px',
      lineHeight: '30px',
      marginTop: '-3px',
      wordBreak: 'break-word',
      marginRight: 30,
    },
    '.initials-container-with-data .circle-container': {
      color: '#fff',
      background: '#3CA680',
      opacity: 1,
    },
    'dl, button': {
      flexShrink: 0,
    },
    'dl dt': {
      fontWeight: '400',
      fontSize: 13,
    },
    'dl dd': {
      fontSize: 14,
      '.initials-container-with-data .name-container .subtitle, .MuiChip-filled >span>.MuiBox-root p':
        {
          fontSize: 14,
        },
    },
    button: {
      height: 47,
      svg: {
        marginLeft: '5px',
      },
    },
  },
  '.testRow .test': {
    maxWidth: '75%',
    width: '75%',
    '> * ': {
      width: '31%',
      maxWidth: '31%',
      flex: '0 0 31%',
    },
  },
  '.quick-links': {
    display: 'flex',
    flex: '1 1 auto',
    flexDirection: 'column',
    alignItems: 'flex-start',
    background: 'url(/svgs/quick_links-bg.svg) no-repeat top right #00223A',
    padding: '20px 20px 14px 20px',
    borderRadius: 4,
    alignSelf: 'stretch',
    maxWidth: '25%',
    width: '25%',

    h3: {
      margin: 0,
      padding: 0,
      fontSize: '12px',
      fontWeight: '600',
      textTransform: 'uppercase',
      lineHeight: '13px',
      letterSpacing: '0px',
      textAlign: 'left',
      color: '#fff',
    },
    '.links': {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      width: '100%',
    },
    '.link': {
      fontSize: '14px',
      marginTop: 20,
      fontWeight: '400',
      textDecoration: 'none',
      lineHeight: '16px',
      letterSpacing: '0.15000000596046448px',
      textAlign: 'left',
      color: '#fff',
      flex: '0 0 50%',
      transition: 'transform ease-in-out 300ms',
      '@media (max-width:1300px)': {
        flex: '0 0 100%',
        marginTop: 9,
      },
      svg: {
        color: '#FF6C00',
        transition: 'transform ease-in-out 300ms',
        marginLeft: '5px',
      },
      '&:hover': {
        textDecoration: 'underline',
        svg: {
          marginLeft: '8px',
        },
      },
    },
    '.completed': {
      padding: '5px',
      borderRadius: '4px',
      border: '1px solid #fff',
      width: '50%',
      textAlign: 'center',
      marginLeft: 'auto',
      marginTop: 'auto',
      fontSize: '15px',
      color: '#fff',
      textDecoration: 'none',
      '&:hover': {
        background: palette.success.dark,
        borderColor: palette.success.dark,
        color: '#fff',
        textDecoration: 'none',
      },
      '@media (max-width:1300px)': {
        width: '100%',
        marginTop: 8,
        fontSize: '14px',
      },
    },
  },
}));

const ReportSectionContainer = styled(Box)({
  '.intro-wrapper': {
    alignItems: 'center !important',
  },
  '&.dark': {
    borderRadius: '8px',
    padding: '20px',
    background:
      'url(/svgs/solutions-bottom-right.svg) right bottom no-repeat rgb(0, 34, 58)',
    '&.lg-background': {
      background:
        'url(/svgs/solutions-bottom-right.svg) right bottom no-repeat rgb(0, 34, 58)',
    },
    h2: {
      color: '#FFF',
    },
    '.section-title': {
      color: '#FFF',
      fontSize: '16px',
      textAlign: 'left',
      lineHeight: '1',
      marginBottom: '10px',
      fontWeight: 'normal',
    },
    '&.no-title .section-title': {
      display: 'none',
    },
    '.overview-title': {
      color: '#FFF',
      fontSize: '32px',
      maxWidth: '100%',
      lineHeight: '1.3',
    },
    '.chart-title': { color: '#FFF' },
    '.chart-percentage > span': { color: '#FFF' },
    '.card': {
      background: '#fff',
      borderRadius: 8,
    },
    '.insights-table': {
      'tr.selected': {
        td: {
          borderTop: '2px solid #FF6C00',
          borderBottom: '2px solid #FF6C00',
          '&:first-child': {
            borderLeft: '2px solid #FF6C00',
          },
          '&:last-child': {
            borderRight: '2px solid #FF6C00',
          },
        },
      },
    },
  },
  marginTop: '20px',
  '&.padding': {
    padding: '10px 20px',
  },
});

const EmptyStateContainer = styled(Box)({
  flex: '1 1 auto',
  padding: '20px 22px 15px 22px',
  borderRadius: '8px',
  backgroundColor: '#FFFFFF',
  minHeight: '100px',
});

const StyledBox = styled(Box)(({ theme: { palette } }) => ({
  width: 103,
  height: 103,
  background: '#CDF1F3',
  textAlign: 'center',
  borderRadius: 999,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
}));

function RcaEditorOverview() {
  const { showPopup, showConfirmationModal } = useUiPopup();
  const { systemText } = useSystemText();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const {
    caseId,
    caseDetail,
    filteredImpacts,
    themes,
    totals,
    outcomes,
    isLoading,
    canShowCompleteButton,
    canShowReOpenButton,
    canChangeCaseDetails,
    reopenCase,
    isFetching,
    contributors,
    invitedGroups,
    solutionTermCoverage,
    evidenceStrengthCoverage,
    evidenceStrengthChartData,
  } = useRcaEditorOverview();

  const onReopenRca = async () => {
    await showConfirmationModal({
      title: 'Reopen RCA',
      message: 'Are you sure you want to reopen this RCA?',
      yesButtonLabel: 'Reopen RCA',
      noButtonLabel: 'Cancel',
      yesButtonAsyncAction: reopenCase,
    });
  };

  const startCompleteFlow = async () => {
    await showPopup(CompleteRcaModal);
  };

  return (
    <Container>
      <Row className="headerBar">
        <div>
          <Typography variant="h1">
            {isLoading ? <InlineBoxSkeleton width={250} /> : caseDetail!.name}
          </Typography>
          <Gap size={12} />
          <Typography variant="caption">
            {isLoading ? (
              <InlineBoxSkeleton width={150} />
            ) : (
              `${systemText['RCA.Overview.FocalPoint']}: ${
                caseDetail!.focalPoint
              }`
            )}
          </Typography>
        </div>
        <Spacer />
        <FetchingIndicator show={!isLoading && isFetching} />
        <Spacer />
        {isLoading ? (
          <LabelValueSkeleton />
        ) : (
          <LabelValueDisplay
            label="CREATED BY"
            value={
              <InitialsContainerWithData
                fullName={caseDetail!.createdByCompanyUserName}
              />
            }
          />
        )}
        <Gap size={50} />
        {isLoading ? (
          <LabelValueSkeleton />
        ) : (
          <LabelValueDisplay
            label="SENSITIVITY"
            value={<RcaSeverityPill severity={caseDetail!.caseSeverityName} />}
          />
        )}
        <Gap size={50} />
        {isLoading ? (
          <LabelValueSkeleton />
        ) : (
          <LabelValueDisplay
            label="STATUS"
            value={<RcaStatusText statusId={caseDetail!.caseStatusId} />}
          />
        )}
        <Gap size={50} />
        {isLoading ? (
          <ButtonSkeleton noGrow />
        ) : (
          <OutlinedButton onClick={() => navigate(`/rca/tabs/${caseId}/chart`)}>
            Go To Analysis
            <FontAwesomeIcon icon={faArrowRight} />
          </OutlinedButton>
        )}
      </Row>
      <Gap size={32} />
      <OverviewGetStartedContainer />
      <Row className="testRow">
        <DashboardStatContainer className="test" isTotals>
          {isLoading ? (
            <InlineBoxSkeleton width={346} height="100%" />
          ) : (
            <LargeDashboardStatBox
              bgColor="#FFCFAB3D"
              fontColour="#FF6C00"
              icon={<CauseBoxIcon width={28} height="auto" stroke="#FF6C00" />}
              label="Cause Boxes"
              value={totals!.chainItems}
            />
          )}
          {isLoading ? (
            <InlineBoxSkeleton width={346} height="100%" />
          ) : (
            <LargeDashboardStatBox
              bgColor="#C640A91A"
              fontColour="#C640A9"
              icon={<FontAwesomeIcon icon={faUser} fill="#C640A9" />}
              label={`Team Members (${contributors!.totalCount})`}
              value={
                <GroupedAvatars
                  extractInitials
                  items={contributors!.model.map((x) => ({
                    id: x.companyUserId,
                    label: x.name,
                  }))}
                />
              }
            />
          )}
          {isLoading ? (
            <InlineBoxSkeleton width={346} height="100%" />
          ) : (
            <LargeDashboardStatBox
              bgColor="#26AEB61A"
              fontColour="#26AEB6"
              icon={<FontAwesomeIcon icon={faUserGroup} fill="#0097E1" />}
              label={`Groups (${invitedGroups?.length ?? 0})`}
              value={
                (invitedGroups ?? []).length > 0 ? (
                  <GroupedBadges
                    items={invitedGroups!}
                    maxCount={2}
                    small={false}
                    transparentCount
                  />
                ) : (
                  'No groups'
                )
              }
            />
          )}
        </DashboardStatContainer>
        <div className="quick-links">
          <h3>Quick Links</h3>
          <div className="links">
            {canChangeCaseDetails && (
              <>
                <Link className="link" href={`/rca/edit/${caseId}`}>
                  Edit RCA
                  <FontAwesomeIcon icon={faArrowRight} />
                </Link>
              </>
            )}
            {canChangeCaseDetails && (
              <>
                <Link
                  className="link"
                  href={`/rca/edit/${caseId}/?initialTab=${SelectedCaseEditTab.collaboration}`}
                >
                  Manage Team
                  <FontAwesomeIcon icon={faArrowRight} />
                </Link>
              </>
            )}
            {canShowReOpenButton && (
              <>
                <Link className="link" onClick={onReopenRca}>
                  Reopen RCA
                  <FontAwesomeIcon icon={faArrowRight} />
                </Link>
              </>
            )}
            <Link className="link" href={`/rca/tabs/${caseId}/files`}>
              View Files <FontAwesomeIcon icon={faArrowRight} />
            </Link>
            <Link
              className="link"
              href="../activity"
              onClick={(e) => {
                e.preventDefault();
                showPopup<ActivityPanelDrawerProps, never>(
                  ActivityPanelDrawer,
                  {
                    view: ActivityPanelView.caseLevel,
                  }
                );
              }}
            >
              Activity Feed <FontAwesomeIcon icon={faArrowRight} />
            </Link>
          </div>
          {canShowCompleteButton && (
            <>
              <Link className="completed" onClick={startCompleteFlow}>
                Complete RCA
              </Link>
            </>
          )}
        </div>
      </Row>
      <Gap size={32} />
      {!isLoading && solutionTermCoverage!.data!.length > 0 && (
        <>
          <ReportSectionContainer className="dark">
            <SolutionsSectionOverview
              termReport={solutionTermCoverage!}
              showChart={false}
            />
          </ReportSectionContainer>
        </>
      )}
      <DashboardStatContainer gap={24}>
        {isLoading && filteredImpacts != null ? (
          <InlineBoxSkeleton width="32%" height={300} />
        ) : (
          <DashboardLeagueTable
            title="Evidence"
            description={
              evidenceStrengthCoverage!.totals.linkedEvidenceCount > 0 ? (
                <>
                  This RCA has{' '}
                  <span>
                    {evidenceStrengthCoverage!.totals.linkedEvidenceCount}
                  </span>{' '}
                  piece
                  {evidenceStrengthCoverage!.totals.linkedEvidenceCount === 1
                    ? ''
                    : 's'}{' '}
                  of evidence assigned to causes, achieving{' '}
                  <span>
                    {formatNumberToDecimals(
                      evidenceStrengthCoverage!.totals.coveragePercent
                    )}
                    %
                  </span>{' '}
                  overall coverage. The average evidence strength is{' '}
                  <span
                    style={{
                      color: getStrengthColor(
                        evidenceStrengthCoverage!.metaData.length > 0
                          ? evidenceStrengthCoverage!.metaData[0].strength
                          : 0
                      ),
                    }}
                  >
                    {evidenceStrengthCoverage!.totals.linkedEvidenceAverageStrength.toFixed(
                      0
                    )}
                    /5
                  </span>
                  .
                </>
              ) : (
                <></>
              )
            }
            tableTitle="Evidence"
            emptyMessage={
              filteredImpacts!.realCount === 0
                ? 'No evidence assigned to visual analysis'
                : 'No evidence match the selected filter'
            }
            entries={
              evidenceStrengthCoverage!.totals.linkedEvidenceCount > 0
                ? evidenceStrengthChartData
                : []
            }
          />
        )}
        {isLoading ? (
          <InlineBoxSkeleton width="32%" height={300} />
        ) : (
          <DashboardStatTheme report={themes} descriptionMinHeight="72px" />
        )}
        {isLoading ? (
          <InlineBoxSkeleton width="32%" height={300} />
        ) : (
          <DashboardStatSolutions
            report={solutionTermCoverage}
            totalChainItems={totals!.chainItems}
          />
        )}
      </DashboardStatContainer>
      <OverviewContainer
        dark={outcomes != null && outcomes.totalCount > 0}
        className="dark"
        sx={{
          '&.dark table thead tr th': {
            color: '#fff !important',
          },
          '&.dark table tbody tr:hover td': {
            borderTop: '2px solid #FF6C00 !important',
            borderBottom: '2px solid #FF6C00 !important',
          },
          '&.dark tbody tr:hover td:first-child': {
            borderLeft: '2px solid #FF6C00 !important',
          },
          '&.dark table tbody tr:hover td:last-child': {
            borderRight: '2px solid #FF6C00 !important',
          },
        }}
      >
        <Row>
          {isLoading ? (
            <InlineBoxSkeleton width="100%" height={300} />
          ) : (
            <OutcomesData
              outcomes={outcomes!.model}
              caseId={caseId}
              showTitle
            />
          )}
        </Row>
      </OverviewContainer>
    </Container>
  );
}

export default requireAuth(withEditorScaffold(RcaEditorOverview));
