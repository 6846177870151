import { ColumnForm } from '@components/layout-util-components/column';
import { Grid, Typography } from '@mui/material';
import { Gap } from '@components/layout-util-components/gap';
import useBusyAction from '@hooks/use-busy-action-hook';
import { onlyNumbers, required, validEmail } from '@util/validators';
import useField from '@hooks/use-field-hook';
import useFieldsWatcher from '@hooks/use-fields-watcher';
import { usePageAlertVariants } from '@components/alerts';
import { invalidation } from '@api/cache-util';
import { isApiError } from '@api/types/api-error';
import ActionsRow from '@components/layout-util-components/actions-row';
import { OutlinedButton, PrimaryButton } from '@components/buttons';
import WCTTextField from '@components/input/text-field';
import { useSeedWACompanyMutation } from '@api/endpoints/wct-admin/wct-admin-company.api';
import { WASeedCompanyRequest } from '@api/types/wct-admin/wct-admin-company-types';
import { useGetAllWAInstallNameOptionsQuery } from '@api/endpoints/wct-admin/wct-admin-install.api';
import WCTSelectField from '@components/input/select-field';
import WCTSwitchField from '@components/input/switch-field';
import { useGetAllWASectorOptionsQuery } from '@api/endpoints/wct-admin/wct-admin-sector.api';
import { useGetAllWABusinessAreaOptionsQuery } from '@api/endpoints/wct-admin/wct-admin-business-area.api';
import { useGetAllWACountryOptionsQuery } from '@api/endpoints/wct-admin/wct-admin-country.api';
import {
  DrawerFooter,
  DrawerHeader,
  SideDrawerFormProps,
  withSideDrawer,
} from '@components/ui-popup/drawers';

export interface WASeedCompanyFormProps extends SideDrawerFormProps<never> {
  installId?: number;
}

const WASeedCompanyForm = ({
  onClose,
  installId: defaultInstallId,
}: WASeedCompanyFormProps) => {
  const { showSuccessMessage, showErrorMessage } = usePageAlertVariants();
  const [seedWACompanyMutation] = useSeedWACompanyMutation();

  const { data: installOptions, isLoading: loadingInstallOptions } =
    useGetAllWAInstallNameOptionsQuery();

  const { data: sectorOptions, isLoading: loadingSectorOptions } =
    useGetAllWASectorOptionsQuery();

  const { data: businessAreaOptions, isLoading: loadingBuesinessAreaOptions } =
    useGetAllWABusinessAreaOptionsQuery();

  const { data: countryOptions, isLoading: loadingCountryOptions } =
    useGetAllWACountryOptionsQuery();

  const selectInstall = useField<boolean>([]);
  const creatingInstall = () => selectInstall.value === true;
  const installId = useField<number>([], defaultInstallId);
  const email = useField<string>([required(), validEmail()]);
  const firstName = useField<string>([required()]);
  const lastName = useField<string>([required()]);
  const companyName = useField<string>([required()]);
  const sectorId = useField<number>([required()]);
  const businessAreaId = useField<number>([required()]);
  const countryId = useField<number>([required()]);
  const installName = useField<string>([required({ when: creatingInstall })]);
  const companyLimit = useField<number>([
    required({ when: creatingInstall }),
    onlyNumbers(),
  ]);
  const manualPayments = useField<boolean>([]);
  const allowPublicSignups = useField<boolean>([]);
  const installAppUrl = useField<string>([required({ when: creatingInstall })]);
  const installApiUrl = useField<string>([required({ when: creatingInstall })]);
  const po = useField<string>([required({ when: creatingInstall })]);
  const billingCompanyName = useField<string>([
    required({ when: creatingInstall }),
  ]);
  const addressLineOne = useField<string>([
    required({ when: creatingInstall }),
  ]);
  const addressLineTwo = useField<string>([]);
  const town = useField<string>([]);
  const postcode = useField<string>([required({ when: creatingInstall })]);
  const contactName = useField<string>([required({ when: creatingInstall })]);
  const contactEmail = useField<string>([required({ when: creatingInstall })]);
  const contactTelephone = useField<string>([
    required({ when: creatingInstall }),
  ]);
  const contactNotes = useField<string>([]);

  const { isValid, validateAll } = useFieldsWatcher([
    installId,
    email,
    firstName,
    lastName,
    companyName,
    sectorId,
    businessAreaId,
    countryId,
    installName,
    companyLimit,
    manualPayments,
    allowPublicSignups,
    installAppUrl,
    installApiUrl,
    po,
    billingCompanyName,
    addressLineOne,
    addressLineTwo,
    town,
    postcode,
    contactName,
    contactEmail,
    contactTelephone,
    contactNotes,
  ]);

  const [seedCompany, isBusy] = useBusyAction(async () => {
    // if (!validateAll()) {
    //   return false;
    // }

    try {
      await seedWACompanyMutation({
        installId: creatingInstall() ? undefined : installId.value,
        install: creatingInstall()
          ? {
              name: installName.value,
              companyLimit: !isNaN(Number(companyLimit.value))
                ? Number(companyLimit.value)
                : 0,
              manualPayments: manualPayments.value ? true : false,
              allowPublicSignups: allowPublicSignups.value ? true : false,
              installAPIUrl: installApiUrl.value,
              installAPPUrl: installAppUrl.value,
              po: po.value,
              companyName: billingCompanyName.value,
              addressLineOne: addressLineOne.value,
              addressLineTwo: addressLineTwo.value,
              town: town.value,
              postcode: postcode.value,
              contacts: [
                {
                  name: contactName.value,
                  email: contactEmail.value,
                  telephone: contactTelephone.value,
                  notes: contactNotes.value,
                },
              ],
            }
          : undefined,
        companyName: companyName.value,
        sectorId: sectorId.value,
        businessAreaId: businessAreaId.value,
        countryId: countryId.value,
        userEmailAddress: email.value,
        userFirstName: firstName.value,
        userLastName: lastName.value,
      }).unwrap();
      await invalidation('WAInstall');
      showSuccessMessage('Company created successfully');
      onClose();
      return true;
    } catch (error) {
      if (isApiError<WASeedCompanyRequest>(error)) {
        const { message, errors } = error;
        // showErrorMessage(errors?.id ?? message);

        installId.setError(errors?.installId);
        email.setError(errors?.userEmailAddress);
        firstName.setError(errors?.userFirstName);
        lastName.setError(errors?.userLastName);
        companyName.setError(errors?.companyName);
        sectorId.setError(errors?.sectorId);
        businessAreaId.setError(errors?.businessAreaId);
        countryId.setError(errors?.countryId);
        installName.setError(errors?.install);
      }
      return false;
    }
  });

  const canSubmit = isValid && !isBusy;

  return (
    <ColumnForm
      noValidate
      onSubmit={(e) => {
        e.preventDefault();
        seedCompany();
      }}
    >
      <DrawerHeader onClose={onClose}>Seed Company</DrawerHeader>
      <Gap size={24} />
      <Typography variant="h3">Install</Typography>
      <Gap size={24} />
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <WCTSwitchField
            name="selectInstall"
            label="New Install"
            value={selectInstall.value}
            onChange={selectInstall.set}
          />
        </Grid>

        {creatingInstall() ? (
          <>
            <Grid item xs={12}>
              <WCTTextField
                name="installName"
                label="Install Name"
                onChange={installName.set}
                value={installName.value}
                error={installName.error}
                required
              />
            </Grid>
            <Grid item xs={3}>
              <WCTTextField
                name="companyLimit"
                label="Company Limit"
                onChange={companyLimit.set}
                value={companyLimit.value?.toString()}
                error={companyLimit.error}
                required
              />
            </Grid>
            <Grid item xs={9}>
              <WCTSwitchField
                name="manualPayments"
                label="Manual Payments"
                onChange={manualPayments.set}
                value={manualPayments.value}
                error={manualPayments.error}
              />
              <WCTSwitchField
                name="allowPublicSignups"
                label="Allow Public Signups"
                onChange={allowPublicSignups.set}
                value={allowPublicSignups.value}
                error={allowPublicSignups.error}
              />
            </Grid>
            <Grid item xs={12}>
              <WCTTextField
                name="installAPIURL"
                label="API URL"
                onChange={installApiUrl.set}
                value={installApiUrl.value}
                error={installApiUrl.error}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <WCTTextField
                name="installAPPURL"
                label="APP URL"
                onChange={installAppUrl.set}
                value={installAppUrl.value}
                error={installAppUrl.error}
                required
              />
            </Grid>
            <Grid item xs={24}>
              <Typography variant="h4">Contact Details</Typography>
            </Grid>
            <Grid item xs={12}>
              <WCTTextField
                name="contactName"
                label="Name"
                onChange={contactName.set}
                value={contactName.value}
                error={contactName.error}
                required
              />
            </Grid>
            <Grid item xs={6}>
              <WCTTextField
                name="contactEmail"
                label="Email"
                onChange={contactEmail.set}
                value={contactEmail.value}
                error={contactEmail.error}
                required
              />
            </Grid>
            <Grid item xs={6}>
              <WCTTextField
                name="contactTelephone"
                label="Telephone"
                onChange={contactTelephone.set}
                value={contactTelephone.value}
                error={contactTelephone.error}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <WCTTextField
                name="contactNotes"
                label="Notes"
                onChange={contactNotes.set}
                value={contactNotes.value}
                error={contactNotes.error}
                multiline
                required
              />
            </Grid>
            <Grid item xs={24}>
              <Typography variant="h4">Billing Details</Typography>
            </Grid>
            <Grid item xs={12}>
              <WCTTextField
                name="billingCompanyName"
                label="Company Name"
                onChange={billingCompanyName.set}
                value={billingCompanyName.value}
                error={billingCompanyName.error}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <WCTTextField
                name="po"
                label="PO"
                onChange={po.set}
                value={po.value}
                error={po.error}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <WCTTextField
                name="addressLineOne"
                label="Address Line One"
                onChange={addressLineOne.set}
                value={addressLineOne.value}
                error={addressLineOne.error}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <WCTTextField
                name="addressLineTwo"
                label="Address Line Two"
                onChange={addressLineTwo.set}
                value={addressLineTwo.value}
                error={addressLineTwo.error}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <WCTTextField
                name="town"
                label="Town"
                onChange={town.set}
                value={town.value}
                error={town.error}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <WCTTextField
                name="postcode"
                label="Postcode"
                onChange={postcode.set}
                value={postcode.value}
                error={postcode.error}
                required
              />
            </Grid>
          </>
        ) : (
          <Grid item xs={8}>
            <WCTSelectField
              id="installId"
              name="installId"
              label="Install"
              options={installOptions ?? []}
              onChange={installId.set}
              value={installId.value}
              error={installId.error}
              required
            />
          </Grid>
        )}
      </Grid>
      <Gap size={24} />
      <Typography variant="h4">Admin User Details</Typography>
      <Gap size={24} />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <WCTTextField
            name="email"
            label="Email Address"
            onChange={email.set}
            value={email.value}
            error={email.error}
            required
          />
        </Grid>

        <Grid item xs={6}>
          <WCTTextField
            name="firstName"
            label="First Name"
            onChange={firstName.set}
            value={firstName.value}
            error={firstName.error}
            required
          />
        </Grid>

        <Grid item xs={6}>
          <WCTTextField
            name="surname"
            label="Last Name"
            onChange={lastName.set}
            value={lastName.value}
            error={lastName.error}
            required
          />
        </Grid>
      </Grid>
      <Gap size={24} />
      <Typography variant="h4">Default Company Details</Typography>
      <Gap size={24} />
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <WCTTextField
            name="companyName"
            label="Company Name"
            onChange={companyName.set}
            value={companyName.value}
            error={companyName.error}
            required
          />
        </Grid>
        <Grid item xs={6}>
          <WCTSelectField
            id="sectorId"
            name="installId"
            label="Sector"
            options={sectorOptions ?? []}
            onChange={sectorId.set}
            value={sectorId.value}
            error={sectorId.error}
            required
          />
        </Grid>
        <Grid item xs={6}>
          <WCTSelectField
            id="businessAreaId"
            name="businessAreaId"
            label="Business Area"
            options={businessAreaOptions ?? []}
            onChange={businessAreaId.set}
            value={businessAreaId.value}
            error={businessAreaId.error}
            required
          />
        </Grid>
        <Grid item xs={6}>
          <WCTSelectField
            id="countryId"
            name="countryId"
            label="Country"
            options={countryOptions ?? []}
            onChange={countryId.set}
            value={countryId.value}
            error={countryId.error}
            required
          />
        </Grid>
      </Grid>
      <Gap size={24} />
      <DrawerFooter>
        <OutlinedButton onClick={() => onClose()}>Cancel</OutlinedButton>
        <PrimaryButton type="submit" disabled={!canSubmit} isBusy={isBusy}>
          Create Company
        </PrimaryButton>
      </DrawerFooter>
    </ColumnForm>
  );
};

export default withSideDrawer(WASeedCompanyForm);
