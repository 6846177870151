import useField from '@hooks/use-field-hook';
import { useGetThemeOptionsQuery } from '@api/endpoints/theme.api';
import {
  useGetChainItemQuery,
  useUpdateChainItemMutation,
} from '@api/endpoints/chain/chain-item.api';
import { useAppDispatch, useAppSelector } from '@store/store';
import {
  makeSelectIsNodeBusy,
  makeSelectParentNode,
  selectChainId,
  selectCurrentRcaCaseId,
  selectFocusedNode,
} from '@store/rca-editor/selectors';
import useFieldsWatcher from '@hooks/use-fields-watcher';
import { usePageAlertVariants } from '@components/alerts';
import { useBinaryOptions } from '@util/constant-options';
import { useMemo } from 'react';
import {
  removeDisproval,
  updateNodeLabel,
} from '@store/rca-editor/rca-editor-actions';
import { UpdateChainItemRequest } from '@api/types/chain/update-chain-item.request';
import { ApiError } from '@api/types/api-error';
import useSystemText from '@hooks/use-system-text';
import { useCasePermission } from '@hooks/case/use-case-permission';
import useBusyAction from '@hooks/use-busy-action-hook';
import { lessThan } from '@util/validators';
import { RCA_BOX_TEXT_MAX_LENGTH } from '@pages/app/rca/tabs/components/node-types/rca-default-node';

export default function useOverviewPanel() {
  const dispatch = useAppDispatch();
  const { showSuccessMessage, showErrorMessage } = usePageAlertVariants();
  const caseId = useAppSelector(selectCurrentRcaCaseId);
  const { canContribute } = useCasePermission();

  const { data: themeOptions, isLoading: loadingThemes } =
    useGetThemeOptionsQuery(caseId);

  const focusedNode = useAppSelector(selectFocusedNode);
  const isNodeBusy = useAppSelector(makeSelectIsNodeBusy(focusedNode.id));
  const chainId = useAppSelector(selectChainId)!;
  const { chainItemId, disproved } = focusedNode.data ?? {};
  const selectParentNode = useMemo(
    () => makeSelectParentNode(focusedNode.id),
    [focusedNode.id]
  );
  const parentNode = useAppSelector(selectParentNode);
  const hasChainItemId = chainItemId != null;
  const canEdit = hasChainItemId && canContribute;

  const [updateOverview] = useUpdateChainItemMutation();

  const {
    data: chainItemData,
    isLoading: loadingChainItem,
    isFetching: fetchingChainItem,
  } = useGetChainItemQuery(
    { chainId, chainItemId: chainItemId ?? -1 },
    { skip: !hasChainItemId }
  );

  const { systemText } = useSystemText();

  const nodeTypeOptions = useBinaryOptions({
    falseLabel: systemText['RCA.CauseBox.Transitory'],
    trueLabel: systemText['RCA.CauseBox.NonTransitory'],
  });

  const description = useField<string>(
    [lessThan(RCA_BOX_TEXT_MAX_LENGTH)],
    chainItemData?.description
  );
  const theme = useField<Array<number>>(
    [],
    useMemo(() => {
      return chainItemData?.themes.map((theme) => theme.themeId) ?? [];
    }, [chainItemData?.themes])
  );
  const transitory = useField<boolean>([], chainItemData?.transitory);

  const { isValid, isDirty } = useFieldsWatcher([
    description,
    theme,
    transitory,
  ]);

  const [onSubmit, isSubmitting] = useBusyAction(async () => {
    try {
      await dispatch(updateNodeLabel(focusedNode.id, description.value));

      await updateOverview({
        chainId,
        chainItemId: chainItemId!,
        description: description.value,
        transitory: transitory.value,
        themes: theme.value,
      }).unwrap();

      showSuccessMessage(`Updated Cause Box ${description.value}`);

      return true;
    } catch (e) {
      const { message, errors } = e as ApiError<UpdateChainItemRequest>;
      showErrorMessage(message);

      description.setError(errors?.description);
      theme.setError(errors?.themes);
      transitory.setError(errors?.transitory);

      return false;
    }
  });

  const removeDisproved = () => {
    dispatch(removeDisproval(focusedNode.id));
  };

  const isFetching = isNodeBusy || fetchingChainItem;
  const isLoading = loadingThemes || loadingChainItem || chainItemId == null;
  const canSubmit =
    !isLoading && !isFetching && !isSubmitting && isValid && isDirty;

  return {
    nodeTypeOptions,
    hasChainItemId,
    description,
    theme,
    transitory,
    themeOptions,
    isLoading,
    canEdit,
    canSubmit,
    isFetching,
    isSubmitting,
    onSubmit,
    disproved,
    removeDisproved,
    canContribute,
    canRemoveDisproval: canContribute && !(parentNode?.data.disproved ?? false),
  };
}
