import Column from '@components/layout-util-components/column';
import { styled, Typography } from '@mui/material';
import { Gap } from '@components/layout-util-components/gap';
import TutorialCard from '@components/cards/tutorial-card';
import CrossSellPlaceholder from '@assets/images/cross-sell-placeholder.png';

const Container = styled(Column)(({ theme }) => ({
  paddingTop: 80,
  justifyContent: 'flex-start',
  '.title': {
    color: '#fff',
    fontSize: 20,
    fontWeight: 400,
    marginBottom: 8,
  },
  '.sub-title': {
    color: '#fff',
    fontSize: 15,
    fontWeight: 400,
    opacity: '0.8',
    lineHeight: 1.5,
  },
  '.content': {
    color: '#fff',
    lineHeight: 1.4,
    fontSize: 18,
  },
  '.content-sm': {
    color: '#fff',
    lineHeight: 1.4,
    fontSize: 16,
  },
}));

export default function HelpSideBar() {
  return <></>;

  // return (
  //   <Container centerContent className="help-side-bar">
  //     <Typography variant="h4" className="title">
  //       Setting up your analysis
  //     </Typography>
  //     <Gap size={4} />
  //     <Typography variant="body2" className="sub-title">
  //       Lorem ipsum dolor sit amet, consectetur adipiscing elit.
  //     </Typography>
  //     <Gap size={50} />
  //     <Typography variant="body1" className="content">
  //       How to get started
  //     </Typography>
  //     <Gap size={20} />
  //     <TutorialCard
  //       title="How to setup an RCA investigation"
  //       tags={['Analysis', 'RCA']}
  //       time="2:00"
  //       isVideo
  //     />
  //     <Gap size={32} />
  //     <Typography variant="body1" className="content-sm">
  //       Need assistance?
  //     </Typography>
  //     <Gap size={20} />
  //     <img src={CrossSellPlaceholder} alt="placeholder" />
  //   </Container>
  // );
}
