import { SubscriptionStatus } from '@api/types/subscription/subscription-status';
import { getDate } from '@util/date-util';
import dayjs from 'dayjs';
import { ErrorAlert } from '@components/alerts/index';
import { useAppTr } from '@i18n/use-app-tr';
import { Trans } from 'react-i18next';
import { Link, Typography } from '@mui/material';
import { COMPANY_BILLING_PATH, useIsPath } from '@util/path-util';
import TrialExpiryGateModal from '@components/ui-popup/modals/trial-expiry-gate-modal';
import useUser from '@store/user/user-hook';
import { useGetSubscriptionDetailsQuery } from '@api/endpoints/subscription.api';
import PaymentIssueModal from '@components/ui-popup/modals/payment-issue-modal';
import { useGetInstallConfigurationQuery } from '@api/endpoints/install.api';
import ManualPaymentIssueModal from '@components/ui-popup/modals/manual-payment-issue-modal';

export default function BillingAlert() {
  const now = dayjs();
  const { t } = useAppTr('common');
  const isCompanyBillingPath = useIsPath(COMPANY_BILLING_PATH);
  const { data, isLoading } = useGetSubscriptionDetailsQuery();
  const { data: installConfiguration } = useGetInstallConfigurationQuery();
  const { isAdminOrSuperAdminUser } = useUser();

  if (isLoading || data == null) {
    return <></>;
  }

  if (installConfiguration?.manualPayments === true) {
    if (
      data.status !== SubscriptionStatus.trialing &&
      data.status !== SubscriptionStatus.active
    ) {
      return <ManualPaymentIssueModal show onClose={() => null} />;
    }

    return <></>;
  }

  const details = data!;
  if (details.status === SubscriptionStatus.trialing) {
    const freeTrialEndDate = getDate(details.freeTrialEnd);
    if (freeTrialEndDate == null) {
      return <></>;
    }

    const renderPreTrialExpireAlert = () => {
      if (!isAdminOrSuperAdminUser || data == null || data.hasPaymentMethod) {
        return <></>;
      }

      return (
        <ErrorAlert
          title={t('trialExpireAlert.beforeTrialEnd.title', {
            date: freeTrialEndDate.toDate(),
          })}
          message={
            <Trans t={t} i18nKey={'trialExpireAlert.beforeTrialEnd.message'}>
              <Link href="/company-profile/billing">
                Update your payment details
              </Link>
            </Trans>
          }
        />
      );
    };

    const fourteenDaysFromExpiry = freeTrialEndDate.add(14, 'days');
    const renderPostTrialAlert = () => {
      if (!isAdminOrSuperAdminUser) {
        return <></>;
      }

      return (
        <ErrorAlert
          title={t('trialExpireAlert.before12Days.title', {
            date: fourteenDaysFromExpiry.toDate(),
          })}
          message={
            <Trans t={t} i18nKey={'trialExpireAlert.before12Days.message'}>
              <Link href="/company-profile/billing">
                Update your payment details
              </Link>
            </Trans>
          }
        />
      );
    };

    if (now.isAfter(freeTrialEndDate)) {
      if (now.isBefore(fourteenDaysFromExpiry)) {
        return renderPostTrialAlert();
      } else if (!isCompanyBillingPath) {
        return <TrialExpiryGateModal show onClose={() => null} />;
      } else {
        return renderPostTrialAlert();
      }
    } else {
      return renderPreTrialExpireAlert();
    }
  } else if (
    details.status !== SubscriptionStatus.active &&
    !isCompanyBillingPath
  ) {
    return <PaymentIssueModal show onClose={() => null} />;
  }

  return <></>;
}
