import { useMemo } from 'react';
import { DashboardLeagueTable } from './dashboard-stat-container';
import { formatNumberToDecimals, numberFromString } from '@util/string-util';

interface IDashboardStatThemeProps {
  report: any;
  limit?: number;
  descriptionMinHeight?: string | number;
}

export function DashboardStatTheme({
  report,
  limit,
  descriptionMinHeight,
}: IDashboardStatThemeProps) {
  const filteredThemes = useMemo(() => {
    if (!report || !report.data) return [];
    return report!.data
      .filter((x) => x.clientGeneratedId > 0)
      .slice(0, limit ?? 5);
  }, [report, limit]);

  return (
    <DashboardLeagueTable
      title={
        filteredThemes.length > 0
          ? `Themes (Top ${filteredThemes.length})`
          : 'Themes'
      }
      descriptionMinHeight={descriptionMinHeight}
      description={
        filteredThemes.length > 0 ? (
          <>
            <span
              style={{
                color: filteredThemes[0].colour,
              }}
            >
              '{filteredThemes[0].cells[1].value}'
            </span>{' '}
            is your most occurring Theme, accounting for{' '}
            <span>
              {formatNumberToDecimals(filteredThemes[0].cells[3].value)}%
            </span>{' '}
            of the total cause boxes.
          </>
        ) : (
          <></>
        )
      }
      tableTitle="Theme"
      emptyMessage="No themes assigned to visual analysis"
      entries={filteredThemes.map((row) => {
        return {
          id: row.clientGeneratedId,
          name: row.cells[1].value,
          causeBoxes: numberFromString(row.cells[2].value),
          percentage: numberFromString(row.cells[3].value) ?? 0.0,
          colour: row.colour,
        };
      })}
    />
  );
}
