import withModal from '@components/ui-popup/modals/modal-hoc';
import { styled, Typography } from '@mui/material';
import { Gap } from '@components/layout-util-components/gap';

const Container = styled('div')({
  maxWidth: 658,
  display: 'flex',
  flexDirection: 'column',
});

function ManualPaymentIssueModal() {
  return (
    <Container>
      <Typography variant="h3">Issue with your subscription</Typography>
      <Gap size={20} />
      <Typography variant="body2">
        There is an issue with your subscription please contact
        support@whatcausedthis.com
      </Typography>
    </Container>
  );
}

export default withModal(ManualPaymentIssueModal, { blurBackdrop: true });
