// This has been added after a lot of the state was inserted into the view
// moving across each section as and when needed...

import { useState } from 'react';
import { NodeEndStateType } from '@store/rca-editor/types';
import { useAppDispatch, useAppSelector } from '@store/store';
import {
  selectCurrentRcaCaseId,
  selectFocusedNode,
  selectIsHighlightMode,
  selectNodePanelEditorSelectedTab,
  selectRcaChartMode,
} from '@store/rca-editor/selectors';
import { ApiError, isApiError } from '@api/types/api-error';
import { ChainItemSetEndStateRequest } from '@api/types/chain/chain-item-set-end-state.request';
import { usePageAlertVariants } from '@components/alerts';
import {
  disproveNode,
  makeNodeEndState,
  removeDisproval,
  removeNode,
  toggleNodeCollapseState,
} from '@store/rca-editor/rca-editor-actions';
import useDefaultNode from '@pages/app/rca/tabs/components/node-types/default-node-hook';
import {
  NodePanelEditorTab,
  RcaChartMode,
  resetFocus,
  selectNodePanelEditorTab,
  setHighlightMode,
} from '@store/rca-editor/rca-editor-slice';
import { useGetCaseTotalsForChainItemQuery } from '@api/endpoints/case.api';
import { useCreateTaskForUserMutation } from '@api/endpoints/task-detail.api';
import { getNowUtc } from '@util/date-util';
import { TaskPriority } from '@api/types/task-detail/task-priority';
import { useUiPopup } from '@components/ui-popup/ui-popup-provider';
import useUser from '@store/user/user-hook';
import DisproveConfirmationModal, {
  DisproveConfirmationAction,
} from '@pages/app/rca/tabs/components/editor-panels/components/disprove-confirmation-modal';
import useSystemText from '@hooks/use-system-text';
import { PrimaryButton } from '@components/buttons';
import { RcaUtil } from '@util/rca-util';
import useBusyAction from '@hooks/use-busy-action-hook';
import { invalidation } from '@api/cache-util';
import { useCasePermission } from '@hooks/case/use-case-permission';

export enum NodePanelLoadingKey {
  endState,
}

type LoadingMap = Record<NodePanelLoadingKey, boolean>;

export default function useFocusedNodePanelHook() {
  const dispatch = useAppDispatch();
  const { showSuccessMessage, showErrorMessage } = usePageAlertVariants();
  const { showConfirmationModal, showDeleteConfirmationModal, showPopup } =
    useUiPopup();
  const { companyUserId } = useUser();
  const { systemText } = useSystemText();
  const { canContribute } = useCasePermission();

  const [loadingMap, setLoadingMap] = useState<LoadingMap>({
    [NodePanelLoadingKey.endState]: false,
  });

  const [createTask] = useCreateTaskForUserMutation();

  const caseId = useAppSelector(selectCurrentRcaCaseId);
  const mode = useAppSelector(selectRcaChartMode);
  const focusedNode = useAppSelector(selectFocusedNode);
  const isHighlightMode = useAppSelector(selectIsHighlightMode);
  const selectedTab = useAppSelector(selectNodePanelEditorSelectedTab);

  const {
    canMakeEndState: nodeCanMakeEndState,
    isEndState,
    hasChainItemId,
    childCount,
    childCountIncludingMetaNodes,
    disproved,
    isRoot,
    chainItemId,
    collapsedState,
    isParentDisproved,
  } = useDefaultNode(focusedNode);

  const { data: totals } = useGetCaseTotalsForChainItemQuery(
    {
      caseId: caseId,
      chainItemId: chainItemId ?? -1,
    },
    { skip: !hasChainItemId }
  );

  const isLoading = (key: NodePanelLoadingKey) => {
    return loadingMap[key];
  };

  const isPresenterMode = mode === RcaChartMode.present;
  const isBuildMode = !isPresenterMode;
  const isReadOnly = isPresenterMode || focusedNode.data.isRoot;
  const canPerformActions = !isReadOnly && isBuildMode && hasChainItemId;
  const canHighlightNode = isPresenterMode && hasChainItemId;
  const canCollapseNode =
    childCountIncludingMetaNodes > 0 && !disproved && hasChainItemId;
  const canCreateAsFocalPoint = !isRoot && hasChainItemId;
  const canConnectToCauseBox = !disproved && hasChainItemId;
  const canEditDisprovalState = hasChainItemId && !isParentDisproved;
  const canMakeEndState =
    nodeCanMakeEndState && !isLoading(NodePanelLoadingKey.endState);
  const canDelete = !isReadOnly && !isRoot;
  const shouldHighlightCollapsed = collapsedState !== 'collapse-state-default';

  const setLoading = (key: NodePanelLoadingKey, isLoading: boolean) => {
    setLoadingMap((prev) => ({
      ...prev,
      [key]: isLoading,
    }));
  };

  const setEndState = async (endState: NodeEndStateType) => {
    try {
      setLoading(NodePanelLoadingKey.endState, true);
      await dispatch(makeNodeEndState(focusedNode!.id, endState));
    } catch (e) {
      const { errors, message } = e as ApiError<ChainItemSetEndStateRequest>;
      showErrorMessage(errors?.chainItemId ?? errors?.endState ?? message);
    } finally {
      setLoading(NodePanelLoadingKey.endState, false);
    }
  };

  const [createAsFocusPoint] = useBusyAction(() => {
    return showConfirmationModal({
      title: 'Create as Focus of new investigation',
      message:
        'This will generate a task to create a new RCA with this cause as the Focal Point',
      noButtonLabel: 'Cancel',
      yesButtonLabel: 'Create Task',
      yesButtonAsyncAction: async () => {
        try {
          await createTask({
            title: 'Create new RCA with focal point',
            description: `Create a new RCA with the focal point '${focusedNode.data.label}'`,
            chainItemId: focusedNode.data.chainItemId!,
            dueDate: getNowUtc().add(1, 'day').toISOString(),
            priority: TaskPriority.standard,
            assignedToCompanyUserId: companyUserId!,
            caseId: caseId!,
          }).unwrap();
          await invalidation('TaskDetail');
          showSuccessMessage(
            `You have successfully created a task to create a new Focus from Cause Box #${focusedNode
              .data.chainItemId!}`
          );
          return true;
        } catch (e) {
          if (isApiError(e)) {
            const { message } = e;
            showErrorMessage(message);
          }
          return false;
        }
      },
    });
  });

  const deleteNode = async () => {
    let shouldDelete: boolean | undefined;
    let moveToStorage = childCount > 0;

    if (childCount === 0) {
      const associatedItems: Array<String> = [];
      if (!!totals?.evidence) {
        associatedItems.push('Evidence');
      }
      if (!!totals?.tasks) {
        associatedItems.push('Tasks');
      }
      if (!!totals?.impacts) {
        associatedItems.push('Impacts');
      }
      if (!!totals?.notes) {
        associatedItems.push('Notes');
      }
      if (!!totals?.solutions) {
        associatedItems.push('Solutions');
      }
      if (!!totals?.connections) {
        associatedItems.push('Connections');
      }

      const hasAssociatedItems = associatedItems.length > 0;
      let message: string = '';
      if (hasAssociatedItems) {
        message = `This chain has ${associatedItems.join(
          ', '
        )} associated with it. Are you sure you want to delete this Cause Box?\n\nAlternatively you can remove this Cause Box from the chart and move to the Cause Stack.`;
      }

      if (hasAssociatedItems) {
        shouldDelete = await showDeleteConfirmationModal({
          onDelete: async () => true,
          title: 'Are you sure you want to delete this Cause Box?',
          message: message,
          confirmTitle: 'Confirm deletion of Cause Box',
          confirmMessage:
            'To delete this Cause Box please type ‘DELETE’ below to confirm the deletion of this Cause Box',
          otherActionsAtEnd: true,
          otherActions: hasAssociatedItems
            ? ({ onClose }) => (
                <PrimaryButton
                  onClick={() => {
                    moveToStorage = true;
                    onClose(true);
                  }}
                >
                  Move To Cause Stack
                </PrimaryButton>
              )
            : undefined,
        });
      } else {
        shouldDelete = await showConfirmationModal({
          title: 'Are you sure you want to delete this Cause Box?',
          message: message,
          noButtonLabel: 'Cancel',
          yesButtonLabel: 'Yes, Delete',
          otherActionsAtEnd: true,
          otherActions: hasAssociatedItems
            ? ({ onClose }) => (
                <PrimaryButton
                  onClick={() => {
                    moveToStorage = true;
                    onClose(true);
                  }}
                >
                  Move To Cause Stack
                </PrimaryButton>
              )
            : undefined,
        });
      }
    } else {
      const message = `As there are dependent Cause Boxes in the Causal Path you must manually remove any dependant Cause Boxes before deleting.
        \n
        Alternatively you move the Cause Box and all dependants to the Cause Stack`;

      shouldDelete = await showConfirmationModal({
        title: 'You cannot delete a Cause Box with dependents',
        message: message,
        noButtonLabel: 'Cancel',
        yesButtonLabel: 'Move to Cause Stack',
      });
    }

    if (shouldDelete != null && shouldDelete) {
      dispatch(removeNode(focusedNode.id, { moveToStorage }));
      showSuccessMessage(
        childCount === 0
          ? 'You have successfully deleted the Cause Box'
          : 'You have successfully moved the Cause Box and all dependants to the Cause Stack'
      );
    }
  };

  const disprove = async () => {
    if (disproved) {
      dispatch(removeDisproval(focusedNode.id));
      return;
    }

    if (childCount >= 1) {
      const action = await showPopup(DisproveConfirmationModal);
      if (action != null) {
        await dispatch(
          disproveNode(
            focusedNode.id,
            action === DisproveConfirmationAction.moveToStorage
          )
        );

        showSuccessMessage(
          `You have successfully marked Cause Box ‘${focusedNode.data.label}’ and all child boxes ‘disproved’`
        );
      }

      return;
    }

    await dispatch(disproveNode(focusedNode.id, false));
    showSuccessMessage(
      `You have successfully marked Cause Box ‘${focusedNode.data.label}’ as ‘disproved’`
    );
  };

  const toggleHighlightMode = () => {
    dispatch(setHighlightMode(!isHighlightMode));
  };

  const toggleCollapsedState = () => {
    const shouldClose = collapsedState === 'collapse-state-default';
    dispatch(toggleNodeCollapseState(focusedNode.id));
    if (shouldClose) {
      dispatch(resetFocus());
      RcaUtil.smoothFocusToNode(focusedNode);
    }
  };

  const setTab = (tap: NodePanelEditorTab) => {
    dispatch(selectNodePanelEditorTab(tap));
  };

  return {
    isLoading,
    setLoading,
    setEndState,
    focusedNode,
    isEndState,
    hasChainItemId,
    isBuildMode,
    isPresenterMode,
    canPerformActions,
    canMakeEndState,
    canHighlightNode,
    canCollapseNode,
    canCreateAsFocalPoint,
    canConnectToCauseBox,
    canEditDisprovalState,
    disproved,
    childCount,
    canDelete,
    totals,
    isHighlightMode,
    selectedTab,
    systemText,
    createAsFocusPoint,
    deleteNode,
    disprove,
    toggleHighlightMode,
    toggleCollapsedState,
    setTab,
    shouldHighlightCollapsed,
    canContribute,
  };
}
