import styled from '@emotion/styled';
import { Box } from '@mui/material';

export const AvatarConnectingLine = () => {
  return (
    <ConnectingLineWrapper>
      <ConnectingLineBox />
    </ConnectingLineWrapper>
  );
};

const ConnectingLineWrapper = styled(Box)({
  width: 32,
  position: 'absolute',
  display: 'flex',
  justifyContent: 'center',
});

const ConnectingLineBox = styled(Box)({
  border: '1px solid #e3e3e3',
  width: 0,
  height: 30,
  marginTop: -30,
  zIndex: 0,
});
