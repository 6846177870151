import { api } from '@api/api';
import {
  WAAllCompanyFilters,
  WAAllCompanyStats,
  WACompanyResource,
  WACompanyRow,
  WASeedCompanyRequest,
  WASetCompanyStatusRequest,
} from '@api/types/wct-admin/wct-admin-company-types';
import { PaginatedResult } from '@api/types/paginated-result';
import { Option } from '@api/types/option';
import { TypeAheadFilters } from '@api/types/type-ahead-filters';
import { makeError } from '@api/types/api-error';
import { SHORT_CACHE_DURATION } from '@api/cache-util';

const wctAdminCompanyApi = api.injectEndpoints({
  endpoints: (build) => ({
    getWAAllCompanyStats: build.query<WAAllCompanyStats, void>({
      query: () => ({
        url: 'company/allCompanyStats',
        method: 'GET',
      }),
      providesTags: ['WACompany'],
      keepUnusedDataFor: SHORT_CACHE_DURATION,
    }),
    getWAAllCompanies: build.query<
      PaginatedResult<WACompanyRow>,
      WAAllCompanyFilters
    >({
      query: (params) => ({
        url: 'company',
        method: 'GET',
        params,
      }),
      providesTags: ['WACompany'],
      keepUnusedDataFor: SHORT_CACHE_DURATION,
    }),
    getWACompanyDetail: build.query<WACompanyResource, number>({
      query: (id) => ({
        url: `company/${id}`,
        method: 'GET',
      }),
      providesTags: ['WACompany'],
      keepUnusedDataFor: SHORT_CACHE_DURATION,
    }),
    getWACompanyOptions: build.query<Option[], TypeAheadFilters>({
      query: (params) => ({
        url: 'company/options',
        method: 'GET',
        params,
      }),
      providesTags: ['WACompany'],
      keepUnusedDataFor: SHORT_CACHE_DURATION,
    }),
    setWACompanyStatus: build.mutation<void, WASetCompanyStatusRequest>({
      query: (body) => ({
        url: 'company/setStatus',
        method: 'PUT',
        body,
      }),
      transformErrorResponse: (error) =>
        makeError<WASetCompanyStatusRequest>(error),
      invalidatesTags: (_, error) => (!error ? ['WACompany'] : []),
    }),
    seedWACompany: build.mutation<void, WASeedCompanyRequest>({
      query: (body) => ({
        url: 'company/SeedCompany',
        method: 'POST',
        body,
      }),
      transformErrorResponse: (error) => makeError<WASeedCompanyRequest>(error),
      invalidatesTags: (_, error) => (!error ? ['WACompany'] : []),
    }),
    deleteSeedWACompany: build.mutation<void, number>({
      query: (id) => ({
        url: `company/SeedCompany/${id}`,
        method: 'DELETE',
      }),
      transformErrorResponse: (error) => makeError<WASeedCompanyRequest>(error),
      invalidatesTags: (_, error) => (!error ? ['WACompany', 'WAInstall'] : []),
    }),
  }),
});

export const {
  useGetWAAllCompaniesQuery,
  useGetWAAllCompanyStatsQuery,
  useGetWACompanyDetailQuery,
  useGetWACompanyOptionsQuery,
  useSetWACompanyStatusMutation,
  useSeedWACompanyMutation,
  useDeleteSeedWACompanyMutation,
} = wctAdminCompanyApi;
