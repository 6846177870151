import { Box, Grid, styled, Typography } from '@mui/material';
import { ElipsisButton } from '@components/buttons';
import ContentFooterCard from '@components/cards/content-footer-card';
import Spacer from '@components/layout-util-components/spacer';
import { dateOnlyLongFormatter } from '@i18n/formatters';
import GroupedAvatars from '@components/containers/grouped-avatars';
import { CaseItemRowResponse } from '@api/types/case/case-item-row.response';
import { getAddressString, truncateString } from '@util/string-util';
import { useMemo } from 'react';

import { usePersonalCaseOptions } from '@hooks/case/use-personal-case-options';
import { useNavigate } from 'react-router-dom';
import { getInitials } from '@util/name-util';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLocationDot } from '@fortawesome/pro-solid-svg-icons';
import RcaIcon from '@components/rca-components/rca-icon';
import RcaStatusText from '@components/rca-components/rca-status-text';
import RcaSeverityPill from '@components/rca-components/rca-severity-pill';
import RcaHealthPill from '@components/rca-components/rca-health-pill';
import HeaderBGImage from '@assets/svgs/card-detail.svg';

interface Props {
  data: CaseItemRowResponse;
  afterClickRedirect: () => void;
  widthOverride?: number | string;
}
const CardHeader = styled('div')(({ theme: { palette } }) => ({
  background: `url(${HeaderBGImage}) no-repeat top right  ${palette.blue.dark}`,
  borderTopLeftRadius: 6,
  borderTopRightRadius: 6,
  padding: 20,
  gap: 12,
  display: 'flex',
  flexDirection: 'column',
  color: palette.common.white,
  '& p': {
    display: 'block',
    lineHeight: '150%',
    height: '2.9em',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    marginRight: 20,
  },
  '& .circle-container': {
    backgroundColor: palette.blue.light,
    color: palette.blue.dark,
  },
}));

const CardContent = styled('div')(({ theme: { palette } }) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: 20,
  gap: 20,
  '.pinIcon': {
    color: palette.primary.main,
  },
}));

const CardInfo = styled('div')(({ theme: { palette } }) => ({
  fontSize: '14px',
  fontWeight: 400,
  '& .MuiTypography-overline': {
    display: 'block',
    marginBottom: '0.5em',
    color: palette.blue.dark,
    fontSize: '12px',
  },
  '& .MuiTypography-body2': {
    color: palette.blue.dark,
  },
  '& .circle-container': {
    backgroundColor: palette.success.main,
    opacity: 1,
    fontSize: '10px',
    color: '#fff',
  },
  '& .name-container .label': {
    fontSize: '15px',
    fontWeight: 400,
  },
}));

export default function RcaCard({
  data,
  afterClickRedirect,
  widthOverride,
}: Props) {
  const navigate = useNavigate();
  const elipsisOptions = usePersonalCaseOptions(
    data.caseId,
    data.createdByCompanyUserId,
    data.caseStatusName?.toLowerCase() === 'draft',
    data.caseAnalysisTypeId
  );

  const incidentLocation = useMemo(() => {
    const location = data.incidentLocation;
    if (location == null) {
      return;
    }

    return location.name || getAddressString({ ...location });
  }, [data.incidentLocation]);

  return (
    <ContentFooterCard
      pointer
      widthOverride={widthOverride}
      onClick={() => {
        navigate(
          data.caseStatusName?.toLowerCase() === 'draft'
            ? `/rca/create/steps/?type=${data.caseAnalysisTypeId}&step=0&caseId=${data.caseId}`
            : `/rca/tabs/${data.caseId}`
        );
        afterClickRedirect();
      }}
      content={
        <>
          <CardHeader>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                gap: '10px',
              }}
            >
              <RcaIcon type={data.caseAnalysisTypeShortName} />
              <Typography
                variant="caption"
                sx={{
                  textTransform: 'uppercase',
                  color: '#fff',
                  fontSize: '12px',
                  fontWeight: 500,
                }}
              >
                {data.caseAnalysisTypeShortName}
              </Typography>
            </Box>

            <Typography variant="body1">
              {truncateString(data.name, 45)}
            </Typography>
          </CardHeader>
          <CardContent>
            <Box
              sx={{
                display: 'flex',
                gap: '10px',
              }}
            >
              <FontAwesomeIcon icon={faLocationDot} className="pinIcon" />
              <Typography variant="body2">
                {truncateString(incidentLocation ? incidentLocation : '-', 30)}
              </Typography>
            </Box>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <CardInfo>
                  <Typography variant="overline">Severity</Typography>
                  <RcaSeverityPill severity={data.caseSeverityName} />
                </CardInfo>
              </Grid>
              <Grid item xs={6}>
                <CardInfo>
                  <Typography variant="overline">Health</Typography>
                  <RcaHealthPill health={data.healthScore} />
                </CardInfo>
              </Grid>
              <Grid item xs={6}>
                <CardInfo>
                  <Typography
                    variant="overline"
                    sx={{
                      marginBottom: '10px !important',
                    }}
                  >
                    Created
                  </Typography>
                  {dateOnlyLongFormatter(data.created)}
                </CardInfo>
              </Grid>
              <Grid item xs={6}>
                <CardInfo>
                  <Typography variant="overline">Status</Typography>
                  <RcaStatusText statusId={data.caseStatusId || 0} />
                </CardInfo>
              </Grid>
            </Grid>
          </CardContent>
        </>
      }
      footer={
        <>
          <GroupedAvatars
            items={data.companyUsers.map(({ companyUserId, name }) => ({
              id: companyUserId,
              label: getInitials(name),
            }))}
          />
          <Spacer />
          <ElipsisButton actions={elipsisOptions} />
        </>
      }
    />
  );
}
