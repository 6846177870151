import { useGetCaseOutcomeResultQuery } from '@api/endpoints/case-outcome.api';
import { useEffect, useState } from 'react';
import { useAppDispatch } from '@store/store';
import caseImpactTypeMetricApi from '@api/endpoints/case-impact-type-metric.api';
import { CaseImpactTypeMetricsResource } from '@api/types/case-impact-type-metric/case-impact-type-metric.resource';
import {
  ChartDisplayMode,
  setDisplayMode,
} from '@store/rca-editor/rca-editor-slice';

export interface OutcomeReportScaleOptionsLookup {
  [key: number]: CaseImpactTypeMetricsResource;
}

export default function useOutcomeReport(caseId: number, outcomeId: number) {
  const dispatch = useAppDispatch();

  const [isLoadingMetricOptions, setIsLoadingMetricOptions] = useState(true);
  const [scaleOptionsLookup, setScaleOptionsLookup] =
    useState<OutcomeReportScaleOptionsLookup>({});

  const { data: result, isLoading: loadingResult } =
    useGetCaseOutcomeResultQuery(
      {
        caseId,
        outcomeId,
      },
      { refetchOnMountOrArgChange: true }
    );

  const isLoading = loadingResult || isLoadingMetricOptions;

  // Ensure chart is in health state mode
  useEffect(() => {
    dispatch(
      setDisplayMode({
        displayMode: ChartDisplayMode.healthScore,
        useNodeDataHealthScore: true,
      })
    );
  }, [dispatch]);

  useEffect(() => {
    async function loadMetricOptions() {
      const report = result;
      if (report == null) {
        return;
      }

      const impacts = report.caseImpacts;
      if (impacts != null) {
        const metricTypes = [
          ...new Set(impacts.map((x) => x.caseImpactTypeMetricId)),
        ];

        const promises = metricTypes.map((metricId) =>
          dispatch(
            caseImpactTypeMetricApi.endpoints.getMetricDetail.initiate(metricId)
          ).unwrap()
        );

        setIsLoadingMetricOptions(true);
        const results = await Promise.all(promises);
        setScaleOptionsLookup(
          results.reduce((acc, curr) => {
            acc[curr.caseImpactTypeMetricId] = curr;
            return acc;
          }, {} as OutcomeReportScaleOptionsLookup)
        );
        setIsLoadingMetricOptions(false);
      } else {
        setIsLoadingMetricOptions(false);
      }
    }
    loadMetricOptions();
  }, [dispatch, result]);

  return {
    isLoading,
    result,
    outcomeDetail: result?.outcome,
    scaleOptionsLookup,
  };
}

export type OutcomeReportState = ReturnType<typeof useOutcomeReport>;
