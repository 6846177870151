import { Drawer, DrawerProps, styled } from '@mui/material';
import useLensesPanel, {
  LensesPanelViewState,
} from '@pages/app/rca/tabs/lenses/lenses-panel-hook';
import OptionSelectView from '@pages/app/rca/tabs/lenses/views/option-select-view';
import ReportFormView from '@pages/app/rca/tabs/lenses/views/report-form-view';
import AllRecentLensesView from '@pages/app/rca/tabs/lenses/views/all-recent-lenses-view';
import { ReactNode, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '@store/store';
import { selectCurrentRcaCaseIdNullable } from '@store/rca-editor/selectors';
import { setIsHealthScorePanelOpen } from '@store/rca-editor/rca-editor-slice';
import useMaybeSnapToFocusedNode from '@pages/app/rca/tabs/hooks/use-maybe-snap-to-focused-node-hook';
import { ReportType } from '@api/types/report-type/report-type.option';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBullseyePointer,
  faChartPieSimple,
} from '@fortawesome/pro-light-svg-icons';
import { ReactComponent as CauseBoxIcon } from '@assets/svgs/cause-box-orange.svg';

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

export interface ITypeDetails {
  type: ReportType;
  name: string;
  icon: ReactNode;
  iconBackground: string;
  message: string;
}

const types: ITypeDetails[] = [
  {
    type: ReportType.coverage,
    name: 'Coverage Report',
    icon: (
      <FontAwesomeIcon
        height="auto"
        icon={faChartPieSimple}
        color="#C640A9"
        size="lg"
      />
    ),
    iconBackground: '#C640A91A',
    message: 'Select your report criteria and rank-by lenses',
  },
  {
    type: ReportType.dominant,
    name: 'Dominant Cause Report',
    icon: <CauseBoxIcon width={28} height="auto" stroke="#FF6C00" />,
    iconBackground: '#FFCFAB3D',
    message: 'Reveal the dominant causes in your RCA',
  },
  {
    type: ReportType.impact,
    name: 'Impact Report',
    icon: (
      <FontAwesomeIcon
        height="auto"
        icon={faBullseyePointer}
        color="#26AEB6"
        size="lg"
      />
    ),
    iconBackground: '#26AEB61A',
    message: 'How does your RCA address your event impacts?',
  },
];

const StyledDrawer = styled(Drawer, {
  target: 'health-score-side-panel',
})<DrawerProps>(({ theme: { palette } }) => ({
  '.MuiPaper-root': {
    top: '79px',
    height: 'calc(100vh - 79px)',
    width: 450,
    boxShadow: '0px 0px 15px 0px #00000040',
    background: '#fff',
    padding: 20,
    overflowY: 'auto',
    zIndex: '1000',
  },
}));

export default function LensesPanel({ isOpen, onClose }: Props) {
  const dispatch = useAppDispatch();
  const state = useLensesPanel();
  const caseId = useAppSelector(selectCurrentRcaCaseIdNullable);
  const navigate = useNavigate();
  const { viewState } = state;

  useMaybeSnapToFocusedNode(isOpen);

  useEffect(() => {
    if (isOpen && caseId != null) {
      dispatch(setIsHealthScorePanelOpen(false));
      navigate(`/rca/tabs/${caseId!}/chart`);
    }
  }, [caseId, dispatch, isOpen, navigate]);

  return (
    <StyledDrawer
      variant="persistent"
      open={isOpen}
      onClose={onClose}
      anchor="right"
      hideBackdrop
    >
      {viewState === LensesPanelViewState.optionSelect ? (
        <OptionSelectView onClose={onClose} state={state} types={types} />
      ) : viewState === LensesPanelViewState.reportForm ? (
        <ReportFormView onClose={onClose} state={state} types={types} />
      ) : viewState === LensesPanelViewState.allRecentLenses ? (
        <AllRecentLensesView onClose={onClose} state={state} />
      ) : null}
    </StyledDrawer>
  );
}
