import { Box, Typography, useTheme } from '@mui/material';

interface Props {
  statusId: number;
}

export default function RcaStatusText({ statusId }: Props) {
  const { palette } = useTheme();
  const statusText = {
    1: 'Draft',
    2: 'In Progress',
    3: 'Complete',
  };

  const statusBackgroundColor = {
    1: '#fff',
    2: 'rgba(127, 144, 156, 0.2)',
    3: 'rgba(69,197,150,0.2)',
  };

  const statusIndicatorColor = {
    1: '#B9C3CA',
    2: palette.blue.dark,
    3: palette.success.main,
  };

  const statusTextColor = {
    1: '#636D73',
    2: palette.blue.dark,
    3: palette.success.main,
  };

  return (
    <Box
      className="status"
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        height: 24,
        gap: '10px',
        // border: `1px solid ${statusTextColor[statusId]}`,
        borderRadius: '5px',
        padding: '5px',
        backgroundColor: statusBackgroundColor[statusId],
      }}
    >
      <Box
        sx={{
          width: '9px',
          height: '9px',
          borderRadius: 9,
          background: statusIndicatorColor[statusId],
        }}
      />
      <Typography
        sx={{
          fontSize: '14px',
          color: statusTextColor[statusId],
        }}
      >
        {statusText[statusId]}
      </Typography>
    </Box>
  );
}
