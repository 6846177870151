import { styled } from '@mui/material';
import { forwardRef, HTMLProps } from 'react';

interface Props extends HTMLProps<HTMLDivElement> {
  rounded?: boolean;
  small?: boolean;
  transparent?: boolean;
}

const StyledBadge = styled('div', { target: 'flat-badge' })<Props>(
  ({ rounded, small, transparent, theme: { palette } }) => ({
    padding: small ? '4px 10px' : '6px 10px',
    display: 'inline-flex',
    borderRadius: rounded ? '30px' : 4,
    background: transparent === true ? 'none' : '#D8DBE3',
    color: palette.common.black,
    fontFamily: 'Usual',
    fontSize: small ? '10px' : '12px',
    fontWeight: '400',
    lineHeight: small ? '12px' : '14px',
    letterSpacing: '0em',
    textAlign: 'center',
    flex: '0 0 auto',
  })
);

const FlatBadge = forwardRef<HTMLDivElement, Props>(
  ({ children, ...rest }, ref) => {
    return (
      <StyledBadge ref={ref} {...(rest as any)}>
        {children}
      </StyledBadge>
    );
  }
);

export default FlatBadge;
