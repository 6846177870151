import { styled } from '@mui/material';
import {
  NodeCollapseState,
  RcaNode,
  RcaNodeType,
} from '@store/rca-editor/types';
import { Handle, Position } from 'reactflow';
import { useAppDispatch, useAppSelector } from '@store/store';
import { makeSelectParentOrChildrenCollapsedState } from '@store/rca-editor/selectors';
import usePopoverMenu, {
  PopoverMenuAction,
} from '@components/popover-menu/use-popover-menu';
import { removeNode } from '@store/rca-editor/rca-editor-actions';
import PopoverMenu from '@components/popover-menu/popover-menu';
import { useMemo } from 'react';
import FetchingIndicator from '@components/loading-indicator/fetching-indicator';
import { useCasePermission } from '@hooks/case/use-case-permission';

const Indicator = styled('div')(({ theme: { palette } }) => ({
  position: 'relative',
  zIndex: 1,
  border: 'none',
  width: 25,
  height: 25,
  padding: '7px 6px 7px 6px',
  borderRadius: '50%',
  background: palette.common.grey90,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  color: 'white',
  fontFamily: 'Roboto',
  fontSize: '9px',
  fontWeight: '500',
  lineHeight: '8px',
  letterSpacing: '0px',
  textAlign: 'center',
  textTransform: 'uppercase',
  cursor: 'default',
  opacity: 1,
}));

export default function RcaMetaNode({
  type,
  id,
  data: { chainItemId },
}: RcaNode) {
  const { canEditGraph } = useCasePermission();
  const dispatch = useAppDispatch();
  const menu = usePopoverMenu();

  const selectCollapsedState = useMemo(() => {
    return makeSelectParentOrChildrenCollapsedState(id);
  }, [id]);

  const menuActions = useMemo((): Array<PopoverMenuAction> => {
    const ret: Array<PopoverMenuAction> = [];

    if (canEditGraph) {
      ret.push({
        label: 'Remove',
        onClick: () => dispatch(removeNode(id, { reparentChildren: true })),
      });
    }
    return ret;
  }, [canEditGraph, dispatch, id]);

  const collapsedState = useAppSelector(selectCollapsedState)!;
  if (
    collapsedState === NodeCollapseState.ghost ||
    collapsedState === NodeCollapseState.hidden
  ) {
    return <></>;
  }

  const isLoading = chainItemId == null;

  return (
    <>
      <Handle position={Position.Left} type="target" />
      <Indicator
        ref={menu.ref}
        onMouseDownCapture={(e) => {
          e.stopPropagation();
          e.preventDefault();

          if (isLoading || menuActions.length === 0) {
            return;
          }

          menu.open(menuActions);
        }}
      >
        {isLoading ? (
          <FetchingIndicator show />
        ) : type === RcaNodeType.or ? (
          'or'
        ) : (
          'and /or'
        )}
      </Indicator>
      <Handle position={Position.Right} type="source" />
      <PopoverMenu {...menu} />
    </>
  );
}
