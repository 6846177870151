import { Alert, styled, Typography } from '@mui/material';
import { StyledAlertHeader } from '@components/alerts/styled';
import { AlertProps } from '@components/alerts/alert-props';
import useScrollToOnMount from '@hooks/use-scroll-to-on-mount';

const StyledInfoAlert = styled(Alert)(({ theme: { palette } }) => ({
  backgroundColor: palette.info.light,
  color: palette.common.textDark,
  '& > .MuiAlert-icon': {
    background: 'none',
    color: '#fff',
  },
}));

export default function InfoAlert({ title, message, onClose }: AlertProps) {
  useScrollToOnMount('info-alert');

  return (
    <StyledInfoAlert id="info-alert" severity="info" onClose={onClose}>
      {title ? <StyledAlertHeader>{title}</StyledAlertHeader> : null}
      <Typography variant="body2">{message}</Typography>
    </StyledInfoAlert>
  );
}
