import { styled } from '@mui/material';
import Spacer from '@components/layout-util-components/spacer';
import Row from '@components/layout-util-components/row';
import { RunReportResponse } from '@api/types/case/case-report/run-report.response';
import { useMemo } from 'react';
import { getStrengthColor } from '@util/colour-identifiers';
import {
  ChainItemRadialOverviewStat,
  ChainItemReportRadialOverview,
} from '../components/chain-item-radial-chart';

interface Props {
  coverage: RunReportResponse<any>;
  chartFirst?: boolean;
}

// Grid layout split into 2 columns,
// each column takes up 1/2 of the space and has 1 row
const Container = styled('div')(({ theme: { palette } }) => ({
  '.section-title': {
    fontSize: '32px',
    fontWeight: '400',
    lineHeight: '43px',
    letterSpacing: '0px',
    textAlign: 'center',
  },

  '.intro-wrapper': {
    alignItems: 'flex-start',
  },

  '.overview-title': {
    maxWidth: '50%',
    fontSize: '40px',
    fontWeight: '400',
    lineHeight: '53px',
    letterSpacing: '0px',
    textAlign: 'left',
    color: palette.common.black,

    '.highlight': {
      color: palette.primary.main,
    },
  },
}));

export default function EvidenceSectionOverview({
  coverage,
  chartFirst,
}: Props) {
  const evidenceChartData: ChainItemRadialOverviewStat[] = useMemo(() => {
    return coverage.data
      .filter((x) => x.clientGeneratedId >= 0)
      .map((x, i) => ({
        id: coverage.metaData[i].strength,
        name: `${coverage.metaData[i].strength}/5`,
        percentage: coverage.metaData[i].coverage,
        chartPercentage: coverage.totals.coverageShare[i],
        colour: getStrengthColor(coverage.metaData[i].strength),
        chainItems: Object.keys(coverage.metaData[i].chainItems).length,
      }))
      .sort((a, b) => b.id - a.id);
  }, [coverage.data, coverage.metaData, coverage.totals.coverageShare]);

  const IntoText = () => {
    return (
      <div className="overview-title">
        This RCA has{' '}
        <span className="highlight">{coverage.totals.linkedEvidenceCount}</span>{' '}
        piece{coverage.totals.linkedEvidenceCount === 1 ? '' : 's'} of evidence
        assigned to causes, achieving{' '}
        <span className="highlight">{coverage.totals.coveragePercent}%</span>{' '}
        overall coverage. The average evidence strength is{' '}
        <span className="highlight">
          {coverage.totals.linkedEvidenceAverageStrength.toFixed(0)}/5
        </span>
        .
      </div>
    );
  };

  const Chart = () => {
    return (
      <ChainItemReportRadialOverview
        name={
          <>
            Overall <br />
             Coverage
          </>
        }
        defaultDisplayInfo={{
          label: (
            <>
              Overall <br />
               Coverage
            </>
          ),
          value: (
            <>
              <span>{coverage.totals.coveragePercent.toFixed(2)}</span>
              <span className="small">%</span>
            </>
          ),
        }}
        stats={evidenceChartData}
        size="medium"
        //totalPercentage={coverage.totals.coveragePercent}
        totalChainItems={coverage.totals.chainItemCount}
        //accumulateTotalsForDefaultPercentage
      />
    );
  };

  return (
    <Container>
      <div className="section-title">Evidence</div>
      <Row className="intro-wrapper">
        {chartFirst && (
          <>
            <Chart />
            <Spacer />
            <IntoText />
          </>
        )}
        {!chartFirst && (
          <>
            <IntoText />
            <Spacer />
            <Chart />
          </>
        )}
      </Row>
    </Container>
  );
}
